import { ACTN_IS_PROCESSING } from "../config/action-types.js";

export const isProcessing = (data) => (dispatch, getState) => {
  var state = getState();
  var isProcessing = state.isProcessing;
  data.key = data.key || "unknown";
  if (data.key && data.isProcessing) {
    isProcessing.keys = isProcessing.keys.concat({ key: data.key, since: Date.now() })
  }
  if (data.key && !data.isProcessing) {
    isProcessing.keys = isProcessing.keys.filter(k => k.key != data.key);
  }

  const newMessage = (data.message && data.message.length) ? data.message : undefined;

  isProcessing.mostRecentStatus = isProcessing.keys.length ? true : false;
  isProcessing.mostRecentMessage = isProcessing.keys.length ? (newMessage ? newMessage : isProcessing.mostRecentMessage) : undefined;

  dispatch({
    type: ACTN_IS_PROCESSING,
    isProcessing: isProcessing
  })
}
