
import {
  ACTN_REFRESH,
  ACTN_REFRESH_SINGLE_AFTER_UPDATE,
  ACTN_REFRESH_DATABASE,
  ACTN_UPDATE_CHIPS, ACTN_UPDATE_SEARCHBOX, ACTN_UPDATE_BRAND,
  ACTN_REFRESH_RESULTS,
  ACTN_REFRESH_ONE

} from '../config/action-types.js';


const initialState = function () {
  let state = {
    database: [],
    chips: [],
    searchBox: {
      iDisplayLength: 100,
      sSearch: "",
      quantity_flag: true
    },
    results: [],
    missinganag: null,
    eans: {
      summary: [],
      brand: {},
      patterns: []
    }
  };

  return state;
}()

export function removeFromLocalStorage(tires) {
  return {
    ...tires,
    eans: {
      summary: [],
      brand: {},
      patterns: []
    },
    results: [],
    searchBox: { ...tires.searchBox, sSearch: "" }
  };
}

export function allTiresReducer(state = initialState, action) {
  switch (action.type) {

    case ACTN_REFRESH:
      var _state = { ...state };
      //if (action.data && action.data.results) {
      //  _state = {
      //    ..._state,
      //    results: action.data.results
      //  }
      //}
      //if a new database is present, let's store it
      //if (action.data && action.data.searchBox && action.data.whoIsThisDataFor) {
      //  _state = {
      //    ..._state,
      //    [action.data.whoIsThisDataFor]: { ..._state[action.data.whoIsThisDataFor], searchBox: action.data.searchBox }
      //  };
      //}
      //if (action.data && action.data.filters && action.data.whoIsThisDataFor) {
      //  _state = {
      //    ..._state,
      //    [action.data.whoIsThisDataFor]: { ..._state[action.data.whoIsThisDataFor], filters: action.data.filters }
      //  };
      //}
      if (action.data && action.data.tires && action.data.tires.database) {
        _state = {
          ..._state,
          database: action.data.tires.database
        };
      }
      if (action.data && action.data.eans && action.data.eans.summary) {
        _state.eans = {
          ..._state.eans,
          summary: action.data.eans.summary
        };
      }
      if (action.data && action.data.eans && action.data.eans.brand) {
        _state.eans = {
          ..._state.eans,
          brand: action.data.eans.brand
        };
      }
      ["missinganag", "results", "elect"].forEach(p => {
        if (action.data && action.data[p]) {
          if (Array.isArray(action.data[p])) {
            _state = {
              ..._state, [p]: action.data[p]
            }
          } else {
            _state = {
              ..._state, [p]: { ...action.data[p] }
            }
          }
        }
      });
      return _state;

    case ACTN_REFRESH_ONE:
      var _state = { ...state };

      if (action.data && action.data.deleted && action.data.deleted.tdid && state.eans && state.eans.brand && state.eans.brand.patterns && state.eans.brand.patterns.length) {
        _state.eans.brand.patterns = _state.eans.brand.patterns.filter(p => !(p.pattern == action.data.deleted.pattern && p.brandid == action.data.deleted.brandid && p.slug == action.data.deleted.slug))
      }
      if (action.data && action.data.updated && action.data.updated.tdid && state.eans && state.eans.brand && state.eans.brand.patterns && state.eans.brand.patterns.length) {
        _state.eans.brand.patterns = _state.eans.brand.patterns.map(p =>
          (p.pattern == action.data.updated.pattern && p.brandid == action.data.updated.brandid && p.slug == action.data.updated.slug) ? action.data.updated : p
        )
      }

      ["eans"].forEach(e => {
        if (action.data && action.data[e]) {
          ["summary"].forEach(s => {
            if (action.data[e][s] && action.data[e][s][0]) {
              var _array = _state[e][s];
              var exists = _array.some(v => v.brandid == action.data[e][s][0].brandid);
              if (exists) {
                _array = _array.map(v => {
                  return (v.brandid == action.data[e][s][0].brandid) ? action.data[e][s][0] : v;
                });
              } else {
                _array.push(action.data[e][s][0]);
              }
              _state = {
                ..._state,
                [e]: {
                  [s]: _array
                }
              }
            }
          });
          ["brand"].forEach(s => {
            if (action.data[e][s] && action.data[e][s].eans) {
              var _array = _state[e][s].eans;
              var item = action.data[e][s].eans[0];
              var exists = _array.some(v => v.ean == item.ean);
              if (exists) {
                _array = _array.map(v => {
                  return (v.ean == item.ean) ? item : v;
                });
              } else {
                _array.push(item);
              }
              _state[e][s].eans = _array;
            }
            if (action.data[e][s] && action.data[e][s].patterns) {
              var _array = _state[e][s].patterns;
              var item = action.data[e][s].patterns[0];
              var exists = _array.some(v => v.brandid == item.brandid && v.slug == item.slug && v.pattern == item.pattern);
              if (exists) {
                _array = _array.map(v => {
                  return (v.brandid == item.brandid && v.slug == item.slug && v.pattern == item.pattern) ? item : v;
                });
              } else {
                _array.push(item);
              }
              _state[e][s].patterns = _array;
            }
          });
        }
      });

      return _state;

    case ACTN_REFRESH_SINGLE_AFTER_UPDATE:
      if (action.data && action.data.results) {
        state.results = state.results.map(v => { });
        return {
          ...state,
          results: action.data.results
        }
      }
      return state;
      break;

    //case ACTN_REFRESH_RESULTS:
    //  return {
    //    ...state,
    //    results: action.results
    //  }

    case ACTN_REFRESH_DATABASE:
      var _state = { ...state };
      if (action.tires) {
        _state = {
          ..._state,
          database: (action.tires && action.tires.database) ? action.tires.database : {},
        };
      }
      return {
        ..._state,
        nowis: Date.now()
      }

    //return {
    //  ...state,
    //  database: (action.tires && action.tires.database) ? action.tires.database : {},
    //  nowis: Date.now()
    //}

    case ACTN_UPDATE_SEARCHBOX:
      return {
        ...state,
        searchBox: { ...state.searchBox, ...action.data }
      }

    case ACTN_UPDATE_BRAND:
      return {
        ...state,
        eans: { ...state.eans, brand: { ...state.eans.brand, ...action.data } }
      }

    case ACTN_UPDATE_CHIPS:
      return {
        ...state,
        chips: action.chips
      }

    default:
      return state;
  }
}