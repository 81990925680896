class Localize {
  static exps = null;
  static chassis = null;
  static models = null;

  static LE = (key) => {
    return key;
  }

  static LM = (key, data) => {
    return key;
  }

  static LC = (key, data) => {
    return key;
  }

  static L = (key, data) => {
    return key;
  }
}

export { Localize };