import HttpRequest from '../../utils/HttpRequest';

import { isProcessing } from "../actions/actProcessing";

import { ACTN_REFRESH, MINICART_ANIMATE } from "../config/action-types.js";

const _doSomething = (url, payload, getState) => {
  return dispatch => {

    var state = getState();

    var headers = [];
    if (state.pusher && state.pusher.socket_id) {
      headers.push({ header: "pusher-socket_id", value: state.pusher.socket_id });
    }

    dispatch(isProcessing({
      key: url,
      isProcessing: true
    }));

    console.log("acxtMiniCart", "url", url);

    var xhr = new HttpRequest("POST", url, "application/json", headers);
    return xhr.send(payload).then((response) => {
      console.log("acxtMiniCart", "url", url, "done");
      var json = response.json;
      if (json.message || json.exception) {
        alert(json.message || json.exception);
      } else {
      }
      dispatch({
        type: ACTN_REFRESH,
        data: json
      });
      dispatch(isProcessing({
        key: url,
        isProcessing: false
      }));
    }).catch((e) => {
      dispatch(isProcessing({
        key: url,
        isProcessing: false
      }));
    });
  }
}

export const doSomething = (url, payload) => (dispatch, getState) => {
  return dispatch(_doSomething(url, payload, getState))
}

const _animateCart = (data, getState) => {
  return dispatch => {

    var state = getState();

    dispatch({
      type: MINICART_ANIMATE,
      data: data
    });
  }
}

export const animateCart = (data) => (dispatch, getState) => {
  return dispatch(_animateCart(data, getState))
}