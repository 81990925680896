import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { doSomething, updateRouting } from '../../gcomponents/store/actions/actShared.js';
import ProgressLoading from '../../gcomponents/utils/ProgressLoading'
import { invalidate } from '../../gcomponents/store/config/refreshIfStale.js'

function FooterTools({ IsAuthenticated, Name}) {
  const isProcessing = useSelector(state => state.isProcessing.mostRecentStatus)
  const stateStaleDates = useSelector(state => Object.keys(state).filter(k => state[k].nowis).map(k => { return { key: k, nowis: state[k].nowis } }))

  const dispatch = useDispatch();

  const refreshLocalData = useCallback(file => {
    // Do something with the files
    var entries = stateStaleDates.map(sd => sd.key)
    dispatch(invalidate(entries));
  }, [stateStaleDates])

  var items = []
  stateStaleDates.forEach((sd, i) => {
    var now = new Date()
    var date = new Date(sd.nowis)
    var diff = Math.floor(((now - date) / 1000) / 60) 
    items.push(<span key={"staledates-" + i}><span className="badge badge-light" style={{fontSize: "100%"}}>{sd.key}</span> {diff}minutes</span>)
    //items.push(<span key={"staledates-" + i}><span class="badge badge-light">{sd.key}</span> {diff}minutes {date.toISOString().substring(0, 19)} {now.toISOString().substring(0, 19)}</span>)
  })

  var ret = (
    <div>
      <span>{Name}</span>
      <div style={{ fontFamily: "Roboto Condensed", fontSize: 12 }}><button type="button" className="btn btn-danger btn-sm" style={{ padding: "2px 4px", fontSize: 12 }} onClick={e => refreshLocalData(e)}><i className="fas fa-sync-alt"></i> browser data</button> {items}</div>
    </div>
  )

  return ret;
}


export default FooterTools;