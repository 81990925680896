import React from 'react';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Error from '@material-ui/icons/ErrorOutlined';
import Check from '@material-ui/icons/Check';
import DynamicField from './DynamicField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from '../theme/style';
import { Localize } from "../../../scripts/shared/localize.js";
class LocalRegistration extends React.Component{

    constructor(props){
      super(props);
      let state = {};
      for(let i = 0; i < props.fields.length; i++){
        state[props.fields[i].name] = props.fields[i].type !== "checkbox" ? "" : false;
      }
      //initialize state with given fields names
      this.state = state;
    }

    handleChange = name => event => {
      let field = this.props.fields.filter((field) => field.name === name)[0];
      this.setState({ [name]: field.type !== "checkbox" ? event.target.value : event.target.checked });
    }

    handleRegistration = () => {
      this.props.handleRegistration(this.state);
    }

    render(){
      let {classes} = this.props;
      let {fields} = this.props;

      let invalidFields = fields.filter((field) => {
        if(field.required && (this.state[field.name] === "" || this.state[field.name] === false)){
          return true;
        }
        return false;
      }).length > 0;
      return (
        <Paper elevation={0}>
          <form style={{ marginBottom: 15 }}>
            {fields.map((field) =>
              <DynamicField key={"register_" + field.name} {...field} value={this.state[field.name]} handleChange={this.handleChange} />
            )}

            <br/>

            {this.props.enableRegisterAction && (
              <Button disabled={invalidFields} variant="contained" color="primary" onClick={this.handleRegistration} style={{display:'block', margin:'0 auto'}}>
                Registrati
              </Button>)}

            {this.props.error !== "" && (<div className={classes.errorText} style={{marginTop: 8}}>
              <Error /><span style={{ top: 0, position: 'relative', marginLeft: 10}}>{this.props.error}</span></div>)}

            {this.props.success !== "" && (<div style={{ color: 'green', marginTop: 8}}>
              <Check /><span style={{ top: 0, position: 'relative', marginLeft: 10}}>{this.props.success}</span></div>)}

            {!this.props.enableRegisterAction && (<LinearProgress />)}
          </form>
          <Typography variant="subtitle1" style={{margin: '5px 0'}}>
            {Localize.L("Hai già un account?")}
            <strong className={classes.primaryText} onClick={() => this.props.handleNavigation("login")}> {Localize.L("Accedi")} </strong>
          </Typography>
        </Paper>
      );
    }
}

export default withStyles(styles)(LocalRegistration);

/*
  <TextField label="Email" type="email" autoComplete="email" margin="normal" onChange={this.handleChange('email')} fullWidth style={{marginTop:0}}/>
  <TextField label="Password" type="password" autoComplete="current-password" margin="normal" onChange={this.handleChange('password')} fullWidth/>
  <br/>
*/
