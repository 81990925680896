import { ACTN_AUTH, ACTN_SIGNUP, ACTN_ERROR, ACTN_FORGOT } from './actions'

const initialState = {
  authResponse: {},
  signupResponse: {},
  errors: [],
  signupErrors: [],
  authSuccess: "",
  signupSuccess: ""
}

export default function (state = initialState, action) {
  let isError;
  switch(action.type){
    case ACTN_AUTH:
      isError = action.payload.errors && action.payload.errors.length > 0;
      return {
        ...state,
        authResponse: action.payload,
        errors: isError ? action.payload.errors : [],
        signupErrors: [],
        authSuccess: !isError ? "Accesso avvenuto con successo" : "",
        signupSuccess: ""
      }
    case ACTN_FORGOT:
      isError = action.payload.error ? true : false;
      return {
        ...state,
        forgotResponse: action.payload,
        errors: isError ? [{ message: action.payload.error }] : [],
        signupErrors: [],
        authSuccess: !isError ? "Controlla la tua mail!" : "",
        signupSuccess: ""
      }
    case ACTN_SIGNUP:
      isError = action.payload.errors && action.payload.errors.length > 0;
      return {
        ...state,
        signupResponse: action.payload,
        errors: [],
        signupErrors: isError ? action.payload.errors : [],
        signupSuccess: !isError ? "Account creato. Ora puoi usare la stessa mail per accedere" : ""
      }
    case ACTN_ERROR:
      return {
        ...state,
        signupResponse: "",
        errors: [{ message: action.error.error }],
        signupErrors: [],
        signupSuccess: ""
      }
    default:
      return state;
  }
}
