
import {
  ACTN_IS_PROCESSING
} from '../config/action-types.js';

import { initialProcessingState } from "./redProcessing-initial.js";

export default function (state = initialProcessingState, action) {
  switch (action.type) {

    case ACTN_IS_PROCESSING:
      return {
        ...state,
        ...action.isProcessing
      }

    default:
      return state;
  }
}