import React, { useState, useEffect } from 'react';
import {
  ResponsiveContainer, BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

function HistorySales(props) {
  // Declare a new state variable, which we'll call "count"

  const crunchData = (data) => {
    if (data && data[0]) {
      data = data[0];

      //var years = groupBy(data.Closed, "yyyy").sort((a, b) => b - a);
      //console.log("years", years);

      data.Closed = data.Closed.map(v => {
        Object.keys(v).forEach(y => {
          if (v[y] == 0) {
            delete v[y];
          }
        });
        return v;
      });

      if (data.LostWithContactInformation) {
        data.LostWithContactInformation = data.LostWithContactInformation.map(v => {
          Object.keys(v).forEach(y => {
            if (v[y] == 0) {
              delete v[y];
            }
          });
          return v;
        });
      }

      return data;
    } else {
      return undefined;
    }
  }

  const [_data, setData] = useState(crunchData(props.data));

  useEffect(() => {
    setData(crunchData(props.data));
  }, [props.data]);

  if (!_data) {
    return null;
  }

  var currentYear = new Date().getFullYear();

  var Closed = (
    <div className="row">
      <div className="col">
        <div style={{ fontFamily: "Roboto Condensed", textAlign: "center", fontSize: "1.5rem" }}>Closed Sales - {props.domain}</div>
        <ResponsiveContainer width="100%" height={200}>
          <LineChart
            data={_data.Closed}
            syncId="sales"
            margin={{
              top: 5, right: 0, left: 0, bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="wk"
              tick={{ fontFamily: "Roboto Condensed", fontSize: ".75rem" }} />
            <YAxis
              tick={{ fontFamily: "Roboto Condensed", fontSize: ".75rem" }}
              tickFormatter={tick => {
                return (1 * tick).toFixed(0);
              }} />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey={currentYear} stroke="#0074D9" strokeWidth="2" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey={(currentYear - 1)} stroke="#FF851B" strokeDasharray="3 4 5 2" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey={(currentYear - 2)} stroke="#AAAAAA" strokeDasharray="3 4 5 2" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  var LostWithContactInformation = null;
  if (props.domain != "platforms") {
    LostWithContactInformation = (
      <div className="row">
        <div className="col">
          <div style={{ fontFamily: "Roboto Condensed", textAlign: "center", fontSize: "1.5rem" }}>LostWithContactInformation - {props.domain}</div>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart
              data={_data.LostWithContactInformation}
              syncId="sales"
              margin={{
                top: 5, right: 0, left: 0, bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="wk"
                tick={{ fontFamily: "Roboto Condensed", fontSize: ".75rem" }} />
              <YAxis
                tick={{ fontFamily: "Roboto Condensed", fontSize: ".75rem" }}
                tickFormatter={tick => {
                  return (1 * tick).toFixed(0);
                }} />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey={currentYear} stroke="#FF4136" strokeWidth="2" activeDot={{ r: 8 }} />
              <Line type="monotone" dataKey={(currentYear - 1)} stroke="#FF851B" strokeDasharray="3 4 5 2" activeDot={{ r: 8 }} />
              <Line type="monotone" dataKey={(currentYear - 2)} stroke="#AAAAAA" strokeDasharray="3 4 5 2" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
  return (
    <div>
      {Closed}
      {LostWithContactInformation}
    </div>
  );
}

export default HistorySales;

