
import {
  ACTN_REFRESH,
  ACTN_REFRESH_DATABASE,
  ORDERS_UPDATE_CHIPS,
  ORDERS_UPDATE_SEARCHBOX,
  ORDERS_UPDATE_MODAL,
  ORDERS_REFRESH_RESULTS,
  ORDERS_REFRESH_ONE,
  ORDERS_UPDATE_APPROVING,
  ORDER_SHIPPING_CLOSEWORKINGDAY,
} from '../config/action-types.js';

const cf_statuses = [
  //{ name: 'notsent', value: false, label: 'Non inoltrati' },
  {
    name: 'lost', value: false, label: 'Lost',
    subs: [
      { name: 'lost_no_contact', value: false, label: 'No info' },
      { name: 'lost_contact_info', value: true, label: 'With contact information' },
      { name: 'lost_failed_payment', value: false, label: 'Failed payment' },
      { name: 'waiting_additional_payment', value: false, label: 'Additional payment needed' },
      { name: 'waiting_action', value: false, label: 'Altri' },
      { name: 'waiting_moneytransfer', value: false, label: 'Waiting money transfer' }
    ]
  },
  {
    name: 'open', value: true, label: 'Aperti',
    subs: [
      { name: 'waiting_approval', value: false, label: 'Ready for approval' },
      { name: 'open_autoapprqueue', value: false, label: 'Auto approval queue' },
      { name: 'open_autoapprerror', value: false, label: 'Auto approval error' },
    ]
  },
  {
    name: 'approved', value: false, label: 'Approvati',
    subs: [
      { name: 'approved_autoapproved', value: false, label: 'Auto approved' },
      { name: 'not_sent_to_nts', value: false, label: 'Not sent to NTS' },
      { name: 'sent_to_nts', value: true, label: 'Sent to NTS' },
      { name: 'nts_confirmed', value: false, label: 'NTS confirmed' }
    ]
},
  {
    name: 'delivered', value: false, label: 'Spediti',
    subs: [
      { name: 'delivering_no_tracking', value: false, label: 'No tracking' },
      { name: 'delivering_no_tracking_update', value: true, label: 'No update' },
      { name: 'delivering_operational', value: false, label: 'Operational' },
      { name: 'delivering_delivered', value: false, label: 'Delivered' },
      { name: 'delivering_late', value: false, label: 'Late' },
      { name: 'delivering_returned', value: false, label: 'Returned' },
      { name: 'delivering_troubled', value: false, label: 'Troubled' }
    ]
  },
  {
    name: 'invoiced', value: false, label: 'Fatturati'
  },
  {
    name: 'canceled', value: false, label: 'Cancellati',
  }];


const initialState = function () {
  let state = {
    domains: [],
    distributors: [],
    wheelFittingFees: [],
    sensorUnitStandardPrice: null,
    paywith: [],
    ordersConfiguration: {},
    chips: [],
    searchBox: {
      iDisplayLength: 100,
      sSearch: "",
      domains: ["gomme-auto.it"],
      cf_statuses: cf_statuses
    },
    results: [],
    modal: {},

    approving: {},

    activitymonitor: {
      filters: {}
    }
  };

  return state;
}()

export function removeFromLocalStorage(state) {
  return {
    ...state,
    ordersAgentDashboard: {},
    results: [],
    modal: {}
  };
}

function common(data, keys) {
  var ret = {};
  if (data && keys) {
    keys.forEach(p => {
      if (data[p]) {
        ret[p] = data[p]
      }
    });
  }
  return ret;
}

export function ordersReducer(state = initialState, action) {

  switch (action.type) {

    case ACTN_REFRESH:
      var c = common(action.data, ["user", "results", "ordersAgentDashboard"]);
      var _state = { ...state, ...c };
      //["user", "results", "ordersAgentDashboard"].forEach(p => {
      //  if (action.data && action.data[p]) {
      //    _state[p] = action.data[p]
      //  }
      //});
      return _state;

    case ORDERS_UPDATE_MODAL:
      var _state = { ...state };
      if (action.data && ["returning-item-with", "preview-item-changes", "preview-wheel-changes", "approving"].some(a => a == action.data.action)) {
        _state.modal.data = {
          ..._state.modal.data,
          ...action.data.data,
          lastAction: action.data.action
        }
        _state.modal.start = {
          paymentRequest: action.data.data.paymentRequest
        }
        //we shall move approving to state.approving
        if (action.data.action == "approving") {
          _state.approving = { ..._state.approving, ...action.data.data }
        }
        //we shall move approving to state.approving
        return _state;
      } else {
        _state = {
          ..._state,
          modal: {
            ..._state.modal, ...action.data }
        }
        //we shall move approving to state.approving
        if (action.data.data && action.data.data.modal == "approving") {
          _state.approving = { ..._state.approving, ...action.data.data }
        }
        //we shall move approving to state.approving

        return _state
      }

    case ORDERS_REFRESH_ONE:
      var c = common(action.data, ["ordersAgentDashboard"]);
      var _state = { ...state, ...c };
      if (action.data && action.data.results[0]) {
        var result = action.data.results[0];
        //it works and update state, because _state.results is a reference
        _state.results.forEach((r, i) => {
          if (r.cartid == result.cartid) {
            _state.results[i] = result;
          }
        });
        //_state = {
        //  ..._state,
        //  results: _state.results.map(r => r.cartid == result.cartid ? result : r)
        //}
        //if (action.options && action.options.approving && action.options.approving.clearLog) {
        //  _state.approving.log = []
        //}
      }
      return _state;

    case ACTN_REFRESH_DATABASE:
      var _state = { ...state };
      if (action.orders) {
        _state = {
          ..._state,
          domains: (action.orders && action.orders.domains) ? action.orders.domains : [],
          paywith: (action.orders && action.orders.paywith) ? action.orders.paywith : [],
          warranty_options: (action.orders && action.orders.warranty_options) ? action.orders.warranty_options : [],
          shipping_warranty_options: (action.orders && action.orders.shipping_warranty_options) ? action.orders.shipping_warranty_options : [],
          wheelFittingFees: (action.orders && action.orders.wheelFittingFees) ? action.orders.wheelFittingFees : [],
          sensorUnitStandardPrice: (action.orders && action.orders.sensorUnitStandardPrice) ? action.orders.sensorUnitStandardPrice : null,
          ordersConfiguration: (action.orders && action.orders.ordersConfiguration) ? action.orders.ordersConfiguration : null,
        };
      }
      if (action.tires) {
        _state = {
          ..._state,
          distributors: (action.tires && action.tires.database && action.tires.database.distributors) ? action.tires.database.distributors : [],
        };
      }
      return {
        ..._state,
        //domains: (action.orders && action.orders.domains) ? action.orders.domains : [],
        //paywith: (action.orders && action.orders.paywith) ? action.orders.paywith : [],
        //warranty_options: (action.orders && action.orders.warranty_options) ? action.orders.warranty_options : [],
        //distributors: (action.tires && action.tires.database && action.tires.database.distributors) ? action.tires.database.distributors : [],

        //wheelFittingFees: (action.orders && action.orders.wheelFittingFees) ? action.orders.wheelFittingFees : [],
        //sensorUnitStandardPrice: (action.orders && action.orders.sensorUnitStandardPrice) ? action.orders.sensorUnitStandardPrice : null,

        nowis: Date.now()
      }

    case ORDERS_UPDATE_SEARCHBOX:
      return {
        ...state,
        searchBox: { ...state.searchBox, ...action.data}
      }

    case ORDERS_UPDATE_CHIPS:
      return {
        ...state,
        chips: action.chips
      }

    case ORDER_SHIPPING_CLOSEWORKINGDAY:
      return {
        ...state,
        labels: action.data.labels
      }

    case ORDERS_UPDATE_APPROVING:
      var _state = { ...state }
      if (action.data) {
        _state = {
          ...state,
          //approving: { ...state.approving, ...action.data }
          approving: { ...action.data }
        }
      } else {
        _state = {
          ...state,
          approving: { }
        }
      }

      return _state

    default:
      return state;
  }
}