
import {
  ACTN_REFRESH,
} from '../config/action-types.js';


const initialState = function () {
  let state = {
    routing: {}
    //eans: {
    //  searchbox: {
    //    text: "",
    //    chips: [],
    //    filters: { }
    //  }
    //}
  };

  return state;
}()



export default function (state = initialState, action) {
  switch (action.type) {

    case ACTN_REFRESH:
      var _state = { ...state };
      ["routing", "missinganag", "feeds"].forEach(e => {
        if (action.data && action.data[e]) {
          _state = {
            ..._state,
            [e]: action.data[e]
          }
        }
      });
      return _state;

    default:
      return state;
  }
}