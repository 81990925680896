import React from 'react'
//import React from 'react';
//import InputTrigger from 'react-input-trigger';
import InputTrigger from '../../../gcomponents/utils/react-input-trigger';
import Popper from '@material-ui/core/Popper';
import getCaretCoordinates from 'textarea-caret';
import Dropzone from 'react-dropzone'
import DrawerMemoSaveButton from './drawer-memo-save-button.jsx'


function MyDropzone() {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Drag 'n' drop some files here, or click to select files</p>
      }
    </div>
  )
}


const filteredUsersList = (users, text) => {
  return users.filter(user => !text || user.toLowerCase().indexOf(text.toLowerCase()) !== -1);
}

export class DrawerMemo extends React.Component {
  constructor(props) {
    super(props);

    var { replyingTo, agents } = props;
    var textareaValue = "";
    if (replyingTo) {
      textareaValue = "#" + (replyingTo.idtosomething || replyingTo.cartid) + " ";
      //if (replyingTo.longtext && replyingTo.longtext.includes("@")) {
      //  replyingTo.longtext.split(" ")
      //    .filter(s => s && s.length && s[0] == "@")
      //    .reduce((a, v) => {
      //      var agent = agents.find(a => a.clientid == v.substring(1));
      //      if (agent) a.push(agent); return a;
      //    }, [])
      //    .forEach(a => {
      //      textareaValue += "@" + a.clientid + " ";
      //    });
      //}
      var agent = agents.find(a => replyingTo.createdby && a.userid.toLowerCase() == replyingTo.createdby.toLowerCase());
      if (agent) {
        textareaValue += "@" + agent.clientid + " ";
      }
      textareaValue = (textareaValue + "\n").trim();
      window.setTimeout(() => {
        this.taRef.selectionStart = this.taRef.selectionEnd = textareaValue.length;
        this.taRef.focus();
      }, 50);
    }

    this.state = {
      top: null,
      left: null,
      showSuggestor: false,
      startPosition: null,
      endPosition: null,

      users: this.props.agents.map(a => a.clientid),

      //users: [
      //  'Charmander',
      //  'Squirtle',
      //  'Bulbasaur',
      //  'Pikachu'
      //],

      text: null,
      textareaValue: textareaValue,

      currentSelection: 0,

      lockOrder: (this.props.order && this.props.order.starred == true),
      sendToT24: true,
      sendTo07zr: true,
      t24OrderStatusId: '7'
    };

    this.events = false;
  }

  componentDidUpdate(prevProps, prevState) {
    // Typical usage (don't forget to compare props):
    if (this.props.order) {
      if (this.props.order.starred !== prevProps.order.starred) {
        this.setState({ lockOrder: this.props.order.starred });
      }
    }
  }

  toggleSuggestor = (metaInformation) => {
    const { hookType, cursor } = metaInformation;

    //console.log("drawer-memo", "metaInformation", metaInformation);

    var boundingClientRect = {};
    if (hookType === 'start') {

      var el = this.taRef;//document.querySelector("#popperino-ta");
      if (el) {
        var paddingTop = 1 * window.getComputedStyle(this.taRef, null).getPropertyValue('padding-top').replace("px", "");
        var paddingLeft = 1 * window.getComputedStyle(this.taRef, null).getPropertyValue('padding-left').replace("px", "");

        cursor.width = cursor.width || 10;
        var _boundingClientRect = el.getBoundingClientRect();
        boundingClientRect.top = _boundingClientRect.top + cursor.top - el.scrollTop + paddingTop;
        boundingClientRect.bottom = boundingClientRect.top + cursor.height;
        boundingClientRect.height = cursor.height;
        boundingClientRect.width = cursor.width || 10;
        boundingClientRect.left = _boundingClientRect.left + cursor.left + paddingLeft;
        boundingClientRect.right = boundingClientRect.left + cursor.width;
        boundingClientRect.x = boundingClientRect.left;
        boundingClientRect.y = boundingClientRect.top;

        //console.log("suggestor-start boundingClientRect", _boundingClientRect);
        //console.log("suggestor-start", boundingClientRect, cursor.left, cursor.top, cursor.height, el.scrollTop);
      }
      //var taRect = this.taRef.current.getBoundingClientRect();

      this.setState({
        showSuggestor: true,
        left: cursor.left,
        top: cursor.top + cursor.height, // we need to add the cursor height so that the dropdown doesn't overlap with the `@`.
        text: null,
        startPosition: cursor.selectionStart,
        endPosition: cursor.selectionEnd,
        boundingClientRect: boundingClientRect
      });
    }

    if (hookType === 'cancel') {
      // reset the state

      this.setState({
        showSuggestor: false,
        left: null,
        top: null,
        text: null,
        startPosition: null,
        endPosition: null
      });
    }
  }

  handleInput = (metaInformation) => {
    if (this.state.showSuggestor) {
      //we care about the text after @ only when suggestions are shown
      //if we keep doing this we keep saving .text in state even when it's close
      //saving the old value
      //console.log("drawer-memo handleInput", "metaInformation.text", metaInformation.text);
      this.setState({
        text: metaInformation.text,
        endPosition: metaInformation.cursor.selectionEnd
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleKeyDown = (event) => {
    const { which } = event;
    const { currentSelection, users, text } = this.state;

    if (!this.state.showSuggestor) {
      //here we manage only events when the suggestions are shown
      return;
    }


    if (which === 27) { // 27 is ESC
      event.preventDefault();

      this.setState({
        showSuggestor: false,
        left: null,
        top: null,
        text: null,
        startPosition: null,
        endPosition: null

        //textareaValue: newText,
      });

      this.endHandler();
    }

    switch (which) {
      case 38://up arrow
      case 40://down arrow
        event.preventDefault();

        var length = filteredUsersList(users, text).length;
        var _currentSelection = which == 38 ? currentSelection - 1 : currentSelection + 1;
        _currentSelection = _currentSelection < 0 ? (length - 1) : _currentSelection >= length ? 0 : _currentSelection;
        this.setState({
          currentSelection: _currentSelection
        });
        break;
    }

    if ((which === 13 || which === 9) && this.state.showSuggestor) { // 13 is the character code for enter
      if (which === 9 || which === 13) {
        event.preventDefault();
      }

      this.handleConfirmSuggestion();
    }
  }

  handleConfirmSuggestion = () => {
    const { users, currentSelection, startPosition, endPosition, textareaValue, text } = this.state;
    const user = filteredUsersList(users, text)[currentSelection];

    var newText = textareaValue;
    if (user) {
      var extraSpace = "";
      if ((startPosition - 2) > 0) {
        //console.log("@@@@", textareaValue.slice(startPosition - 2, startPosition - 1));
        if (textareaValue.slice(startPosition - 2, startPosition - 1) != " ") {
          extraSpace = " ";
        }
      }
      newText = `${textareaValue.slice(0, startPosition - 1)}${extraSpace}@${user}${textareaValue.slice(startPosition + (endPosition - startPosition), textareaValue.length)}`
    }

    // reset the state and set new text
    // and highlighted text
    var highlightedText = this.applyHighlights(newText);

    this.setState({
      showSuggestor: false,
      left: null,
      top: null,
      text: null,
      startPosition: null,
      endPosition: null,

      textareaValue: newText,
      highlightedText: highlightedText
    }, () => {
      //put the cursor in the right place
      this.taRef.selectionEnd = startPosition + extraSpace.length + user.length;
    });

    this.endHandler();
  }

  handleOnScroll = (e) => {
    //console.log("#####", "handleOnScroll");
    if (this.taRef && this.backdropRef) {
      this.resizeBackdrop();

      var scrollTop = this.taRef.scrollTop;
      this.backdropRef.scrollTop = scrollTop;

      //var scrollLeft = $textarea.scrollLeft();
      //$backdrop.scrollLeft(scrollLeft);  
    }
  }

  handleTextareaInput = (event) => {
    const { value } = event.target;

    var highlightedText = this.applyHighlights(value);

    //console.log("@@@@", "drawer-memo handleTextareaInput", highlightedText);

    this.setState({
      textareaValue: value,
      highlightedText: highlightedText
    })
  }

  applyHighlights = (text) => {
    text = text
      .replace(/\n$/g, '\n\n')
      .replace(/\B[\#\@]\w+/g, m => { return this.state.users.some(u => u == m.substring(1)) ? "<mark>" + m + "</mark>" : m });
    //text = text
    //  .replace(/\n$/g, '\n\n')
    //  .replace(/\B[\#\@]\w+/g, '<mark>$&</mark>');

    return text;
  }

  handleMouseUp = () => {
    //console.log("#####", "handleMouseUp", this.events);
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
    this.events = false;
  }

  handleMouseMove = () => {
    //console.log("#####", "handleMouseMove");
    //const currentHeight = this.taRef.offsetHeight;
    //let savedHeight = localStorage.getItem('textareaHeight');
    this.resizeBackdrop();
  }

  resizeBackdrop = () => {
    if (this.taRef && this.taRef.offsetHeight !== this.state.taHeight && this.containerRef && this.backdropRef) {
      this.containerRef.style.height = this.taRef.offsetHeight + "px";
      this.backdropRef.style.height = this.taRef.offsetHeight + "px";;
      //this.setState({ taHeight: this.taRef.offsetHeight });
    }
  }

  handleMouseDown = (e) => {
    //console.log("#####", "handleMouseDown", this.events);
    window.addEventListener('mouseup', this.handleMouseUp);
    window.addEventListener('mousemove', this.handleMouseMove);
    this.events = true;
  }

  handleSaveComment = (e) => {
    var { order, replyingTo, options } = this.props;
    if (order) {
      var action = options.action ? options.action : "internalmemo-save";
      this.props.processActions(action,
        order, null, e,
        { text: this.state.textareaValue, block: this.state.lockOrder == true, sendToT24: this.state.sendToT24, sendTo07zr: this.state.sendTo07zr, t24OrderStatusId: this.state.t24OrderStatusId, replyingTo: replyingTo ? replyingTo : undefined })
        .then(() => {
          this.setState({
            textareaValue: "",
            highlightedText: ""
          })
        });
    } else {
      this.props.processActions("memo-save",
        null, null, e,
        { text: this.state.textareaValue, replyingTo: replyingTo ? replyingTo : undefined })
        .then(() => {
          this.setState({
            textareaValue: "",
            highlightedText: ""
          })
        });
    }
  }

  render() {
    var { boundingClientRect, users, text, showSuggestor, sendToT24, sendTo07zr } = this.state;
    var { replyingTo, options, order } = this.props;

    //console.log("drawer-memo render", showSuggestor, boundingClientRect);
    var lockerButton = null;
    var sendToT24Check = null;
    var sendTo07zrCheck = null;
    var t24OrderStatusIdSelect = null;
    if (order) {
      if (!options || options.action != "cancel") {
        lockerButton = (
          <div className="col">
            <button
              className={"btn btn-sm btn-light " + (!this.state.textareaValue ? " disabled" : "")}
              style={{ color: (this.state.lockOrder ? "#FF4136" : "inherit") }}
              onClick={(e) => this.setState({ lockOrder: !this.state.lockOrder })}
            >
              {this.state.lockOrder && <i className="fas fa-lock"></i>}
              {!this.state.lockOrder && <i className="fas fa-lock-open"></i>}
            </button>
          </div>
        );
      }
      else {
        if (order.domain == "tyre24.com") {
          sendToT24Check = (
            <div className="col-3">
              <div className="form-check">
                <input
                  style={{ marginTop: 6 }}
                  className="form-check-input"
                  type="checkbox"
                  name="sendToT24"
                  checked={sendToT24}
                  onChange={(e) => this.handleInputChange(e)} />
                <label className="form-check-label">
                  <small>Send to T24</small>
                </label>
              </div>
            </div>
          );

          var t24orderStatuses = [];
          if (sendToT24) {
            t24orderStatuses.push(<option key={4} value="4">Order rejected</option>);
            t24orderStatuses.push(<option key={5} value="5">Out of stock</option>);
            t24orderStatuses.push(<option key={6} value="6">Partially canceled</option>);
            t24orderStatuses.push(<option key={7} value="7">Canceled by customer</option>);
          }
          t24OrderStatusIdSelect = (
            <div className="col">
              <select
                name="t24OrderStatusId"
                value={this.state.t24OrderStatusId}
                onChange={(e) => this.handleInputChange(e)}
                className="form-control form-control-sm"
                disabled={!sendToT24}>
                {t24orderStatuses}
              </select>
            </div>
          );
        }
        else if (order.domain == "07zr.com") {
          sendTo07zrCheck = (
            <div className="col-3">
              <div className="form-check">
                <input
                  style={{ marginTop: 6 }}
                  className="form-check-input"
                  type="checkbox"
                  name="sendTo07zr"
                  checked={sendTo07zr}
                  onChange={(e) => this.handleInputChange(e)} />
                <label className="form-check-label">
                  <small>Send to 07 ZR</small>
                </label>
              </div>
            </div>
          );
        }
      }
    }

    var actionsBtns = null;
    actionsBtns = (
      <div style={{ marginTop: 10, fontFamily: "Roboto Condensed" }} className="row no-gutters align-items-center">
        {lockerButton}
        {sendToT24Check}
        {sendTo07zrCheck}
        {t24OrderStatusIdSelect}
        <div className="col" style={{ textAlign: "right" }}>
          {replyingTo &&
            <button
              className={"btn btn-sm btn-outline-secondary mr-2"}
              onClick={(e) => this.props.replyMemo(e, undefined)}
            >Cancel</button>
          }
          <DrawerMemoSaveButton
            order={order}
            options={options}
            handleSaveComment={this.handleSaveComment}
            textareaValue={this.state.textareaValue} />
        </div>
      </div>
    );

    return (
      <div
        style={{
          position: 'relative', padding: !replyingTo ? "0px 10px" : "10px 0px"
        }}

        onKeyDown={this.handleKeyDown}
      >
        <div className="drawer-memo-ta-container"
          ref={element => this.containerRef = element}
        >
          <div className="drawer-memo-ta-backdrop"
            ref={element => this.backdropRef = element}
          >
            <div className="drawer-memo-ta-highlights" dangerouslySetInnerHTML={{ __html: this.state.highlightedText }}></div>
          </div>
          <InputTrigger
            trigger={{
              key: "@",
              shiftKey: true,
            }}
            onStart={(metaData) => { this.toggleSuggestor(metaData); }}
            onCancel={(metaData) => { this.toggleSuggestor(metaData); }}
            onType={(metaData) => { this.handleInput(metaData); }}
            endTrigger={(endHandler) => { this.endHandler = endHandler; }}
          >
            <textarea
              className="drawer-memo-ta-textarea"
              placeholder="Leave a memo..."
              spellCheck={false}
              id="popperino-ta"
              ref={element => this.taRef = element}

              onChange={this.handleTextareaInput}
              onMouseDown={this.handleMouseDown}
              onScroll={this.handleOnScroll}
              value={this.state.textareaValue}
            />
          </InputTrigger>
        </div>
        <div className="drawer-memo-drag-and-drop">
          <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <section style={{ cursor: "pointer" }}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
        {actionsBtns}
        {showSuggestor &&
          <Popper
            id={"popperino"}
            open={showSuggestor}
            anchorEl={{
              clientWidth: boundingClientRect.width,
              clientHeight: boundingClientRect.height,
              getBoundingClientRect: () => boundingClientRect
            }}
            style={{ zIndex: "9999 !important" }}
            placement="bottom-start"
          >
            <div
              id="dropdown"
              style={{

                width: "200px",
                borderRadius: "3px",
                background: "white",
                boxShadow: "rgba(0, 0, 0, 0.4) 0px 1px 4px",

                display: showSuggestor ? "block" : "none"
              }}
            >
              {
                filteredUsersList(users, text)
                  .map((user, index) => (
                    <div
                      key={"user-" + index}
                      onClick={(e) => {
                        this.setState({ currentSelection: index }, () => {
                          window.setTimeout(() => this.handleConfirmSuggestion(), 250);
                        });
                      }}
                      style={{
                        padding: '5px 10px',
                        fontFamily: "Roboto Condensed",
                        background: index === this.state.currentSelection ? '#0062cc' : '',
                        color: index === this.state.currentSelection ? 'white' : 'black'
                      }}
                    >
                      {user}
                    </div>
                  ))
              }
            </div>
          </Popper>
        }
      </div>
    );
  }
}

