import React from 'react';

import memoize from "memoize-one";

import { connect } from 'react-redux';
import { doSomething as drawerDoSomething, updateDrawer } from "../../../gcomponents/store/actions/actDrawer";
import { doSomething as ordersDoSomething } from '../../../gcomponents/store/actions/actOrders';

import DrawerOrder from "./drawer-order.jsx";
import DrawerInbox from "./drawer-inbox.jsx";

import ProgressLoading from '../../../gcomponents/utils/ProgressLoading'

class DrawerDaddy extends React.Component {
  constructor(props) {
    super(props);
  }

  closeDrawer = (a) => {
    const isOpen = this.props.drawer.isOpen ? false : true;

    this.props.updateDrawer({
      isOpen: isOpen,
      order: undefined,
      memos: [],
      mail: undefined
    });
  }

  processActions = (a, o, i, e, data) => {
    if (e) {
      e.preventDefault();
    }
    //console.log(a, o, i);
    if (o) {
      switch (a) {
        case "tagger":
        case "email-send":
        case "internalmemo-save":
        case "cancel":
          var url = "/bo/orders/" + a + "/" + o.cartid;
          var payload = { ...data };
          return this.props.ordersDoSomething(url, payload, { refreshOne: true })
            .then((d) => {
              return d;
            });
          break;
      }
      //return new Promise((resolve, reject) => {
      //  switch (a) {
      //    case "email-send":
      //    case "internalmemo-save":
      //      var url = "/bo/orders/" + a + "/" + o.cartid;
      //      var payload = { ...data };
      //      this.props.ordersDoSomething(url, payload, { refreshOne: true })
      //        .then((d) => {
      //          resolve(d);
      //        });
      //      break;
      //  }
      //});
    } else {
      return new Promise((resolve, reject) => {
        switch (a) {

          case "memo-save":
            var url = "/bo/todo/" + a;
            var payload = { ...data };
            this.props.drawerDoSomething(url, payload, { refreshOne: true })
              .then(() => {
                resolve();
              });
            break;
        }
      });
    }
  }

  render() {
    var ret = null;

    var { isOpen, order, memos, mail, options } = this.props.drawer;
    if (isOpen) {
      if (order) {
        ret = (
          <DrawerOrder
            drawerIsOpen={isOpen}
            mail={mail}
            order={order}
            options={options}
            toggleDrawer={this.closeDrawer}
            processActions={this.processActions}
            isProcessing={this.props.isProcessing}
            user={this.props.todo.user}
          />
        );
      } else if (memos) {
        ret = (
          <DrawerInbox
            drawerIsOpen={isOpen}
            mail={mail}
            memos={memos}
            toggleDrawer={this.closeDrawer}
            processActions={this.processActions}
            isProcessing={this.props.isProcessing}
            user={this.props.todo.user}
          />
        );
      }
    }

    return ret;
  }
}


const mapStateToProps = state => {
  return {
    drawer: state.drawer,
    todo: state.todo,

    isProcessing: state.isProcessing.mostRecentStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDrawer: (data) => dispatch(updateDrawer(data)),

    drawerDoSomething: (url, payload) => dispatch(drawerDoSomething(url, payload)),
    ordersDoSomething: (url, payload, options) => dispatch(ordersDoSomething(url, payload, options)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(DrawerDaddy);
