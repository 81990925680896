import React from "react";
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';

class FacebookProvider extends React.Component {

  constructor(props) {
    super(props);
	this.facebookProvider = React.createRef();
  }
  
  /*facebookProviderRef = (element) => {
	this.facebookProvider = element;
  }*/
  
  componentDidMount() {
    let self = this;
    //let fbButton = ReactDOM.findDOMNode(this.refs.facebookProvider)
	//let fbButton = this.facebookProvider.current;
	let fbButton = document.getElementById("facebookProvider");
	console.log("fbButton", fbButton);
	if(!fbButton) return;
	fbButton.addEventListener("click", function (e) {
      FB.login(function (response) {
        let authResponse = response.authResponse;
        FB.api('/me?fields=name,email,picture', function (response) {
          response.authResponse = authResponse;
          this.props.onAuth(response, "facebook"); //loggedin
        }.bind(self));
      }, {
        scope: 'email',
        return_scopes: true
      });
    }, false);
  }
  
  render() {
    return (
      <IconButton id="facebookProvider" aria-label="Accedi con Facebook" color="primary">
        <img src="/images/facebook.png" alt="Accedi con Facebook" style={{ height: 48 }} />
      </IconButton>
    );
  }
}

FacebookProvider.propTypes = {
  onAuth: PropTypes.func.isRequired //handle authentication process
};

export default FacebookProvider;