import primary from '@material-ui/core/colors/blue';
import secondary from '@material-ui/core/colors/blueGrey';
import yellow from '@material-ui/core/colors/yellow';
import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'light',
    primary: primary,
    secondary: secondary,
    accent: yellow,
    error: {
      main:'#ca0909'
    },
    sand: {main:'#F4DECB'},
    shell: {main:'#F8EEE7'},
    status: {
      danger:'#b71c1c'
    }
  }
});
