import React from 'react';
import ReactDOM from 'react-dom';
import NavBar from './shared/navbar.jsx';
import FooterTools from './shared/footer.jsx'
import DrawerDaddy from './bo/drawer/drawer-daddy.jsx';
//import HistoryWheels from './bo/history/wheels.jsx';
//import HistoryTires from './bo/history/tires.jsx';
//import HistoryRedis from './bo/history/redis.jsx';
import HistoryDash from './bo/history/dash.jsx';

import JsonEditorContainer from '../json-editor-container/JsonEditorContainer'
import JsonSmartEditor from '../json-editor-container/JsonSmartEditor'

import { Provider } from 'react-redux';
import configureStore from '../gcomponents/store/config/config'

//if (document.getElementById('join-complete')) {
//  ReactDOM.render(
//    <UserJoin />,
//    document.getElementById('join-complete')
//  );
//}

const store = configureStore();

var el = document.getElementById("json-editor-container");
if (el) {
  ReactDOM.render(
    <JsonEditorContainer />,
    el
  );
}

el = document.getElementById("json-smart-editor");
if (el) {
  ReactDOM.render(
    <Provider store={store}>
      <JsonSmartEditor />
    </Provider>,
    el
  );
}

el = document.getElementById("navbar");
if (el) {
  var IsAuthenticated = el.dataset.isauthenticated == "True";
  var name = el.dataset.name;
  ReactDOM.render(
    <Provider store={store}>
      <NavBar IsAuthenticated={IsAuthenticated} Name={name} />
    </Provider>,
    el
  );
}

el = document.getElementById("footer-tools");
if (el) {
  var IsAuthenticated = el.dataset.isauthenticated == "True";
  var name = el.dataset.name;
  ReactDOM.render(
    <Provider store={store}>
      <FooterTools IsAuthenticated={IsAuthenticated} Name={name} />
    </Provider>,
    el
  );
}

el = document.getElementById("bo-drawer");
if (el) {
  ReactDOM.render(
    <Provider store={store}>
      <DrawerDaddy />
    </Provider>,
    el);
}

//el = document.getElementById("history-wheels");
//if (el) {
//  ReactDOM.render(
//    <Provider store={store}>
//      <HistoryWheels />
//    </Provider>,
//    el);
//}

//el = document.getElementById("history-tires");
//if (el) {
//  ReactDOM.render(
//    <Provider store={store}>
//      <HistoryTires />
//    </Provider>,
//    el);
//}

//el = document.getElementById("history-redis");
//if (el) {
//  ReactDOM.render(
//    <Provider store={store}>
//      <HistoryRedis />
//    </Provider>,
//    el);
//}

el = document.getElementById("history-dash");
if (el) {
  ReactDOM.render(
    <Provider store={store}>
      <HistoryDash />
    </Provider>,
    el);
}

el = document.getElementById("history-sales-details");
if (el) {
  ReactDOM.render(
    <Provider store={store}>
      <HistoryDash id={"sales-details"}/>
    </Provider>,
    el);
}