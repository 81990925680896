import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {
  ACTN_REFRESH, ORDERS_UPDATE_SEARCHBOX
} from '../../gcomponents/store/config/action-types.js';

function NavBarTwilio(props) {
  // Declare a new state variable, which we'll call "count"
  const user = useSelector(state => state.todo.user)
  const pusher = useSelector(state => state.pusher)
  const twilio = useSelector(state => state.pusher.twilio)
  const calls = useSelector(state => state.pusher.twilio.calls)
  const queue = useSelector(state => state.pusher.twilio.queue)
  const connection = useSelector(state => state.pusher.twilio.connection)
  const members = useSelector(state => state.pusher.members)

  const dispatch = useDispatch();

  const [status, setStatus] = useState(twilio.device.isInitialized ? twilio.device.status() : "offline");

  const {
    ui,
    handleClick
  } = props;

  const updateConnectionObject = (c) => {
    dispatch({
      type: ACTN_REFRESH,
      data: {
        twilio: { connection: connection }
      }
    });
  }

  useEffect(() => {
    // Update the document title using the browser API
    twilio.device.on('error', function (error) {
      console.log("twilio", error.message);

      updateConnectionObject(
        twilio.device.activeConnection()
      );
    });

    twilio.device.on('cancel', function (error) {
      console.log("twilio", "cancel", connection);

      updateConnectionObject(
        twilio.device.activeConnection()
      );
    });

    twilio.device.on('connect', (connection) => {
      console.log("twilio", "connect", connection);
    });

    twilio.device.on('disconnect', (connection) => {
      console.log("twilio", "disconnect", connection);
      updateConnectionObject(connection);
    });

    twilio.device.on('incoming', (connection) => {
      console.log("twilio", 'incoming', connection);
    });

    twilio.device.on('offline', (device) => {
      console.log("twilio", "offline");
      setStatus(device.status());
    });

    twilio.device.on('ready', (device) => {
      console.log("twilio", "ready");
      setStatus(device.status());
    });
  }, [twilio.device]);

  console.log("!!!twilio", twilio);
  console.log("!!!connection", connection);
  if (connection) {
    console.log("!!!connection", "status", connection.status());
  }

  const call = (queued) => {

    var current_connection = twilio.device.connect({ QueueFriendlyName: 'generic', AgentId: user.twilio_clientid, Sid: queued.sid });

    dispatch({
      type: ACTN_REFRESH,
      data: {
        twilio: { connection: current_connection }
      }
    });
  }

  var click = (e) => {
    handleClick(e, "presencemenu");
  }

  const goToCart = (cartid) => {
    dispatch({
      type: ORDERS_UPDATE_SEARCHBOX,
      data: {
        sSearch: "" + cartid
      }
    });
    window.setTimeout(() => {
      var win = window.open("/bo/orders", '_blank');
      win.focus();
    }, 0);
  }

  var badge = null;
  switch (status) {
    case "offline":
      badge = <span className="ml-1"><span className="badge badge-light">{status}</span></span>;
      break;
    case "busy":
    case "ready":
      var label = status;
      if (calls && calls.some(c => c.userid == user.guid && c.status =="dialing")) {
        label = "Dialing";
      } else if (queue && queue.some(q => q.status == "crisscrossed" && q.destination == user.twilio_clientid)
        || calls && calls.some(c => c.userid == user.guid)) {
        label = "In a call";
      } else if (queue && queue.some(q => q.status == "enqueued")) {
        var q = queue.filter(q => q.status == "enqueued");
        q = q[q.length - 1];
        label = q.phonenumber;
      }
      badge = <span className="ml-1"><span className="badge badge-success">{label}</span></span>;
      break;
  }

  var ringerIcon = <i className="mr-1 ml-1 fas fa-bell-slash fa-fw" style={{ color: "#dc3545" }}></i>;
  if (user.ringtoneEnabled !== false) {
    ringerIcon = <i className="mr-1 ml-1 fas fa-bell fa-fw"></i>;
  }

  var whosonline = members && members.map((m, i) => {
    if (user.twilio_clientid == m.info.twilio_clientid) {
      return <a key={"member-" + m.id} className="dropdown-item disabled pt-0 pb-0" style={{ color: "#6c757d" }}>{m.info.twilio_clientid} (you)</a>;
    } else {
      return <a key={"member-" + m.id} className="dropdown-item disabled pt-0 pb-0" href="#">{m.info.twilio_clientid}</a>;
    }
  });
  if (!whosonline) {
    whosonline = [].push(<a key={"member-none"} className="dropdown-item disabled pt-0 pb-0" href="#">No one</a>);
  }

  return (
    <ul className="navbar-nav mr-10" style={{ paddingLeft: 10 }}>
      <li className={"nav-item dropdown" + (ui.presencemenu.show ? " show" : "")}
        ref={ui.presencemenu.ref}>
        <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          style={{ position: "relative" }}
          onClick={(e) => click(e)}
          name="presencemenu">
          <span>
            <i className="fas fa-phone"></i>
            {ringerIcon}
            {badge}
          </span>
        </a>
        <div className={"dropdown-menu dropdown-menu-right" + (ui.presencemenu.show ? " show" : "")}>
          <Queue call={call} goToCart={goToCart}/>
          <ActiveCalls call={call} goToCart={goToCart}/>
          <a
            className="dropdown-item disabled pb-0 pt-2"
            style={{ color: "#6c757d", borderBottom: "1px solid #e9ecef", fontWeight: 700 }}
            href="#">
            Who's online
          </a>
          {whosonline}
          <Settings/>
        </div>
      </li>
    </ul>
  );
}

function ActiveCalls({ goToCart }) {
  var calls = useSelector(state => state.pusher.twilio.calls)
  const queue = useSelector(state => state.pusher.twilio.queue)
  const connection = useSelector(state => state.pusher.twilio.connection)
  const agents = useSelector(state => state.todo.agents)
  const user = useSelector(state => state.todo.user)

  var isMuted = false;
  if (connection) {
    isMuted = connection.isMuted();
  }

  const [toRefresh, setToRefresh] = useState(0);

  const toggleMute = () => {
    if (connection) {
      var isMuted = connection.isMuted();
      connection.mute(!isMuted);
      setToRefresh(1+toRefresh);
    }
  }

  const hangup = () => {
    if (connection) {
      connection.disconnect();
    }
  }

  var _calls = [];
  _calls.push(
    <a
      key={"calls-title"}
      className="dropdown-item disabled pb-0 pt-2"
      style={{ color: "#6c757d", borderBottom: "1px solid #e9ecef", fontWeight: 700 }}
    >
      Active calls
      </a>
  );

  var mycalls = queue.filter(q => q.status == "crisscrossed" && q.destination == user.twilio_clientid);
  mycalls.push(...(
    calls.filter(c => c.to && !queue.some(q => q.sid == c.sid) && c.userid == user.guid)
  ));
  mycalls.forEach((q, i) => {
    _calls.push(
      <a key={"queue-" + q.sid + "-" + i}
        className="dropdown-item disabled pt-0 pb-0"
        href="#"
      >
        <span>
          <span className="badge badge-dark"><i className="fas fa-headset fa-fw"></i></span> <CalledPartyLabel q={q} /> {q.phonenumber} (<span>You</span>)
        </span>
      </a>
    );
    if (q.cartid) {
      _calls.push(
        <a key={"cartid-" + q.sid + "-" + i}
          className="dropdown-item pt-0 pb-0"
          href="#"
          onClick={() => goToCart(q.cartid)}
        >
          <span>
            <i className="far fa-file-alt fa-fw"></i> cartid {q.cartid}
      </span>
        </a>
      );
    }
    _calls.push(
      <a key={"hang-up-calls-" + q.sid + "-" + i}
        className="dropdown-item pt-0 pb-0"
        href="#"
        onClick={() => hangup()}
      >
        <span>
          <i className="fas fa-stop fa-fw" style={{ color: "#dc3545" }}></i> Hang Up
      </span>
      </a>
    );
    if (connection) {
      _calls.push(
        <a key={"settings-microphone-mute-" + q.sid + "-" + i}
          className="dropdown-item pt-0 pb-0 mb-2"
          href="#"
          onClick={() => toggleMute()}>
          <span>
            <i className={"fas fa-microphone" + (isMuted ?"-slash":"")+" fa-fw"}></i> muted
        </span>
        </a>
      );
    }
    _calls.push(
      <a key={"divider-" + q.sid + "-" + i}
        className="dropdown-item pt-0 mb-1 disabled"
        style={{ color: "#6c757d", borderBottom: "1px dashed #e9ecef", fontWeight: 700 }}
      >
        <span>
        </span>
      </a>
    );

  });

  var done = mycalls.map(c=>c.sid);

  queue.filter(q => q.status == "crisscrossed" && !done.some(d => d == q.sid)).forEach((q, i) => {
    done.push(q.sid);
    _calls.push(
      <a key={"queue-" + q.sid + "-" + i}
        className="dropdown-item disabled pt-0 pb-0"
        href="#"
      >
        <span>
          <span className="badge badge-dark"><i className="fas fa-headset fa-fw"></i></span> <CalledPartyLabel q={q} /> {q.phonenumber} ({q.destination})
        </span>
      </a>
    );
    if (q.cartid) {
      _calls.push(
        <a key={"cartid-" + q.sid + "-" + i}
          className="dropdown-item pt-0 pb-0"
          href="#"
          onClick={() => goToCart(q.cartid)}
        >
          <span>
            <i className="far fa-file-alt fa-fw"></i> cartid {q.cartid}
          </span>
        </a>
      );
    }
    _calls.push(
      <a key={"divider-" + q.sid + "-" + i}
        className="dropdown-item pt-0 mb-1 disabled"
        style={{ color: "#6c757d", borderBottom: "1px dashed #e9ecef", fontWeight: 700 }}
      >
        <span>
        </span>
      </a>
    );
  });
  calls = calls.filter(c => c.to && !queue.some(q => q.sid == c.sid) && !done.some(d => d == c.sid));
  if (calls && calls.length && agents) {
    calls.forEach((q, i) => {
      var agent = agents.filter(a => a.userid == q.userid);
      agent = agent && agent[0];
      done.push(q.sid);
      _calls.push(
        <a key={"calls-" + q.sid}
          className="dropdown-item disabled pt-0 pb-0"
        >
          <span>
            <span className="badge badge-dark"><i className="fas fa-headset fa-fw"></i></span> <CalledPartyLabel q={q} /> {q.phonenumber} ({agent && agent.clientid})
          </span>
        </a>
      );
      if (q.cartid) {
        _calls.push(
          <a key={"cartid-" + q.sid + "-" + i}
            className="dropdown-item pt-0 pb-0"
            href="#"
            onClick={() => goToCart(q.cartid)}
          >
            <span>
              <i className="far fa-file-alt fa-fw"></i> cartid {q.cartid}
            </span>
          </a>
        );
      }
      _calls.push(
        <a key={"divider-" + q.sid + "-" + i}
          className="dropdown-item pt-0 mb-1 disabled"
          style={{ color: "#6c757d", borderBottom: "1px dashed #e9ecef", fontWeight: 700 }}
        >
          <span>
          </span>
        </a>
      );
    });
  }
  if (_calls.length == 1) {
    _calls.push(<a key={"calls-empty"} className="dropdown-item disabled pt-0 pb-0" style={{ color: "#6c757d" }}>Empty</a>);
  }

  return _calls;
}

function Settings() {
  const ringtoneEnabled = useSelector(state => state.todo.user.ringtoneEnabled)
  const connection = useSelector(state => state.pusher.twilio.connection)
  const dispatch = useDispatch();

  const toggleRingtone = () => {
    dispatch({
      type: ACTN_REFRESH,
      data: {
        user: { ringtoneEnabled: ringtoneEnabled === false ? true : false }
      }
    });
  }

  var _settings = [];
  _settings.push(
    <a
      key={"settings-title"}
      className="dropdown-item disabled pb-0 pt-2"
      style={{ color: "#6c757d", borderBottom: "1px solid #e9ecef", fontWeight: 700 }}
    >
      Settings
      </a>
  );
  if (ringtoneEnabled===false) {
    _settings.push(
      <a key={"settings-ringtoneEnabled"}
        className="dropdown-item pt-0 pb-0"
        href="#" onClick={() => toggleRingtone()}>
        <span>
          <i className="fas fa-bell-slash fa-fw"></i> disabled
        </span>
      </a>
    );
  } else {
    _settings.push(
      <a key={"settings-ringtoneEnabled"}
        className="dropdown-item pt-0 pb-0"
        href="#" onClick={() => toggleRingtone()}>
        <span>
          <i className="fas fa-bell fa-fw"></i> enabled
        </span>
      </a>
    );
  }

  return _settings;
}

function Queue({ call, goToCart }) {
  const queue = useSelector(state => state.pusher.twilio.queue)

  var _queue = [];
  _queue.push(
    <a
      key={"queue-title"}
      className="dropdown-item disabled pb-0 pt-2"
      style={{ color: "#6c757d", borderBottom: "1px solid #e9ecef", fontWeight: 700 }}
    >
      Queue
      </a>
  );
  //_queue.push(
  //  <div key={"queue-divider"}
  //    className="dropdown-divider"></div>
  //);
  if (queue && queue.length) {
    var done = [];
    queue.filter(q => q.status == "incoming" && !q.destination).forEach((q, i) => {
      done.push(q.sid);
      _queue.push(
        <a key={"queue-" + q.sid}
          className="dropdown-item disabled pt-0 pb-0"
        >
          <span>
            <span className="badge badge-dark"><i className="fas fa-random fa-fw"></i></span> <CalledPartyLabel q={q} /> {q.phonenumber}
          </span>
        </a>
      );
      _queue.push(
        <a key={"divider-" + q.sid}
          className="dropdown-item pt-0 mb-1 disabled"
          style={{ color: "#6c757d", borderBottom: "1px dashed #e9ecef", fontWeight: 700 }}
        >
          <span>
          </span>
        </a>
      );
    });
    queue.filter(q => q.status == "enqueued" && !q.destination && !done.some(d => d == q.sid)).forEach((q, i) => {
      done.push(q.sid);
      _queue.push(
        <a key={"queue-" + q.sid}
          className="dropdown-item pt-0 pb-0"
          href="#" onClick={() => call(q)}>
          <span>
            <span className="badge badge-dark"><i className="fas fa-bell fa-fw"></i></span> <CalledPartyLabel q={q} /> {q.phonenumber}
          </span>
        </a>
      );
      if (q.cartid) {
        _queue.push(
          <a key={"cartid-" + q.sid}
            className="dropdown-item pt-0 pb-0"
            href="#"
            onClick={() => goToCart(q.cartid)}
          >
            <span>
              <i className="far fa-file-alt fa-fw"></i> cartid {q.cartid}
            </span>
          </a>
        );
      }
      _queue.push(
        <a key={"divider-" + q.sid}
          className="dropdown-item pt-0 mb-1 disabled"
          style={{ color: "#6c757d", borderBottom: "1px dashed #e9ecef", fontWeight: 700 }}
        >
          <span>
          </span>
        </a>
      );
    });
    //queue.filter(q => q.status == "crisscrossed" && !done.some(d => d == q.sid)).forEach((q, i) => {
    //  done.push(q.sid);
    //  _queue.push(
    //    <a key={"queue-" + q.sid}
    //      className="dropdown-item disabled pt-0 pb-0"
    //      href="#"
    //    >
    //      <span>
    //        <span className="badge badge-dark"><i className="fas fa-headset fa-fw"></i></span> <CalledPartyLabel q={q} /> {q.phonenumber} ({q.destination})
    //      </span>
    //    </a>
    //  );
    //});
    queue.filter(q => q.status != "enqueued" && q.status != "crisscrossed" && !done.some(d => d == q.sid)).forEach((q, i) => {
      done.push(q.sid);
      _queue.push(
        <a key={"queue-" + q.sid}
          className="dropdown-item disabled pt-0 pb-0"
          href="#"
          onClick={() => call(q)}
        >
          <span>
            <span className="badge badge-info"><i className="fas fa-retweet fa-fw"></i></span> <CalledPartyLabel q={q} /> {q.phonenumber}
          </span>
        </a>
      );
      _queue.push(
        <a key={"divider-" + q.sid}
          className="dropdown-item pt-0 mb-1 disabled"
          style={{ color: "#6c757d", borderBottom: "1px dashed #e9ecef", fontWeight: 700 }}
        >
          <span>
          </span>
        </a>
      );
    });
  }
  if (_queue.length == 1) {
    _queue.push(<a key={"queue-empty"} className="dropdown-item disabled pt-0 pb-0" style={{ color: "#6c757d" }}>Empty</a>);
  }

  return _queue;
}

function CalledPartyLabel({ q }) {
  if (q) {
    switch (q.to) {
      case "+390294754019":
        return (
          <span className={"badge"} style={{ backgroundColor: "#FFDC00", color: "black" }}>GA</span>
        );
      case "+390294759421":
        return (
          <span className={"badge"} style={{ backgroundColor: "#FF851B" }}>CG</span>
        );
    }
  }

  return null;
}

export default NavBarTwilio;
