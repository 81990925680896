import React from 'react';

import AuthProvider from './subcmps/AuthProvider';

//stylish
import { theme } from './theme/theme';
import { MuiThemeProvider } from '@material-ui/core/styles';

//State management
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import configureStore from './store/configureStore';
const authStore = configureStore();

class Reauth extends React.Component{
  render() {
    return (
      <Provider store={authStore}>
        <MuiThemeProvider theme={theme}>
          <AuthProvider {...this.props} />
        </MuiThemeProvider>
      </Provider>
    );
  }
}

Reauth.propTypes = {
  //entryPoint: PropTypes.object,
  endpoint: PropTypes.string.isRequired,
  onAuth: PropTypes.func //handle authentication process
};

export default Reauth;
