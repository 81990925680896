import React from 'react';
import { connect } from 'react-redux';

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { DrawerMail } from "./drawer-mail.jsx";
import { DrawerMemo } from "./drawer-memo.jsx";
import { DrawerLog } from "./drawer-log.jsx";

import { OrderTagger } from "./drawer-order-tagger.jsx";

class DrawerOrder extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    var ret = null;
    var { drawerIsOpen, order, mail, options } = this.props;

    if (drawerIsOpen) {
      ret = (
        <Drawer
          className="bo-orders-drawer"
          anchor="right"
          open={drawerIsOpen}
          onClose={(e) => this.props.toggleDrawer(e)}
          classes={{
            paper: "bo-orders-drawer-paper"
          }}
        >
          <div style={{ justifyContent: "flex-end" }}>
            <IconButton onClick={(e) => this.props.toggleDrawer(e)}>
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Divider />
          <div style={{ fontWeight: 700, padding: 10, fontFamily: "Roboto Condensed" }}>Order {order.cartid}</div>
          <div style={{ padding: "0px 10px 10px 10px" }}>
            <OrderTagger
              processActions={this.props.processActions}
              order={order}
            />
          </div>
          {mail &&
            <DrawerMail
              processActions={this.props.processActions}
              handleCancel={this.props.toggleDrawer}
              isProcessing={this.props.isProcessing}
              order={order}
              mail={mail}
              agents={this.props.agents}
            />
          }
          <DrawerLog
            processActions={this.props.processActions}
            isProcessing={this.props.isProcessing}
            order={order}
            options={options}
            mail={mail}
            agents={this.props.agents}
            user={this.props.user}
          />
        </Drawer>
      );
    }

    return ret;
  }
}

const mapStateToProps = state => {
  return {
    agents: state.todo.agents,
    //distributors: state.orders.distributors,
    //paywith: state.orders.paywith,
    //warranty_options: state.orders.warranty_options,
    //searchBox: state.orders.searchBox,
    //modal: state.orders.modal,
    //chips: state.orders.chips,
    //orders: state.orders.results,
    //user: state.orders.user,
    //minicart: state.minicart,

    //wheelFittingFees: state.orders.wheelFittingFees,
    //sensorUnitStandardPrice: state.orders.sensorUnitStandardPrice,

    isProcessing: state.isProcessing.mostRecentStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    //updateChipsSearchBox: (chips) => dispatch(updateChipsSearchBox(chips)),
    //updateSearchBox: (data) => dispatch(updateSearchBox(data)),
    //updateModal: (data) => dispatch(updateModal(data)),

    //animateCart: (data) => dispatch(animateCart(data)),

    //pusherTrigger: (data) => dispatch(pusherTrigger(data)),

    //doSomething: (url, payload, options) => dispatch(doSomething(url, payload, options)),
    //doSomethingSpecific: (url, payload) => dispatch(doSomethingSpecific(url, payload))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(DrawerOrder);

