import React from 'react';
import LocalLogin from './LocalLogin';
import LocalRegistration from './LocalRegistration';
import LocalForgot from './LocalForgot';

import Slide from '@material-ui/core/Slide';

class LocalProvider extends React.Component{

  constructor(props){
    super(props);
    this.state = {login: true, register: false, forgot: false};
  }

  handleNavigation = (where) => {
    let navigation = { login: false, register: false, forgot: false, [where]: true };
    this.setState(navigation);
  }

  handleAuth = (body) => {
    this.props.onAuth(body);
  }

  handleRegistration = (body) => {
    this.props.onRegistration(body);
  }

  handleForgot = (body) => {
    this.props.onForgot(body);
  }

  render(){
    return (
      <div>
        <Slide direction="left" in={this.state.login} mountOnEnter unmountOnExit>
          <LocalLogin fields={this.props.loginFields}
            handleAuth={this.handleAuth}
            handleForgot={this.handleForgot}
            handleNavigation={this.handleNavigation}
            enableAuthAction={this.props.enableAuthAction}
            error={this.props.authError}
            success={this.props.authSuccess} />
        </Slide>
        <Slide direction="right" in={this.state.register} mountOnEnter unmountOnExit style={{ transitionDelay: this.state.register ? '200ms' : '0ms' }}>
          <LocalRegistration
            fields={this.props.registrationFields}
            handleRegistration={this.handleRegistration}
            handleNavigation={this.handleNavigation}
            enableRegisterAction={this.props.enableRegisterAction}
            error={this.props.signupError}
            success={this.props.signupSuccess} />
        </Slide>
        <Slide direction="right" in={this.state.forgot} mountOnEnter unmountOnExit style={{ transitionDelay: this.state.register ? '200ms' : '0ms' }}>
          <LocalForgot
            handleForgot={this.handleForgot}
            handleNavigation={this.handleNavigation}
            enableForgotAction={this.props.enableForgotAction}
            error={this.props.signupError}
            success={this.props.signupSuccess} />
        </Slide>
      </div>
    );
  }

}

export default LocalProvider;
