import React from 'react';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Error from '@material-ui/icons/ErrorOutlined';
import Check from '@material-ui/icons/Check';
import DynamicField from './DynamicField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from '../theme/style';
import { Localize } from "../../../scripts/shared/localize.js";
class LocalRegistration extends React.Component{

    constructor(props){
      super(props);
      let state = {
        fields: [{ name: 'email', type: 'email', label: Localize.L("Email"), autoComplete: 'email', pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/, required: true }],
        email: ""
      };

      //initialize state with given fields names
      this.state = state;
    }

    handleChange = name => event => {
      let field = this.state.fields.filter((field) => field.name === name)[0];
      this.setState({ [name]: field.type !== "checkbox" ? event.target.value : event.target.checked });
    }

    handleForgot = () => {
      if (this.state.email) {
        this.props.handleNavigation("login")
        this.props.handleForgot({ email: this.state.email });
      }
    }

    render(){
      let {classes} = this.props;
      let {fields} = this.state;

      let invalidFields = fields.filter((field) => {
        if(field.required && (this.state[field.name] === "" || this.state[field.name] === false)){
          return true;
        }
        return false;
      }).length > 0;

      return (
        <Paper elevation={0}>
          <form style={{ marginBottom: 15 }}>
            {fields.map((field) =>
              <DynamicField key={"forgot_" + field.name} {...field} value={this.state[field.name]} handleChange={this.handleChange} />
            )}
            <br/>
            {this.props.enableForgotAction && (
              <Button disabled={invalidFields} variant="contained" color="primary" onClick={this.handleForgot} style={{display:'block', margin:'0 auto'}}>
                Richiedi password
              </Button>)}
              {!this.props.enableForgotAction && (<LinearProgress />)}
          </form>

          <Typography variant="subtitle1" style={{margin: '5px 0'}}>
            {Localize.L("Hai già un account?")}
            <strong className={classes.primaryText} onClick={() => this.props.handleNavigation("login")}> {Localize.L("Accedi")} </strong>
          </Typography>
        </Paper>
      );
    }
}

export default withStyles(styles)(LocalRegistration);