import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

function NavBarUserMenu(props) {

  const {
    ui,
    name,
    handleClick } = props;

  return (
    <ul className="navbar-nav mr-10" style={{ paddingLeft: 10 }}>
      <li className={"nav-item dropdown" + (ui.usermenu.show ? " show" : "")}
        ref={ui.usermenu.ref}>
        <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          style={{ position: "relative" }}
          onClick={(e) => handleClick(e)}
          name="usermenu">
          <i className="fas fa-user-circle"></i>
        </a>
        <div className={"dropdown-menu dropdown-menu-right" + (ui.usermenu.show ? " show" : "")}>
          <a className="dropdown-item disabled" href="#">{name}</a>
          <div className="dropdown-divider"></div>
          <a className="dropdown-item" href="/logout"><i className="fas fa-sign-out-alt"></i> Log Out</a>
        </div>
      </li>
    </ul>
  );
  //return (
  //  <ul className="navbar-nav mr-10" style={{paddingLeft: 10}}>
  //    <li className={"nav-item dropdown" + (ui.usermenu.show ? " show" : "")}
  //      ref={ui.usermenu.ref}>
  //      <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
  //        style={{ position: "relative" }}
  //        onClick={(e) => handleClick(e)}
  //        name="usermenu">
  //        <i className="fas fa-user-circle"></i>
  //      </a>
  //      <div className={"dropdown-menu dropdown-menu-right" + (ui.usermenu.show ? " show" : "")}>
  //        <a className="dropdown-item disabled" href="#">{name}</a>
  //        <div className="dropdown-divider"></div>
  //        <a className="dropdown-item" href="/refresh-cache"><i className="fas fa-sync-alt"></i> Refresh cache</a>
  //        <div className="dropdown-divider"></div>
  //        <a className="dropdown-item" href="/logout"><i className="fas fa-sign-out-alt"></i> Log Out</a>
  //      </div>
  //    </li>
  //  </ul>
  //);
}

export default NavBarUserMenu;