import React, { useState, useEffect } from 'react';
import {
  ResponsiveContainer, BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import HttpRequest from '../../../gcomponents/utils/HttpRequest.js';
import HistoryWheels from './wheels.jsx';
import HistoryTires from './tires.jsx';
import HistoryRedis from './redis.jsx';
import HistorySales from './sales.jsx';
import HistorySalesDetails from './salesDetails.jsx';

const FetchData = (id) => {
  var url = "/bo/history";

  //dispatch(isProcessing({
  //  key: url,
  //  isProcessing: true
  //}));

  var xhr = new HttpRequest("POST", url, "application/json");
  var payload = {
    id: id
  };

  return xhr.send(payload).then((response) => {
    //dispatch(isProcessing({
    //  key: url,
    //  isProcessing: false
    //}));
    return {
      ...response.json
    }
  }).catch((e) => {
    //dispatch(isProcessing({
    //  key: url,
    //  isProcessing: false
    //}));
  });
}

function HistoryDash({ id }) {
  // Declare a new state variable, which we'll call "count"
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!id) {
      id = "/dashboard/all";
    } else {
      id = "/dashboard/" + id;
    }
    FetchData(id).then(fresh => {
      //console.log("!!!fresh", fresh.data);
      setData(fresh.data);
    });
  }, []);

  var redis = null;
  var tires = null;
  var wheels = null;
  var spares = null;
  var sales = [];
  var salesDetails = [];
  var catalogue = [];
  var destinations = []
  var tireAuthorityReviews = [];
  if (data && data.some(d => d.id)) {
    var chunk = data.find(d => d.id == "/redis/counters");
    if (chunk) {
      redis = chunk.data;
    }
    chunk = data.find(d => d.id == "/tires-online/counters");
    if (chunk) {
      tires = chunk.data;
    }
    chunk = data.find(d => d.id == "/wheels-online/counters");
    if (chunk) {
      wheels = chunk.data;
    }
    chunk = data.find(d => d.id == "/spare-online/counters");
    if (chunk) {
      spares = chunk.data;
    }
    sales = data.filter(d => d.id.includes("/sales/")).map(d => {
      // "/sales/gomme-auto.it"
      return {
        data: d.data,
        domain: d.id.replace("/sales/", "")
      };
    });
    chunk = data.find(d => d.id == "/catalogues");
    if (chunk) {
      catalogue = chunk.data;
    }
    chunk = data.find(d => d.id == "/destinations");
    if (chunk) {
      destinations = chunk.data;
    }
    chunk = data.find(d => d.id == "/blog/tire-authority-reviews");
    if (chunk) {
      tireAuthorityReviews = chunk.data;
    }
  }

  if (data && id == "sales-details") {
    var domains = data.map(d => d.domain);
    domains = [...new Set(domains)];
    var years = data.map(d => d.yyyy);
    years = [...new Set(years)];
    const mostRecentYear = Math.max(...years);
    var weeks = data.map(d => d.wk);
    weeks = [...new Set(weeks)];
    salesDetails = domains.map(domain => {
      var mostRecentYearSalesVolume = 0;
      var domainData = data.filter(d => d.domain == domain);
      var k = { domain: domain };
      ["tires", "wheels", "spare"].forEach(p => {
        const data = weeks.map(w => {
          var t = { wk: w };
          years.forEach(y => {
            var details = domainData.find(d => d.wk == w && d.yyyy == y);
            t = { ...t, [y]: (details ? details[p] : 0) }
            if (y == mostRecentYear && details) {
              mostRecentYearSalesVolume += details[p];
            }
          });
          return t;
        });

        k = { ...k, [p]: data, mostRecentYearSalesVolume: mostRecentYearSalesVolume};
      });
      return k;
    });

  }

  var _historySalesDetails = salesDetails.sort((a, b) => { return b.mostRecentYearSalesVolume - a.mostRecentYearSalesVolume}).map((s, i) => {
    return <div className="row" key={"_historySalesDetails-" + i}>
      <div className="col">
        <HistorySalesDetails
          data={{ tires: s.tires, wheels: s.wheels, spare: s.spare }}
          domain={s.domain}
        />
      </div>
    </div>;
  });

  var _historySales = sales.map((s, i) => {
    return <div className="row" key={"_historySales-" + i}>
      <div className="col">
        <HistorySales
          data={s.data}
          domain={s.domain}
        />
      </div>
    </div>;
  });

  var _wheels = null;
  if (wheels) {
    _wheels = <div className="row mt-5"><div className="col">
      <HistoryWheels data={wheels} />
    </div></div >;
  }

  var _spares = null;
  if (spares) {
    //var strokes = ["#0077df", "#60b5ff"];
    //<HistoryWheels data={spares} title="Spares on sale" strokes={strokes}/>
    _spares = <div className="row mt-1"><div className="col">
      <HistoryWheels data={spares} title="Spares on sale"/>
    </div></div >;
  }

  var _tires = null;
  if (tires) {
    _tires = <div className="row mt-1"><div className="col">
      <HistoryTires data={tires} />
    </div></div>
      ;
  }

  var _redis = null;
  if (redis) {
    _redis = <div className="row mt-5">
      <div className="col">
        <HistoryRedis data={redis} />
      </div>
    </div>;
  }

  //var _grouped = null;
  //if (_redis || _wheels || _spares || _tires) {
  //  _grouped = <div className="row mt-5">
  //    {_wheels}
  //    {_spares}
  //    {_tires}
  //  </div>;
  //}
  //{ _grouped }

  return (
    <div>
      <Catalogues data={catalogue} />
      <Destinations data={destinations} />
      <TireAuthorityReviews data={tireAuthorityReviews} />
      {_historySales}
      {_historySalesDetails}
      {_wheels}
      {_spares}
      {_tires}
      {_redis}
    </div>
  );
}

function Catalogues({data}) {
  var ret = null;

  if (data && data.length > 0) {
    var maduka = [];
    data.filter(d => d.destination == "maduka").forEach((v, i) => {
      maduka.push(<div key={"maduka-" + i} style={{ lineHeight: 1 }}>
        <span>{v.mi}</span>m ago, <span>{v.updatedon.toISOString()}</span>
      </div>);
    });
    var ebay = [];
    data.filter(d => d.destination == "ebay").forEach((v, i) => {
      ebay.push(<div key={"ebay-" + i} style={{lineHeight: 1}}>
        <span>{v.mi}</span>m ago,         <span>{v.updatedon.toISOString()}</span>
      </div>);
    });
    var platforms = [];
    data.filter(d => d.destination == "platforms").forEach((v, i) => {
      platforms.push(<div key={"platforms-" + i} style={{ lineHeight: 1 }}>
        <span>{v.mi}</span>m ago, <span>{v.updatedon.toISOString()}</span>
      </div>);
    });

    ret = (
      <div className="row pb-2" style={{ fontFamily:"Roboto Condensed", fontSize: "75%"}}>
        <div className="col pl-5">
          <span style={{ fontWeight: 700 }}>catalogue update cerchigomme.it/gomme-auto.it</span>
          {maduka}
        </div>
        <div className="col pl-5">
          <span style={{ fontWeight: 700 }}>catalogue update tyre24/gomzon/07zr/camodo</span>
          {platforms}
        </div>
        <div className="col pl-5">
          <span style={{ fontWeight: 700 }}>catalogue update ebay</span>
          {ebay}
        </div>
      </div>
    );
  }

  return ret;
}

function Destinations({ data }) {
  var ret = null;

  if (data && data.length > 0) {
    var cols = []
    const ids = [...new Set(data.map(obj => obj.destination))];
    ids.forEach((v) => {
      var rows = []
      data
        .filter(f => f.destination == v)
        .slice(0,10)
        .forEach((d, i) => {
          var href = null;
        var icon = null
        if (d.fatal) {
          icon = <i className="fas fa-exclamation-triangle text-danger float-right"></i>
          href = "//feeds.gomme-auto.it/logs/"+d.guid
        }

        rows.push(
          <div
            key={"r:" + v + "#" + i}
            style={{ lineHeight: 1 }}>
            <a href={href} target="_blank">
              <span>{d.mi}</span>m ago, <span>{d.createdon.toISOString()}</span>
              {icon}
            </a>
          </div>
        )
      })
      cols.push(
        <div
          key={"d:" + v}
          className="col">
          <span style={{ fontWeight: 700 }}>{v}</span>
          {rows}
        </div>
      )
    })

    ret = (
      <div className="row pb-2" style={{ fontFamily: "Roboto Condensed", fontSize: "75%" }}>
        {cols}
      </div>
    );
  }

  return ret;
}

function TireAuthorityReviews({ data }) {
  var ret = null;

  if (data && data.length > 0) {
    var cols = []
    var rows = []
    const d = data[0];
    const reviews = d.lastReviews;
    reviews
      .slice(0, 10)
      .forEach((v, i) => {
        var href = "https://wp.gomme-auto.it/wp-admin/post.php?post=" + v.tyre_post_id + "&action=edit";
        var icon = null;

        rows.push(
          <div
            key={"r:" + v.tyre_post_id + "#" + i}
            style={{ lineHeight: 1 }}>
            <a href={href} target="_blank">
              <span>{v.tire}</span> <span>{v.title}</span>
              {icon}
            </a>
            <span className="px-2">by {v.author}</span> <span>{v.createdon.toISOString()}</span>
          </div>
      )
    })

    const lastcreated = new Date(d.lastcreated);
    const lastupdated = new Date(d.lastupdated);
    cols.push(
      <div
        key={"tire-authority-reviews:"}
        className="col">
        <span style={{ fontWeight: 700 }}>{d.hm} tires authority reviews</span>
        <span className="px-2">lastcreated: {lastcreated.toISOString()}</span>
        <span className="px-2">lastupdated: {lastupdated.toISOString()}</span>
        {rows}
      </div>
    )

    ret = (
      <div className="row py-2" style={{ fontFamily: "Roboto Condensed", fontSize: "75%", border: "2px solid #d27800" }}>
        {cols}
      </div>
    );
  }

  return ret;
}

export default HistoryDash;

