export const ACTN_REFRESH = "ACTN_REFRESH";
export const ACTN_REFRESH_ONE = "ACTN_REFRESH_ONE";
export const ACTN_REFRESH_SINGLE_AFTER_UPDATE = "ACTN_REFRESH_SINGLE_AFTER_UPDATE";

export const ACTN_ALL_TIRES = "GET_ALLTIRES";
export const ACTN_REFRESH_DATABASE = "ACTN_REFRESH_DATABASE";
export const ACTN_UPDATE_CHIPS = "ACTN_UPDATE_CHIPS";
export const ACTN_UPDATE_BRAND = "ACTN_UPDATE_BRAND";
export const ACTN_UPDATE_SEARCHBOX = "ACTN_UPDATE_SEARCHBOX";
export const ACTN_UPDATE_SOMETHING = "ACTN_UPDATE_SOMETHING";
export const ACTN_REFRESH_RESULTS = "ACTN_REFRESH_RESULTS";
export const ACTN_IS_PROCESSING = "ACTN_IS_PROCESSING";

export const ORDERS_UPDATE_CHIPS = "ORDERS_UPDATE_CHIPS";
export const ORDERS_UPDATE_SEARCHBOX = "ORDERS_UPDATE_SEARCHBOX";
export const ORDERS_UPDATE_MODAL = "ORDERS_UPDATE_MODAL";
export const ORDERS_REFRESH_RESULTS = "ORDERS_REFRESH_RESULTS";
export const ORDERS_REFRESH_SPECIFIC = "ORDERS_REFRESH_SPECIFIC";
export const ORDERS_REFRESH_ONE = "ORDERS_REFRESH_ONE";
export const ORDERS_UPDATE_APPROVING = "ORDERS_UPDATE_APPROVING";

export const MINICART_UPDATE = "MINICART_UPDATE";
export const MINICART_ANIMATE = "MINICART_ANIMATE";

export const TODO_UPDATE_SEARCHBOX = "TODO_UPDATE_SEARCHBOX";

export const DRAWER_UPDATE = "DRAWER_UPDATE";

export const PUSHER_CLIENT_EVENT = "PUSHER_CLIENT_EVENT";

export const ORDER_SHIPPING_CLOSEWORKINGDAY = "ORDER_SHIPPING_CLOSEWORKINGDAY";