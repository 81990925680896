import React from 'react';

export default class ProgressLoading extends React.Component {
  constructor(props) {
    super(props);
    this.state = { progress: 0, elapsed: 0, isVisible: false};
    this.spinTimeout = null;
    this.delay = 100;
    this.safety = 30 * 10 * this.delay;
  }

  componentDidMount() {
    if (this.props.processing) {
      this.updateState();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //if (this.spinTimeout) {
    //  window.clearTimeout(this.spinTimeout);
    //}

    if (prevProps.processing !== this.props.processing) {
      this.updateState();
    }
  }

  updateState = () => {
    if (this.props.processing) {
      this.updateProgress();
    } else {
      this.cleanUp();
    }
  }

  cleanUp = () => {
    window.clearTimeout(this.spinTimeout);
    this.setState({ progress: 0, elapsed: 0, isVisible: true }, () => {
      this.spinTimeout = window.setTimeout(() => {
        this.setState({ progress: 0, elapsed: 0, isVisible: false });
      }, 5 * this.delay);
    });

    //this.spinTimeout = window.setTimeout(() => {
    //  this.setState({ progress: 0, elapsed: 0, isVisible: true }, () => {
    //    window.setTimeout(() => {
    //      this.setState({ progress: 0, elapsed: 0, isVisible: false });
    //    }, 3 * this.delay);
    //  }); 
    //}, this.delay);
  }

  updateProgress = () => {

    window.clearTimeout(this.spinTimeout);
    const state = { progress: this.state.progress + 1, elapsed: this.state.elapsed + this.delay, isVisible: true };
    this.setState(state);
    //console.log("progress-bar-first", "updateProgress", state);

    this.spinTimeout = window.setTimeout(() => {
      const state = { progress: this.state.progress + 1, elapsed: this.state.elapsed + this.delay, isVisible: true };
      this.setState(state);
      //console.log("progress-bar-timeout", "updateProgress", state);
      if (this.state.elapsed <= this.safety) {
        this.updateProgress();
      }
    }, this.delay);
  }
  
  render() {
    var ret = null;

    if (this.state.isVisible) {
      const { message = "Loading...", keys } = this.props;

      //console.log("@@@ProgressLoading", "message", message, message.length);
      //console.log("@@@ProgressLoading", "keys", keys);

      var width = this.state.progress === 0 ? 100
        : 100 * Math.log(this.state.progress) / Math.log(100 + this.state.progress);
      //console.log("progress-bar", "width", this.state, width);
      ret = (
        <div className="progress-container">
          <div
            ref={this.spinRef}
            className="progress-bar"
            style={{ width: width + "%" }}>
          </div>
          <div className="progress-bar-hello">
            {message}
            </div>
        </div>
      );
      //if (width != 100)
      //  ret = (
      //    <div className="progress-container">
      //      <div
      //        ref={this.spinRef}
      //        className="progress-bar"
      //        style={{ width: width + "%" }}>
      //      </div>
      //      <div className="progress-bar-hello">
      //        Loading...
      //      </div>
      //    </div>
      //  );
      //else
      //  ret = (<div className="progress-container"></div>);
    }

    return ret;
  }
 
}
