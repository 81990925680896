import React from 'react';
//json editor and validation components
import { JsonEditor as Editor } from 'jsoneditor-react';
import '../../../wwwroot/es/editor.min.css';

import ace from 'brace';
import Ajv from 'ajv';
//editor styling
import 'brace/mode/json';
import 'brace/theme/github';
import 'brace/ext/searchbox';
import { withStyles } from '@material-ui/core/styles';
import styles from '../theme/style';

const ajv = new Ajv({ allErrors: true, verbose: true });

class File extends React.Component{
  render() {
    return (
      <Editor
        value={this.props.value}
        mode="code"
        onChange={this.props.onChange}
        ace={ace}
        ajv={ajv}
        theme="ace/theme/github"
        schema={this.props.schema}
      />
    );
  }
}

export default withStyles(styles)(File);
