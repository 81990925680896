import TurndownService from 'turndown'
//var turndownService = new TurndownService()

import React from 'react'
const ReactMarkdown = require('react-markdown')
import { listItem as defaultListItem } from 'react-markdown/lib/renderers';
//import React from 'react';
//import InputTrigger from 'react-input-trigger';
import Dropzone from 'react-dropzone'
import * as marked from 'marked';
import { borderTop } from '@material-ui/system';


function MyDropzone() {
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p>Drag 'n' drop some files here, or click to select files</p>
      }
    </div>
  )
}

const renderListItem = props => {
  if (props.checked !== null && props.checked !== undefined) {
    const element = props.children[0];
    return (
      <li><input type="checkbox" style={{ display: "inline-block" }} /> {<span {...props}></span>}</li>
    );
  }
  // otherwise default to list item
  return defaultListItem(props);
}

const Markdown = "markdown";
const Preview = "preview";

const Italic = "italic";
const Bold = "bold";
const UL = "ul";

export class DrawerMail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      viewmode: Markdown,

      mail: props.mail,

      users: this.props.agents.map(a => a.clientid),

    };

    window.setTimeout(() => {
      this.taRef.selectionStart = this.taRef.selectionEnd = 0;
      this.taRef.focus();
    }, 50);

  }

  componentDidUpdate(prevProps, prevState) {
  }

  toggleView = (e) => {
    const viewmode = this.state.viewmode == Markdown ? Preview : Markdown;
    this.setState({ viewmode: viewmode });
  }

  handleTextareaInput = (event) => {
    const { value } = event.target;
    var { mail } = this.state;

    this.setState({
      mail: { ...mail, body: value }
    })
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var { mail } = this.state;
    this.setState({ mail: { ...mail, [name]: value } });
  }

  handleKeyUp = (e) => {
    console.log("shiftKey", e.shiftKey);
    console.log("ctrlKey", e.ctrlKey);
    console.log("altKey", e.altKey);
    console.log("keyCode", e.keyCode);
    console.log("which", e.which);
    console.log("key", e.key);
    if (e.ctrlKey) {
      switch (e.which) {
        case 66:
          this.applyToSelectedText(e, Bold);
          break;
        case 73:
          this.applyToSelectedText(e, Italic);
          break;
      }
    }
  }

  applyToSelectedText = (e, style) => {
    var { mail } = this.state;
    var start = this.taRef.selectionStart;
    var finish = this.taRef.selectionEnd;
    var body = mail.body;
    switch (style) {
      case Italic:
        body = [body.slice(0, start), "_", body.slice(start, finish), "_", body.slice(finish)].join('');
        break;
      case Bold:
        body = [body.slice(0, start), "**", body.slice(start, finish), "**", body.slice(finish)].join('');
        break;
      case UL:
        body = [body.slice(0, start), "* ", body.slice(start, finish), body.slice(finish)].join('');
        break;
    }
    this.setState({ mail: { ...mail, body: body } });
  }

  handleSendEmail = (e) => {
    var { mail } = this.state;

    var payload = { mail: { ...mail, body: marked(mail.body) } };
    this.props.processActions(
      "email-send",
      this.props.order || null, null, e,
      payload
    )
      .then((d) => {
        if (d && d.sent) {
          this.setState({ mail: { ...mail, sent: true } });
        }
        console.log(d);
        //this.setState({
        //  textareaValue: "",
        //  highlightedText: ""
        //})
      });
  }

  render() {
    var {
      viewmode,
      mail
    } = this.state;
    var { isProcessing, replyingTo } = this.props;

    //console.log("drawer-memo render", showSuggestor, boundingClientRect);
    var actionsBtns = null;
    actionsBtns = (
      <div style={{ marginTop: 10 }} className="row no-gutters">
        <div className="col">
        </div>
        <div className="col" style={{ textAlign: "right" }}>
          <button
            className={"btn btn-sm btn-outline-secondary mr-2"}
            onClick={(e) => this.props.handleCancel(e)}
          >Cancel</button>
          <button
            className={"btn btn-sm btn-primary " + (!mail.body ? " disabled" : "")}
            disabled={!mail.body || isProcessing || mail.sent}
            onClick={(e) => this.handleSendEmail(e)}
          >Send email</button>
          {mail.sent && <small className="form-text text-muted" style={{ color: "green !important"}}><i className="fas fa-check"></i> mail sent.</small>}
        </div>
      </div>
    );

    var body = null;
    switch (viewmode) {
      case Markdown:
        body = (
          <div className="drawer-memo-ta-container"
            ref={element => this.containerRef = element}
          >
            <textarea
              className="drawer-memo-ta-textarea"
              placeholder="Write an email..."
              spellCheck={false}
              id="popperino-ta"
              ref={element => this.taRef = element}

              disabled={mail.sent}
              onChange={this.handleTextareaInput}
              onKeyUp={e => this.handleKeyUp(e)}
              value={mail.body}
            />
          </div>
        );
        break;
      case Preview:
        body = (
          <div className="drawer-memo-ta-container"
            ref={element => this.containerRef = element}
          >
            <div className="drawer-mail-preview p-2">
              <ReactMarkdown
                source={mail.body}
                //renderers={{ listItem: renderListItem }}
              />
            </div>
          </div>
        );
        break;
    }

    return (
      <div className="drawer-mail"
        style={{
          position: 'relative', padding: "0px 15px",
          margin: !replyingTo ? "0px 0px" : "0px -15px"
        }}
      >
        <div className="form-group row">
          <label className="col col-form-label col-form-label-sm" style={{maxWidth: 40}}>From</label>
          <div className="col">
            <input type="email" className="form-control form-control-sm"
              name="sender"
              value={mail.sender}
              onChange={e => this.handleInputChange(e)}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col col-form-label col-form-label-sm" style={{ maxWidth: 40 }}>To</label>
          <div className="col">
            <input type="email" className="form-control form-control-sm"
              name="receiver"
              value={mail.receiver}
              onChange={e => this.handleInputChange(e)}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col">
            <input type="email" className="form-control form-control-sm"
              name="subject"
              value={mail.subject}
              onChange={e => this.handleInputChange(e)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
              <ul className="nav nav-pills">
                <li className="nav-item">
                  <a className={"nav-link " + (viewmode == Markdown ? " active" : "")} href="#" onClick={e => this.toggleView(e)}>Write</a>
                </li>
                <li className="nav-item">
                  <a className={"nav-link" + (viewmode == Preview ? " active" : "")} href="#" onClick={e => this.toggleView(e)}>Preview</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col">
            <div className="btn-toolbar" role="toolbar" style={{justifyContent: "flex-end"}}>
              <div className="btn-group btn-group-sm mr-2" role="group">
                <button type="button" className="btn btn-light" onClick={e => this.applyToSelectedText(e, Italic)}><i className="fas fa-italic"></i></button>
                <button type="button" className="btn btn-light" onClick={e => this.applyToSelectedText(e, Bold)}><i className="fas fa-bold"></i></button>
                <button type="button" className="btn btn-light" onClick={e => this.applyToSelectedText(e, UL)}><i className="fas fa-list-ul"></i></button>
              </div>
            </div>
          </div>
        </div>

        <div onKeyDown={this.handleKeyDown} style={{ fontSize: "10pt" }}>
          {body}
          <div className="drawer-memo-drag-and-drop">
            <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <section style={{ cursor: "pointer" }}>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          {actionsBtns}
          <hr style={{marginLeft: -10, marginRight: -10}}/>
        </div>
      </div>
    );
  }
}

