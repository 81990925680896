
import {
  ACTN_REFRESH,
  MINICART_UPDATE,
  MINICART_ANIMATE
} from '../config/action-types.js';

const initialMiniCartState = {
};

const _initialMiniCartState = JSON.parse(JSON.stringify(initialMiniCartState));

export default function (state = initialMiniCartState, action) {
  switch (action.type) {

    case ACTN_REFRESH:
      if (action.data && action.data.minicart) {
        if (!action.data.minicart.cartid || (!action.data.minicart.items && !action.data.minicart.wheels && !action.data.minicart.rows)) {
          return {
            ..._initialMiniCartState,
            ticks: (action.data.minicart || {}).ticks
          }
        } else {
          return {
            ...state,
            ...action.data.minicart
          }
        }
      }
      return state;

    case MINICART_ANIMATE:
      return {
        ...state,
        animation: action.data
      }

    default:
      return state;
  }
}