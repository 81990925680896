import {
  ACTN_REFRESH,
  ACTN_REFRESH_DATABASE,
  ORDERS_REFRESH_ONE,
  DRAWER_UPDATE
} from '../config/action-types.js';


const initialDrawerState = {
  isOpen: false,
  order: undefined,
  memos: [],
};

export default function (state = initialDrawerState, action) {
  switch (action.type) {

    case ACTN_REFRESH:
      var _state = { ...state };
      //in response to a new list of memos/todo
      if (action.data.todo && action.data.todo.list && action.data.todo.list.length) {
        _state = { ..._state, memos: action.data.todo.list.filter(t => !t.deleted) };
      }
      //in resposne to an action on a single memo/todo
      //is commented because pusher is usually faster resending the whole list before the httppost return any body
      //if (action.data.action && action.data.action.todo && action.data.action.todo.length) {
      //  _state = {
      //    ..._state, memos: _state.memos.map(m => {
      //      var todo = action.data.action.todo.find(t => t.hashkey == m.hashkey);
      //      return todo ? todo : m;
      //    })
      //  };
      //}
      return _state;

    case ACTN_REFRESH_DATABASE:
      return state;
      
    case ORDERS_REFRESH_ONE:
      if (action.data && action.data.results[0] && state.order && state.order.cartid && action.data.results[0].cartid == state.order.cartid) {
        return {
          ...state,
          order: action.data.results[0]
        }
      } else {
        return state;
      }

    case DRAWER_UPDATE:
      return {
        ...state,
        ...action.data
      }
      break;

    default:
      return state;
  }
}