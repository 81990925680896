import React, { useState, useEffect } from 'react';
import {
  ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';


function HistoryTires(props) {
  // Declare a new state variable, which we'll call "count"

  const crunchData = (data) => {
    if (data) {
      return !data || data
        .sort((a, b) => moment(a.createdon).isBefore(b.createdon) ? -1 : 1)
        .map(d => {
          return {
            ...d,
            name: moment(d.createdon).format("HH:mm")//moment(d.createdon).diff(moment(), "minutes") + "min"
          }
        });
    } else {
      return undefined;
    }
  }

  const [_data, setData] = useState(crunchData(props.data));

  useEffect(() => {
    setData(crunchData(props.data));
  }, [props.data]);

  return (
    <div>
      <div style={{ fontFamily: "Roboto Condensed", textAlign: "center", fontSize: "1.5rem" }}>Tires on sales</div>
      <ResponsiveContainer width="100%" height={200}>
        <AreaChart
          data={_data}
          syncId="bubu"
          margin={{
            top: 5, right: 0, left: 0, bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name"
            tick={{ fontFamily: "Roboto Condensed", fontSize: ".75rem" }} />
          <YAxis
            tick={{ fontFamily: "Roboto Condensed", fontSize: ".75rem" }}
            tickFormatter={tick => {
            return tick / 1000 + "K";
          }} />
          <Tooltip />
          <Legend />
          <Area type="monotone" dataKey="summer" stackId="1" stroke="#BEDB39" fill="#BEDB39" dot={true} />
          <Area type="monotone" dataKey="winter" stackId="1" stroke="#588F27" fill="#588F27" dot={true} />
          <Area type="monotone" dataKey="allseason" stackId="1" stroke="#A8C545" fill="#A8C545" dot={true} />
        </AreaChart>

      </ResponsiveContainer>
    </div>
  );
}

export default HistoryTires;

