import React from 'react';
import LocalProvider from './LocalProvider';
import '../theme/style.css';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import GoogleProvider from './GoogleProvider';
import FacebookProvider from './FacebookProvider';
import { Localize } from "../../../scripts/shared/localize";
//import GoogleLogo from "../assets/google.png";
//import FacebookLogo from "../assets/facebook.png";

//state management
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionAuth, actionForgot, actionSignup, actionError } from '../store/actions';

//Provide common usefull authentication interface
class AuthProvider extends React.Component{

  constructor(props){
    super(props);
    this.state = { open: false, enableAuthAction: true, enableRegisterAction: true, enableForgotAction: true };
  }

  componentDidMount(){
    let object;

    var icon = null;
    if (this.props.componentFaIconClass) {
      icon = <i className={this.props.componentFaIconClass} />;
    }

    switch (this.props.component) {
      case 'a':
        object = (<a href="#" className={this.props.componentClass} onClick={this.handleOpen}>{icon} {this.props.componentText}</a>);
        break;
      case 'div':
        object = (<div className={this.props.componentClass} onClick={this.handleOpen}>{this.props.componentText}</div>);
        break;
      default:
        object = (<span className={this.props.componentClass} onClick={this.handleOpen}>{this.props.componentText}</span>);
    }

    this.setState({component: object});
  }

  onAuth = (response) => {
    if(this.props.onAuth){
      this.props.onAuth(response);
    }else{
      window.location.reload();
    }
  }

  onForgot = (response) => {
    if (this.props.onForgot) {
      this.props.onForgot(response);
    } else {
      //window.location.reload();
    }
  }

  onSignup = (response) => {
    if (this.props.onAuth) {
      this.props.onSignup(response);
    } else {
      window.location.reload();
    }
  }

  handleOpen = () => {
    this.setState({open: true});
  }

  handleClose = () => {
    this.setState({open: false});
  }

  handleSubmitLogin = (authBody, provider = "local") => {
    this.setState({enableAuthAction: false});
    this.props.dispatch(actionAuth(this.props.endpoint, provider, authBody)).then(()=>{
      if(this.props.errors.length === 0){
        this.onAuth(this.props.authResponse, provider);
      }
      this.setState({enableAuthAction: true});
    }).catch(()=>{
      this.setState({enableAuthAction: true});
    });
  }

  handleForgotPassword = (forgotBody) => {
    this.setState({ enableAuthAction: false, enableForgotAction: false });
    this.props.dispatch(actionForgot(this.props.endpoint, forgotBody)).then(() => {
      if (this.props.errors.length === 0) {
        this.onForgot(this.props.forgotResponse, provider);
      }
      this.setState({ enableAuthAction: true, enableForgotAction: true });
    }).catch(() => {
      this.setState({ enableAuthAction: true, enableForgotAction: true });
    });
  }

  handleSubmitRegistration = (registrationBody, provider = "local") => {
    this.setState({enableRegisterAction: false});
    this.props.dispatch(actionSignup(this.props.endpoint, registrationBody)).then(()=>{
      if(this.props.signupErrors.length === 0){
        this.onSignup(this.props.signupResponse, provider);
      }
      this.setState({enableRegisterAction: true});
    }).catch(()=>{
      this.setState({enableRegisterAction: true});
    });
  }

  onError = (error) => {
    this.props.dispatch(actionError(error));
  }

  render(){
    let { errors, signupErrors, authSuccess, signupSuccess } = this.props;
    let error = "", signupError = "";
    let entryPoint = this.state.component;
    if(errors.length > 0)
      error = errors[0].message;
    if(signupErrors.length > 0)
      signupError = signupErrors[0].message;

    return (
      <div>
        {entryPoint}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          className="authdialog"
        >
          <DialogTitle id="form-dialog-title">Sign in</DialogTitle>
          <DialogContent style={{minWidth:450, maxWidth:450, minHeight:450, maxHeight:450, overflow: 'hidden'}}>
            <LocalProvider onAuth={this.handleSubmitLogin}
                           onRegistration={this.handleSubmitRegistration}
                           onForgot={this.handleForgotPassword}
                           enableAuthAction={this.state.enableAuthAction}
                           enableRegisterAction={this.state.enableRegisterAction}
                           enableForgotAction={this.state.enableForgotAction}
                           loginFields={this.props.loginFields}
                           registrationFields={this.props.registrationFields}
                           authError={error}
                           signupError={signupError}
                           authSuccess={authSuccess}
                           signupSuccess={signupSuccess}/>

            <div style={{height:100, left:0, right: 0, bottom: 0, position: 'absolute', background: 'white', zIndex:100, padding: '0 24px'}}>
              <div style={{textAlign: 'center', position:'relative'}}><hr/><div style={{
                position: 'absolute',top: '-16px',left: '50%',marginLeft: '-80px',background: 'white',padding: '4px'
              }}>{Localize.L("Oppure continua con")}</div></div>

              <div style={{margin: '0 auto', width:144}}>
                <GoogleProvider onAuth={this.handleSubmitLogin} onError={this.onError} />
                <FacebookProvider onAuth={this.handleSubmitLogin} />
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = function(state){
  return {
    authResponse: state.auth.authResponse,
    signupResponse: state.auth.signupResponse,
    errors: state.auth.errors,
    signupErrors: state.auth.signupErrors,
    authSuccess: state.auth.authSuccess,
    signupSuccess: state.auth.signupSuccess
  }
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ actionAuth, actionSignup, actionError });
  return { ...actions, dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider);