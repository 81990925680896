import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getCaretCoordinates from 'textarea-caret';

function getHookObject(type, element, startPoint, typedText) {
  const caret = getCaretCoordinates(element, element.selectionEnd);

  const result = {
    hookType: type,
    cursor: {
      selectionStart: element.selectionStart,
      selectionEnd: element.selectionEnd,
      top: caret.top,
      left: caret.left,
      height: caret.height,
    },
  };

  if (!startPoint) {
    return result;
  }

  var endPoint = element.selectionEnd > startPoint ? element.selectionEnd : startPoint;
  result.text = element.value.substring(startPoint, endPoint);
  //console.log("InputTrigger getHookObject", result.text, startPoint, endPoint);
  //console.log("InputTrigger getHookObject", typedText);
  ////result.text = element.value.substr(startPoint, element.selectionStart);
  //result.text = typedText;

  return {
    ...result,
    cursor: {
      ...result.cursor,
      selectionEnd: endPoint
    }
  };
}

class InputTrigger extends Component {
  constructor(args) {
    super(args);

    this.state = {
      triggered: false,
      triggerStartPosition: null,
      typedText: ""
    };

    this.handleTrigger = this.handleTrigger.bind(this);
    this.resetState = this.resetState.bind(this);
    this.element = this.props.elementRef;
  }

  componentDidMount() {
    this.props.endTrigger(this.resetState);
  }

  handleTrigger(event) {
    const {
      trigger,
      onStart,
      onCancel,
      onType,
    } = this.props;

    const {
      key,
      which,
      shiftKey,
      metaKey,
      ctrlKey,
    } = event;

    const { selectionStart } = event.target;
    const { triggered, triggerStartPosition } = this.state;

    if (!triggered) {
      console.log("react-input-trigger", which, key, shiftKey);
      if (
        key === trigger.key
        //&&
        //shiftKey === !!trigger.shiftKey &&
        //ctrlKey === !!trigger.ctrlKey &&
        //metaKey === !!trigger.metaKey
      ) {
        this.setState({
          triggered: true,
          triggerStartPosition: selectionStart + 1,
        }, () => {
          setTimeout(() => {
            onStart(getHookObject('start', this.element));
          }, 0);
        });
        return null;
      }
    } else {
    //if (which === trigger.keyCode &&
    //  shiftKey === !!trigger.shiftKey &&
    //  ctrlKey === !!trigger.ctrlKey &&
    //  metaKey === !!trigger.metaKey) {
    //  if (!triggered) {
    //    this.setState({
    //      triggered: true,
    //      triggerStartPosition: selectionStart + 1,
    //    }, () => {
    //      setTimeout(() => {
    //        onStart(getHookObject('start', this.element));
    //      }, 0);
    //    });
    //    return null;
    //  }
    //  //else {
    //  //  this.setState({
    //  //    triggered: false,
    //  //    triggerStartPosition: null,
    //  //    typedText: ""
    //  //  }, () => {
    //  //    setTimeout(() => {
    //  //      onCancel(getHookObject('cancel', this.element));
    //  //      this.setState({
    //  //        triggered: true,
    //  //        triggerStartPosition: selectionStart + 1,
    //  //      }, () => {
    //  //        setTimeout(() => {
    //  //          onStart(getHookObject('start', this.element));
    //  //        }, 0);
    //  //      });
    //  //    }, 0);
    //  //  });
    //  //  return null;
    //  //}
    //} else {
      if (key === "Backspace" && selectionStart <= triggerStartPosition) {
        this.setState({
          triggered: false,
          triggerStartPosition: null,
          typedText: ""
        }, () => {
          setTimeout(() => {
            onCancel(getHookObject('cancel', this.element));
          }, 0);
        });
        return null;
      }

      //console.log("InputTrigger", which, this.state.typedText);

      //if (which && (which >= 65 && which <= 90 || which >= 97 && which <= 122)) {
      //  const typedText = this.state.typedText + key;
      //  this.setState({
      //    typedText: typedText
      //  }, () => {
      //    setTimeout(() => {
      //      onType(getHookObject('typing', this.element, triggerStartPosition, typedText));
      //    }, 0);
      //    });
      //  return null;
      //}

      setTimeout(() => {
        onType(getHookObject('typing', this.element, triggerStartPosition, this.state.typedText));
      }, 0);
    }

    return null;
  }

  resetState() {
    this.setState({
      triggered: false,
      triggerStartPosition: null,
      typedText: ""
    });
  }

  render() {
    const {
      elementRef,
      children,
      trigger,
      onStart,
      onCancel,
      onType,
      endTrigger,
      ...rest
    } = this.props;

    return (
      <div
        role="textbox"
        tabIndex={-1}
        onKeyDown={this.handleTrigger}
        {...rest}
      >
        {
          !elementRef
            ? (
              React.Children.map(this.props.children, child => (
                React.cloneElement(child, {
                  ref: (element) => {
                    this.element = element;
                    if (typeof child.ref === 'function') {
                      child.ref(element);
                    }
                  },
                })
              ))
            )
            : (
              children
            )
        }
      </div>
    );
  }
}

InputTrigger.propTypes = {
  trigger: PropTypes.shape({
    key: PropTypes.string,
    shiftKey: PropTypes.bool,
    ctrlKey: PropTypes.bool,
    metaKey: PropTypes.bool,
  }),
  onStart: PropTypes.func,
  onCancel: PropTypes.func,
  onType: PropTypes.func,
  endTrigger: PropTypes.func,
  children: PropTypes.element.isRequired,
  elementRef: PropTypes.element,
};

InputTrigger.defaultProps = {
  trigger: {
    key: null,
    shiftKey: false,
    ctrlKey: false,
    metaKey: false,
  },
  onStart: () => { },
  onCancel: () => { },
  onType: () => { },
  endTrigger: () => { },
  elementRef: null,
};

export default InputTrigger;