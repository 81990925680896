import React from 'react';

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import styles from '../theme/style';

class DynamicField extends React.Component{

  handleSubmit = (event) => {
    if (this.props.handleSubmit) {
      this.props.handleSubmit();
    }
  }

  render(){
    let isInputText = ["text", "password", "email"].indexOf(this.props.type) !== -1;
    let isCheckbox = this.props.type === "checkbox";
    let {classes} = this.props;
    
    return (
      <div>
        { isInputText && (
          <TextField name={this.props.name} value={this.props.value} type={this.props.type}
                     label={this.props.label}
                     onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                          ev.preventDefault();
                          this.handleSubmit();
                        }
                     }}
                     autoComplete={this.props.autoComplete} required={this.props.required}
                     error={this.props.required && this.props.value === "" ? true : false}
                     margin="normal"
                     onChange={this.props.handleChange(this.props.name)}
                     fullWidth style={{marginTop: 0}}/>
        ) }

        { isCheckbox && (
          <FormControlLabel
            className="checkbox"
            control={
              <Checkbox
                required={this.props.required}
                checked={this.props.checked}
                onChange={this.props.handleChange ? this.props.handleChange(this.props.name) : ()=>{}}
              />
            }
            label={(<span className={this.props.required && this.props.value === false ? classes.errorText : "" }>{this.props.label} {this.props.required ? "*" : ""}</span>)}
          />
        ) }
      </div>
    );
  }

}

export default withStyles(styles)(DynamicField);
