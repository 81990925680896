export function handleRinger(queue, calls, ringtone, user) {
  var ringtoneEnabled = user.ringtoneEnabled !== false;
  var isRinging = true == localStorage.getItem('ringing');
  //console.log("!!!ringtone", "isRinging", isRinging);
  if (queue && queue.some(q => q.status == "crisscrossed" && q.destination == user.twilio_clientid)
    || calls && calls.some(c => c.userid == user.guid)) {
    //user is already in a call
    if (isRinging) {
      ringtone.pause();
      ringtone.currentTime = 0;
      localStorage.setItem('ringing', false);
    }
  } else {
    if (queue.some(q => q.status == "enqueued") && !isRinging && ringtoneEnabled) {
      //ring
      //console.log("!!!ringtone", "ring");
      localStorage.setItem('ringing', true);
      ringtone.addEventListener('ended', () => {
        //console.log("!!!ringtone", "ended", ringtone);
      }, false);
      ringtone.play();
    } else if (isRinging) {
      //stop ringing if ringing
      //console.log("!!!ringtone", "stop ringing");
      ringtone.pause();
      ringtone.currentTime = 0;
      localStorage.setItem('ringing', false);
    }
  }
}

export function handleInboundCall(data, queue, calls) {

  console.log("!!!twilio", "handleInboundCall", queue, data);

  if (data) {
    Object.keys(data).forEach((key) => (data[key] == null) && delete data[key]);
    if (queue.length && queue.some((v, i) => { return v.sid == data.sid || (data.phonenumber && data.phonenumber == v.phonenumber) })) {
      queue = queue.map((v, i) => {
        if (v.sid == data.sid || (data.phonenumber && data.phonenumber == v.phonenumber)) {
          return { ...v, ...data };
        }
        else {
          return v;
        }
      });
    } else {
      queue.push(data);
    }
  }

  switch (data.status) {
    case 'connected':
    case 'connecting':
    case 'crisscrossed':
      //var ringtone = this.state.ringtone;
      //ringtone = this.ringer('kill');
      //ringtone.safety = 0;
      //var dequeing = Object.assign({}, this.state.dequeing);
      //if (data.destination == this.props.clientid && this.state.twilio.waitingConnection) {
      //  dequeing = data;
      //  dequeing.cartid_candidate = {};
      //}
      //this.setState({
      //  queue: queue,
      //  ringtone: ringtone,
      //  dequeing: dequeing,
      //  twilio: Object.assign({}, this.state.twilio, { waitingConnection: false })
      //});
      //console.log("state","crisscrossed", this.state);
      break;
    case 'enqueued':
      //var incoming = data;
      //if (queue.length) {
      //  incoming = queue.find((v) => v.sid == data.sid || (data.phonenumber && data.phonenumber == v.phonenumber));
      //}
      //var ringtone = this.state.ringtone;
      //if (!((this.state.dequeing && this.state.dequeing.sid) || (this.state.current_connection && this.state.current_connection.status() != 'closed'))) {
      //  console.log("this.props.clientid", this.props.clientid);
      //  if (this.props.clientid && this.props.clientid.toLowerCase() != "max") {
      //    console.log("this.props.clientid", this.props.clientid, "RING!!!");
      //    ringtone = this.ringer('ring');
      //  }
      //}
      //this.setState({
      //  queue: queue,
      //  incoming: incoming,
      //  ringtone: ringtone
      //});
      //console.log("state","enqueued", this.state);
      break;
    case 'incoming':
      //var incoming = null;
      //if (queue.length) {
      //  incoming = queue.find((v) => v.sid == data.sid || (data.phonenumber && data.phonenumber == v.phonenumber));
      //}
      //if (data && !incoming) {
      //  incoming = data;
      //}
      //this.setState({
      //  queue: queue,
      //  incoming: incoming
      //});
      //console.log("state","incoming", this.state);
      break;
    case 'bridged':
    case 'bridging-in-process':
      break;
    case "queue-full":
    case 'redirected':
    case "redirected-from-bridged":
    case 'leave':
    case 'hangup':
    case 'error':
    case 'system-error':
    case 'end':
      //var ringtone = this.state.ringtone;
      //var dequeing = Object.assign({}, this.state.dequeing);
      //if (dequeing && data.sid == dequeing.sid && data.status == "end")
      //  dequeing = null;
      //ringtone.safety = 0;
      if (queue.length) {
        queue = queue.filter((v, i) => { return !(v.sid == data.sid || (data.phonenumber && data.phonenumber == v.phonenumber)) });
      }
      if (calls.length) {
        calls = calls.filter((v, i) => { return !(v.sid == data.sid || (data.phonenumber && data.phonenumber == v.phonenumber)) });
      }
      //var incoming = Object.assign({}, this.state.incoming);
      //if (queue.length > 0)
      //  incoming = queue[queue.length - 1];
      //else
      //  incoming = null;
      //this.setState({
      //  queue: queue,
      //  incoming: incoming,
      //  dequeing: dequeing,
      //  ringtone: ringtone
      //});
      //console.log("pusher", data.status, this.state);
      break;
  }

  return { queue, calls };
}

export function handleOutboundCall(data, queue, calls) {

  console.log("!!!twilio", "handleOutboundCall", data);

  if (data) {
    Object.keys(data).forEach((key) => (data[key] == null) && delete data[key]);
    if (calls.length && calls.some((v, i) => { return v.sid == data.sid || (data.phonenumber && data.phonenumber == v.phonenumber) })) {
      calls = calls.map((v, i) => {
        if (v.sid == data.sid || (data.phonenumber && data.phonenumber == v.phonenumber)) {
          return { ...v, ...data };
        }
        else {
          return v;
        }
      });
    } else {
      calls.push(data);
    }
  }

  switch (data.status) {
    case 'connected':
    case 'connecting':
    case 'crisscrossed':
      //var ringtone = this.state.ringtone;
      //ringtone = this.ringer('kill');
      //ringtone.safety = 0;
      //var dequeing = Object.assign({}, this.state.dequeing);
      //if (data.destination == this.props.clientid && this.state.twilio.waitingConnection) {
      //  dequeing = data;
      //  dequeing.cartid_candidate = {};
      //}
      //this.setState({
      //  queue: queue,
      //  ringtone: ringtone,
      //  dequeing: dequeing,
      //  twilio: Object.assign({}, this.state.twilio, { waitingConnection: false })
      //});
      //console.log("state","crisscrossed", this.state);
      break;
    case 'enqueued':
      //var incoming = data;
      //if (queue.length) {
      //  incoming = queue.find((v) => v.sid == data.sid || (data.phonenumber && data.phonenumber == v.phonenumber));
      //}
      //var ringtone = this.state.ringtone;
      //if (!((this.state.dequeing && this.state.dequeing.sid) || (this.state.current_connection && this.state.current_connection.status() != 'closed'))) {
      //  console.log("this.props.clientid", this.props.clientid);
      //  if (this.props.clientid && this.props.clientid.toLowerCase() != "max") {
      //    console.log("this.props.clientid", this.props.clientid, "RING!!!");
      //    ringtone = this.ringer('ring');
      //  }
      //}
      //this.setState({
      //  queue: queue,
      //  incoming: incoming,
      //  ringtone: ringtone
      //});
      //console.log("state","enqueued", this.state);
      break;
    case 'incoming':
      //var incoming = null;
      //if (queue.length) {
      //  incoming = queue.find((v) => v.sid == data.sid || (data.phonenumber && data.phonenumber == v.phonenumber));
      //}
      //if (data && !incoming) {
      //  incoming = data;
      //}
      //this.setState({
      //  queue: queue,
      //  incoming: incoming
      //});
      //console.log("state","incoming", this.state);
      break;
    case 'bridged':
    case 'bridging-in-process':
      break;
    case "queue-full":
    case 'redirected':
    case "redirected-from-bridged":
    case 'leave':
    case 'hangup':
    case 'error':
    case 'system-error':
    case 'end':
    case 'completed':
    case 'no-answer':
      if (calls.length) {
        calls = calls.filter((v, i) => { return !(v.sid == data.sid || (data.phonenumber && data.phonenumber == v.phonenumber)) });
      }
      break;
  }

  return { queue, calls };
}

