import React from 'react';
import { connect } from 'react-redux';
import { doSomething, animateCart } from '../../gcomponents/store/actions/actMiniCart';

class NavBarMiniCart extends React.Component {
  constructor(props) {
    super(props)

    this.cartRef = React.createRef();

  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    var { animation } = this.props.minicart;
    if (animation !== prevProps.minicart.animation) {
      if (animation && animation.animate) {
        window.setTimeout(() => {
          this.cartRef.current.style.animation = "0.75s "
            + "wobble"
            //+ (animation.animation || "heartBeat")
            + " ease";
          window.setTimeout(() => {
            this.cartRef.current.style.animation = "none";
            window.setTimeout(() => {
              this.cartRef.current.style.animation = "0.75s "
                + "wobble"
                //+ (animation.animation || "wobble")
                + " ease";
              window.setTimeout(() => {
                this.cartRef.current.style.animation = "none";
                window.setTimeout(() => {
                  this.cartRef.current.style.animation = "0.75s "
                    + "wobble"
                    //+ (animation.animation || "wobble")
                    + " ease";
                  window.setTimeout(() => {
                    this.cartRef.current.style.animation = "none";
                    this.props.animateCart({});
                  }, 500);
                }, 0);
              }, 1000);
            }, 0);
          }, 1000);
        }, 0);
      } else {
      }
    }
  }

  deleteItemFromCart = (e, item) => {
    const payload = {
      action: "remove-tire", cartid: item.cartid, items: [{ itemid: item.itemid }]
    };
    this.props.doSomething("/bo/minicart/update", payload);
  }

  deleteWheelFromCart = (e, wheel) => {
    const payload = {
      action: "remove-wheel", cartid: wheel.cartid, wheels: [{ itemwheelid: wheel.itemid }]
    };
    this.props.doSomething("/bo/minicart/update", payload);
  }

  render = () => {

    //console.log("NavBarMiniCart", "isProcessing", this.props.isProcessing);

    const { ui, name } = this.props;

    var { minicart } = this.props;

    var items = [];
    var badge = null;
    if ((minicart.items && minicart.items.length) || (minicart.wheels && minicart.wheels.length)) {
      badge = (
        <span name="minicart" className="badge badge-pill badge-danger" style={{ position: 'absolute', top: '-5%', right: '25%', backgroundColor: 'rgba(207, 0, 15, .65)' }}>{(minicart.items || []).length + (minicart.wheels || []).length}</span>
      );
      if (minicart.items && minicart.items.length) {
        items.push(
          <a
            key={"title-tires"}
            className="dropdown-item disabled"
            style={{
              fontWeight: 700, borderTop: "1px solid rgba(0, 0, 0, 0.15)", color: "white", backgroundColor: "#e69c3d"}}
            href="#">Tires</a>
        );
        //<i class="tire-icon-summer"></i>
        minicart.items.forEach((v, i, a) => {
          var icon = null;

          if (v.Season == 'EST' && !v.MUDSNOW) {
            icon = <span><i className="tire-icon-summer"></i></span>
          } else if (v.Season == 'INV') {
            icon = <span><i className="tire-icon-winter"></i> <i className="tire-icon-ms"></i></span>;
          } else if (v.Season == 'ALL' && v.THREEPMSF) {
            icon = <span><i className="tire-icon-summer"></i> <i className="tire-icon-winter"></i> <i className="tire-icon-ms"></i></span>;
          } else if ((v.Season == 'ALL' && !v.THREEPMSF) || (v.Season == 'EST' && v.MUDSNOW)) {
            icon = <span><i className="tire-icon-summer"></i> <i className="tire-icon-ms"></i></span>
          }
          items.push(
            <div key={"tire-" + i} className="row align-items-center justify-content-end dropdown-item" style={{ flexWrap: "nowrap", borderTop: "1px solid rgba(0,0,0,.15)", padding: "0.25rem 1.15rem", display: "flex", margin: 0 }}>
              <div className="col pl-0" style={{ overflowX: "auto" }}>
                <div>
                  <div>{v.Brand} {v.Pattern}</div>
                  <div>{icon} {v.Width}/{v.Ratio} {v.Diameter} {v.Load} {v.Speed} {v.IsRunFlat ? "RUNFLAT" : ""} {v.Additionalinfo ? v.Additionalinfo : ""} {v.dot ? <span>dot {v.DOT}</span> : ""}</div>
                </div>
                <div style={{ paddingTop: 3 }}>
                  {v.qty} x {v.prices.bestprice.pretty} = {v.lineTotals.totalToPay.pretty}
                </div>
              </div>
              <div className="col-2 pr-0">
                <button
                  className={"btn btn-link " + (this.props.isProcessing ? " disabled" : "")}
                  disabled={this.props.isProcessing}
                  style={{ color: "#ddd", paddingLeft: 0, paddingRight: 0 }}
                  onClick={(e) => this.deleteItemFromCart(e, v)}
                >
                  <i className="fas fa-trash"></i>
                </button>
              </div>
            </div>
          );
        });
      }
      if (minicart.wheels && minicart.wheels.length) {
        items.push(
          <a
            key={"title-wheels"}
            className="dropdown-item disabled"
            style={{ fontWeight: 700, borderTop: "1px solid rgba(0, 0, 0, 0.15)", color: "white", backgroundColor: "#e69c3d" }}
            href="#">Wheels</a>
        );
        //<i class="tire-icon-summer"></i>
        minicart.wheels.forEach((v, i, a) => {
          items.push(
            <div key={"wheel-" + i} className="row align-items-center justify-content-end dropdown-item" style={{ flexWrap: "nowrap", borderTop: "1px solid rgba(0,0,0,.15)", padding: "0.25rem 1.15rem", display: "flex", margin: 0 }}>
              <div className="col pl-0" style={{ overflowX: "auto" }}>
                <div>
                <div>{v.details.Brand} {v.details.Model} ({v.details.Color})</div>
                  <div>{v.details.Holes} holes {v.details.Diameter}' {v.details.WheelId}</div>
                </div>
                <div style={{ paddingTop: 3 }}>
                  {v.qty} x {v.prices.bestprice.pretty} = {v.lineTotals.totalToPay.pretty}
                </div>
              </div>
              <div className="col-2 pr-0">
                <button
                  className={"btn btn-link " + (this.props.isProcessing ? " disabled" : "")}
                  disabled={this.props.isProcessing}
                  style={{ color: "#ddd", paddingLeft: 0, paddingRight: 0 }}
                  onClick={(e) => this.deleteWheelFromCart(e, v)}
                >
                  <i className="fas fa-trash"></i>
                </button>
              </div>
            </div>
          );
        });
      }
    } else {
      items.push(
        <a key={"empty"} className="dropdown-item disabled" href="#">Empty</a>
      );
    }

    return (
      <ul className="navbar-nav mr-10" style={{paddingLeft: 10}}>
        <li className={"nav-item dropdown" + (ui.minicart.show ? " show" : "")}
          ref={ui.minicart.ref}>
          <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            style={{ position: "relative" }}
            onClick={(e) => this.props.handleClick(e)}
            name="minicart"
            ref={this.cartRef}
          >
            <i className="fas fa-shopping-cart"></i>
            {badge}
          </a>
          <div className={"dropdown-menu dropdown-menu-right" + (ui.minicart.show ? " show" : "")}>
            {items}
          </div>
        </li>
      </ul>
    );
  }
}


const mapStateToProps = state => {
  return {
    minicart: state.minicart,
    isProcessing: state.isProcessing.mostRecentStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    animateCart: (data) => dispatch(animateCart(data)),
    doSomething: (url, payload) => dispatch(doSomething(url, payload))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(NavBarMiniCart);
