import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';

const SmallInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: "#f8f9fa",
    border: '1px solid #f8f9fa',
    fontSize: 18,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: 4,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },
}));

export default function FolderSwitcher({ folders, handleFolderSwitch }) {
  const classes = useStyles();
  const [selected, setSelected] = React.useState(folders ? folders[0].value : "");

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  const handleChange = e => {
    setSelected(e.target.value);
    handleFolderSwitch(e, e.target.value)
  };

  var mitems = folders.map(f => {
    return <MenuItem key={f.value} value={f.value}>{f.icon && <span><i className={f.icon}></i>&#160;</span>} <span style={{ fontWeight: 700, fontFamily: "Roboto Condensed" }}>{f.label}</span></MenuItem>;
  });
  
  return (
    <div>
      <FormControl className={classes.formControl}>
        <Select
          className={classes.selectEmpty}
          value={selected}
          onChange={handleChange}
          input={<SmallInput name="selected"/>}
          displayEmpty 
        >
          {mitems}
        </Select>
        {1 == 2 && <FormHelperText>Without label</FormHelperText>}
      </FormControl>
    </div>
  );
}
