import React from 'react';
import { connect } from 'react-redux';
import { doSomething, animateCart } from '../../gcomponents/store/actions/actMiniCart';
import { updateDrawer } from "\../../gcomponents/store/actions/actDrawer";

import memoize from "memoize-one";

class NavBarMemos extends React.Component {
  constructor(props) {
    super(props)

    this.todoRef = React.createRef();

    this.safety = 2;
  }

  wobble = () => {
    window.setTimeout(() => {
      //console.log("notifications_hm", "wobble");
      this.todoRef.current.style.animation = "0.75s "
        + "wobble"
        //+ (animation.animation || "heartBeat")
        + " ease";
      //this.todoRef.current.style.animationIterationCount = 3;
      window.setTimeout(() => {
        this.todoRef.current.style.animation = "none";
        if ((this.safety--) > 0) {
          this.wobble();
        }
      }, 1000);
    }, 2500);
  }

  componentDidMount() {
    var { todo } = this.props;

    var notifications_hm = this.badgeCounter((todo.list || []));

    if (notifications_hm) {
      this.wobble();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    var notifications_hm = this.badgeCounter((this.props.todo.list || []));
    var old_notifications_hm = this.badgeCounter((prevProps.todo.list || []));

    if (notifications_hm && notifications_hm != old_notifications_hm && notifications_hm > 0) {
      this.safety = 3;
      this.wobble();
    }
  }

  handleClick = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    switch (name) {
      default:
        this.props.updateDrawer({ isOpen: true, memos: this.props.todo.list });
        break;
    }
  }

  badgeCounter = memoize((list) => {

    //var notifications_hm = (todo.counters || []).filter(c => c.taskid.startsWith("notification-") && c.processed != true).reduce((a, v) => { return a + v.hm }, 0);
    return list.filter(c => c.taskid.startsWith("notification-") && c.processed != true && c.deleted != true).length;
  });

  render = () => {

    var { todo } = this.props;

    var notifications_hm = this.badgeCounter((todo.list || []));

    var badge = null;
    if (notifications_hm) {
      badge = (
        <span name="minicart" className="badge badge-pill badge-danger" style={{ position: 'absolute', top: '-5%', right: '0%', backgroundColor: 'rgba(207, 0, 15, .65)' }}>{notifications_hm}</span>
      );
    }

    return (
      <ul className="navbar-nav mr-10" style={{ paddingLeft: 10 }}>
        <li className={"nav-item"}
        >
          <a className="nav-link" href="#" role="button" aria-haspopup="true" aria-expanded="false"
            style={{ position: "relative" }}
            disabled={!(notifications_hm) && 1 == 2}
            onClick={(e) => this.handleClick(e)}
            name="memos"
            ref={this.todoRef}
          >
            <i className="fas fa-comment-alt"></i>
            {badge}
          </a>
        </li>
      </ul>
    );
  }
}

const mapStateToProps = state => {
  return {
    drawer: state.drawer,
    todo: state.todo,
    isProcessing: state.isProcessing.mostRecentStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDrawer: (data) => dispatch(updateDrawer(data)),
    //animateCart: (data) => dispatch(animateCart(data)),
    //doSomething: (url, payload) => dispatch(doSomething(url, payload))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(NavBarMemos);
