import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';
import styles from '../theme/style';
import '../theme/style.css';
import File from './File';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SaveIcon from '@material-ui/icons/Save';
import RedoIcon from '@material-ui/icons/Redo';
import LinearProgress from '@material-ui/core/LinearProgress'
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

//import { makeStyles } from '@material-ui/core/styles';
//import TreeView from '@material-ui/lab/TreeView';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import ChevronRightIcon from '@material-ui/icons/ChevronRight';
//import TreeItem from '@material-ui/lab/TreeItem';

import Ajv from 'ajv';

//State management
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionFetch, actionUpdateDraft, actionUpdateFile, actionReset } from '../store/actions';
import PropTypes from 'prop-types';

class Container extends React.Component{

  static draft = {}; //used as temporary memory for last updates in current edited files
  static fileNames = [];
  static prevFileNames = [];
  static prevIndex = 0;
  static tabIndex = 0;

  constructor(props){
    super(props);
    //this state persist current opened tab and if the contextual menu is opened
    this.state = { tab: 0, openedMenu: false, loading: false, error: "" };
  }

  componentDidMount() {
    Container.tabIndex = Container.prevIndex = 0;
    Container.fileNames = Container.prevfileNames = this.props.fileNames;
    this.handleChange({}, 0, this.props.domain, this.props.domain);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.domain === this.props.domain) return;
    
    Container.prevfileNames = Container.fileNames;
    Container.fileNames = this.props.fileNames;
    
    this.handleChange({}, 0, prevProps.domain, this.props.domain);
  }

  handleChange = (event, index, prevDomain, targetDomain) => {
    Container.prevIndex = Container.tabIndex;
    Container.tabIndex = index;

    if (!prevDomain && !targetDomain) { prevDomain = targetDomain = this.props.domain; }
    if (JSON.stringify(Container.draft) !== "{}") {
      let fileNames = this.props.fileNames; let prevFileNames = Container.prevfileNames;
      //handle draft change in same domain
      this.handleChangeDraft(prevDomain, targetDomain, prevFileNames, fileNames, Container.prevIndex, index);
    } else {
      this.setState({ loading: true });
      this.props.dispatch(actionFetch(this.props.endpoint, targetDomain, this.props.fileNames[index], this.props.files)).then(() => {
        Container.draft = {};
        this.setState({ tab: index, loading: false});
      });
    }
  }

  handleChangeDraft = (prevDomain, targetDomain, prevFileNames, fileNames, prevIndex, index) => {
    let self = this;
    let filtered = this.props.files.filter((file) => {
      return (file.name) === prevDomain + "_" + prevFileNames[prevIndex];
    });
    if (filtered.length > 0) {
      this.props.dispatch(actionUpdateDraft(filtered[0], prevDomain, Container.draft)).then(() => {
        this.setState({ loading: true });
        this.props.dispatch(actionFetch(this.props.endpoint, targetDomain, this.props.fileNames[index], this.props.files)).then(() => {
          Container.draft = {};
          this.setState({ tab: index, loading: false });
        });
      });
    }
  }

  handleSaveFile = () => {
    let fileName = this.props.fileNames[this.state.tab];
    let file = this.props.files.filter((file) => {
      return file.name === this.props.domain + "_" + fileName && file.opened;
    });
    if (this.state.loading) return;
    if (file.length === 0) return;
    this.setState({ openedMenu: false, loading: true });

    var ajv = new Ajv(); // AJV validation
    var validate = ajv.compile(file[0].schema);
    
    if (JSON.stringify(Container.draft) !== "{}") {
      var valid = validate(Container.draft);
      if (!valid) { this.setState({ error: ajv.errorsText(validate.errors), loading: false }); return; }
      //current draft is always the most updated version
      this.props.dispatch(actionUpdateFile(this.props.endpoint, this.props.domain, fileName, Container.draft, file[0].schema))
        .then(() => {
          if (this.props.error.length === 0) Container.draft = {};
          this.setState({ loading: false, error: this.props.error });
        });
    }else{
      var valid = validate(file[0].content);
      if (!valid) { this.setState({ error: ajv.errorsText(validate.errors), loading: false }); return; }
      //we have to save changes only if current file opened is drafted
      this.props.dispatch(actionUpdateFile(this.props.endpoint, this.props.domain, fileName, file[0].content, file[0].schema))
        .then(() => {
          if (this.props.error.length === 0) Container.draft = {};
          this.setState({ loading: false, error: this.props.error });
        });
    }
  }

  handleReset = () => {
    let fileName = this.props.fileNames[this.state.tab];
    if (this.state.loading) return;
    this.setState({ loading: true, openedMenu: false})
    this.props.dispatch(actionReset(this.props.endpoint, this.props.domain, fileName)).then(()=>{
      Container.draft = {};
      this.setState({loading: false});
    })
  }

  handleFileChange = (json) => {
    Container.draft = json;
  }

  handleCloseMessage = () => {
    this.setState({ error: "" });
  }

  render(){
    let {props, state} = this;
    let {classes} = props;
    let {openedMenu} = state;

        //{(props.fileNames.length > 0 && 1==2) &&
        //  <FileSystemNavigator
        //  domain={props.domain}
        //  fileNames={props.fileNames}
        ///>
        //}
    return (
      <div>
        <div className={classes.paper}>
          {state.loading && (<LinearProgress />)}
          {!state.loading && (<IconButton style={{ position: "absolute", top: 0, left: 0, height: 48, width: 48, color: 'white', zIndex: 999 }} buttonRef={node => { this.anchorEl = node; }} color="inherit" aria-label="Menu" onClick={() => { this.setState({ openedMenu: !openedMenu }) }}>
            <MenuIcon />
          </IconButton>)}
           {props.fileNames.length > 0 &&
            (<AppBar position="static">
              <Tabs
                value={this.state.tab}
                style={{ paddingLeft: 48 }}
                onChange={this.handleChange}
                indicatorColor="secondary"

                variant="scrollable"
                scrollButtons="on"
              >
                {props.fileNames.map((fileName) => (
                  <Tab key={fileName} label={(
                    <Badge color={props.files.filter((file) => this.props.domain + "_" + fileName === file.name && file.draft).length > 0 ? "secondary" : "primary"} classes={{ badge: classes.badge }} variant="dot">
                      {fileName}
                    </Badge>
                  )} />
                ))}
              </Tabs>
            </AppBar>)
          }
          <Paper square style={{ position: 'absolute', top: '49px', bottom: 0, left: 0, right: 0 }} data-container="container-editor">
            {!state.loading && props.files.filter((file) => file.opened).map((file) => (
              <File key={file.key} value={JSON.stringify(Container.draft) === "{}" ? file.content : Container.draft} schema={file.schema} onChange={this.handleFileChange} />
            ))}
          </Paper>
          <Popper open={openedMenu} anchorEl={this.anchorEl} transition disablePortal placement="bottom-start">
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
              >
                <Paper>
                  <ClickAwayListener onClickAway={() => { this.setState({ openedMenu: false }) }}>
                    <MenuList style={{zIndex: 999}}>
                      <MenuItem className={classes.menuItem} onClick={this.handleSaveFile}>
                        <ListItemIcon className={classes.icon}>
                          <SaveIcon />
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.primary }}  primary="Salva" />
                      </MenuItem>
                      <MenuItem className={classes.menuItem} onClick={this.handleReset}>
                        <ListItemIcon className={classes.icon}>
                          <RedoIcon />
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.primary }} primary="Resetta" />
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={this.state.error.length > 0}
            autoHideDuration={6000}
            onClose={this.handleCloseMessage}
          >
            <SnackbarContent
              className={classes.errorMessage}
              aria-describedby="error-message"
              message={
                <span id="error-message" className={classes.message}>
                  <ErrorIcon className={classes.iconMessage} />
                  {this.state.error}
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={this.handleCloseMessage}
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>,
              ]}
            />
          </Snackbar>

        </div>
      </div>
    );
  }
}

Container.propTypes = {
  fileNames: PropTypes.array.isRequired,
  files: PropTypes.array.isRequired
};

const mapStateToProps = function(state){
  return {
    files: state.jre.files,
    error: state.jre.error
  }
};

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({ actionFetch, actionUpdateDraft, actionUpdateFile, actionReset });
  return { ...actions, dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Container));

//const useStyles = makeStyles({
//  root: {
//    height: 216,
//    flexGrow: 1,
//    maxWidth: 400,
//  },
//});

//function FileSystemNavigator({ domain, fileNames }) {
//  const classes = useStyles();

//  var handleNodeToggle = (e, ids) => {
//    alert(ids);
//  }

//  var items = fileNames.map((fileName) => {
//    var key = domain + "_" + fileName;
//    return (<TreeItem
//      nodeId={key}
//      label={fileName}
//      onClick={e=>handleClick(e,fileName)}
//    />);
//  });

//  return (
//    <TreeView
//      className={classes.root}
//      defaultCollapseIcon={<ExpandMoreIcon />}
//      defaultExpandIcon={<ChevronRightIcon />}
//      expanded={["1"]}
//    >
//      <TreeItem nodeId="1" label="Applications">
//        {items}
//      </TreeItem>
//      <TreeItem nodeId="5" label="Documents">
//        <TreeItem nodeId="6" label="Material-UI">
//          <TreeItem nodeId="7" label="src">
//            <TreeItem nodeId="8" label="index.js" />
//            <TreeItem nodeId="9" label="tree-view.js" />
//          </TreeItem>
//        </TreeItem>
//      </TreeItem>
//    </TreeView>
//  );
//}