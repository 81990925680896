import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import Popper from '@material-ui/core/Popper';
import Popover from '@material-ui/core/Popover';
//import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles(theme => ({
  paper: {
    //border: '1px solid rgba(0,0,0,.15)',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    fontFamily: "Roboto Condensed"
  },
}));

const DrawerItemToolbar = ({ item, trashMemo, replyMemo, snoozeMemo, flagMemoAsRead, toggleDetails, user }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const replyEl = useRef(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleClick = (e, v) => {
    handleFilter(e,v)
  };

  const _replyMemo = (e, item, channel) => {
    if (!channel && !open) {
      setAnchorEl(e.currentTarget);
    } else if (!channel && open && e.currentTarget != replyEl) {
      setAnchorEl(e.currentTarget);
    } else if (!channel && open && e.currentTarget == replyEl) {
      handleClose();
    } else if (channel) {
      replyMemo(e, item, channel);
      handleClose();
    }
  }

  var toolbar = [];
  if (flagMemoAsRead && !item.processed && item.createdby != user.guid) {
    toolbar.push(
      <button key={"bell"} type="button"
        className="btn btn-danger"
        onClick={e => flagMemoAsRead(e, item.hashkey)}
      >
        <span style={{fontFamily: "PT Sans Narrow"}}>NEW</span>
      </button>
    );
  }
  if (item.details && item.details.q_time > 0 && item.details.m_subject) {
    toolbar.push(
      <a
        href={item.details.m_subject}
        key={"play-call"}
        className="btn btn-light"
      >
        <i className="fas fa-play"></i>
      </a>
    );
  }

  if (toggleDetails && item.details && item.details.type == "mail" && (item.details.m_htmlbody || item.details.m_textbody)) {
    toolbar.push(
      <button
        key={"read-email"}
        type="button"
        className="btn btn-light"
        onClick={e => toggleDetails(e, item)}
      >
        <i className="fas fa-envelope-open-text"></i>
      </button>
    );
  }


  if (replyMemo) {
    toolbar.push(
      <button
        key={"reply"}
        type="button"
        className={"btn btn-light " + (open ? " active" : "")}
        onClick={e => _replyMemo(e, item)}
        ref={replyEl}
      >
        <i className="fas fa-reply"></i>
      </button>
    );
  }

  if (snoozeMemo) {
    toolbar.push(
      <button key={"bell"} type="button"
        className="btn btn-light"
        onClick={e => snoozeMemo(e, item.id)}
      >
        <i className="fas fa-bell"></i>
      </button>
    );
  }
  if (trashMemo) {
    toolbar.push(
      <button
        key={"trash"}
        type="button"
        className="btn btn-light"
        onClick={e => trashMemo(e, item.hashkey)}
      >
        <i className="fas fa-trash"></i>
      </button>
    );
  }

  //<Popper id={id} open={open} anchorEl={anchorEl} disablePortal={true} placement={"bottom-start"} style={{ zIndex: 1 }}>
  //  <ClickAwayListener onClickAway={handleClose}>
  //    <div className={classes.paper}>
  //      <a href="#" className="dropdown-item pl-2"><i className="far fa-envelope mr-2"></i> by email</a>
  //      <a href="#" className="dropdown-item pl-2"><i className="far fa-comment-alt mr-2"></i> by internal memo</a>
  //    </div>
  //  </ClickAwayListener>
  //</Popper>
  return (
    <div className="btn-toolbar" role="toolbar">
      <div className="btn-group btn-group-sm" role="group">
        {toolbar}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.paper}>
          <a href="#" className="dropdown-item pl-2" onClick={e => _replyMemo(e, item, "mail")}><i className="far fa-envelope mr-2"></i> by email</a>
          <a href="#" className="dropdown-item pl-2" onClick={e => _replyMemo(e, item, "memo")}><i className="far fa-comment-alt mr-2"></i> by internal memo</a>
        </div>
      </Popover>
    </div>
  );
}
//          <a href="#" className="dropdown-item pl-2" onClick={e => _replyMemo(e, item, "todo")}><i className="fas fa-tasks mr-2"></i> with todo</a>

export default DrawerItemToolbar;