import React from 'react';

export default class DrawerMemoSaveButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var ret = null;
    var { textareaValue, handleSaveComment, handleInputChange, options, order, sendToT24 } = this.props;
    var actionCancel = options && options.action == "cancel";
    var buttonText = actionCancel ? "Cancel" : "Save memo";

    ret = (
      <div>
        <button
          className={"btn btn-sm" + (actionCancel ? " btn-danger" : "  btn-primary") + (!textareaValue ? " disabled" : "")}
          disabled={!textareaValue}
          onClick={(e) => handleSaveComment(e)}
        >{buttonText}</button>
      </div>
    );
    return ret;


  }
}