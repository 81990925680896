import { Device } from 'twilio-client';

import { doSomething as doSomething_cart } from "./actMiniCart.js";
import { doSomething as doSomething_orders } from "./actOrders.js";
import {
  ACTN_REFRESH,
  PUSHER_CLIENT_EVENT,
  ORDERS_UPDATE_APPROVING
} from "../config/action-types.js";

import { invalidate } from '../config/refreshIfStale.js'

export const PUSHER_ORDER_EDIT_STOP = "client-order-edit-stop";
export const PUSHER_ORDER_EDIT_START = "client-order-edit-start";
export const PUSHER_MISSINGANAG_PING = "client-pusher-missinganag-ping";

import { handleInboundCall, handleOutboundCall, handleRinger } from "./twilio/handlers.js";

export const initPusher = () => (dispatch, getState) => {
  var state = getState();

  var { appKey, cluster } = state.pusher;

  if (window.state && window.state.isDevelopment) {
    Pusher.logToConsole = true;
  }

  if (Notification.permission === "granted") {
    //var notification = new Notification("Hi " + window.state.user.displayname, { body: "Thanks!", image: "https://localhost:44327/imgaes/logo-ruota.png" });
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        var notification = new Notification("Hi " + window.state.user.displayname, { body: "Thanks!" });
      }
    });
  }

  const pusher = new Pusher(appKey, { cluster: cluster, authEndpoint: '/bo/pusher/auth' });
  pusher.connection.bind('connected', () => {
    var socket_id = pusher.connection.socket_id;
    dispatch({
      type: ACTN_REFRESH,
      data: {
        pusher: {
          socket_id: socket_id
        }
      }
    })
  });

  /*
   // Portable function you can copy-paste
    function bindWithChunking(channel, event, callback) {
      channel.bind(event, callback); // Allow normal unchunked events.

      // Now the chunked variation. Allows arbitrarily long messages.
      var events = {};
      channel.bind("chunked-" + event, data => {
        if (!events.hasOwnProperty(data.id)) {
          events[data.id] = { chunks: [], receivedFinal: false };
        }
        var ev = events[data.id];
        ev.chunks[data.index] = data.chunk;
        if (data.final) ev.receivedFinal = true;
        if (ev.receivedFinal && ev.chunks.length === Object.keys(ev.chunks).length) {
          callback(JSON.parse(ev.chunks.join("")));
          delete events[data.id];
        }
      });
    }

    // Example usage
    Pusher.logToConsole = true;
    var pusher = new Pusher(YOUR_APP_KEY, {
      cluster: YOUR_CLUSTER,
      forceTLS: true
    });
    var channel = pusher.subscribe('my-channel');
    bindWithChunking(channel, "my-event", data => {
      alert(JSON.stringify(data));
    });
  */

  const presence = pusher.subscribe("presence-ga");
  presence.bind('pusher:subscription_succeeded', (members) => {
    //console.log("!!!pusher-presence", "members.count", members.count);

    var _members = [];
    members.each(function (member) {
      //console.log("!!!pusher-presence", "members", member.id, member.info);
      _members.push(member);
    });

    dispatch({
      type: ACTN_REFRESH,
      data: {
        members: _members
      }
    })
  })
  presence.bind('pusher:member_added', (member) => {
    //console.log("!!!pusher-presence", "member_added", member);
    var state = getState();
    var members = state.pusher.members;
    if (!members.some(m => m.id == member.id)) {
      members.push(member);
    }
    dispatch({
      type: ACTN_REFRESH,
      data: {
        members: members
      }
    })
  })
  presence.bind('pusher:member_removed', (member) => {
    //console.log("!!!pusher-presence", "member_removed", member);
    var state = getState();
    var members = state.pusher.members;
    if (members.some(m => m.id == member.id)) {
      members = members.filter(m => m.id != member.id);
    }
    dispatch({
      type: ACTN_REFRESH,
      data: {
        members: members
      }
    })
  })

  const channel = pusher.subscribe('twilio');    
  channel.bind("inboundcall", data => {
    const state = getState();
    const { queue, calls } = handleInboundCall(data, state.pusher.twilio.queue, state.pusher.twilio.calls);
    const status = state.pusher.twilio.device.status();
    if (status == "ready") {
      handleRinger(queue, calls, state.pusher.twilio.ringtone, state.todo.user);
    }
    dispatch({
      type: ACTN_REFRESH,
      data: {
        twilio: {
          queue: queue,
          calls: calls
        }
      }
    })
  });
  channel.bind("outboundcall", data => {
    const state = getState();
    const { queue, calls } = handleOutboundCall(data, state.pusher.twilio.queue, state.pusher.twilio.calls);
    const status = state.pusher.twilio.device.status();
    dispatch({
      type: ACTN_REFRESH,
      data: {
        twilio: {
          queue: queue,
          calls: calls
        }
      }
    })
  });

  const backoffice = pusher.subscribe("private-backoffice");

  const device = new Device();
  device.setup(window.state.user.twilio_token, { debug: (window.state && window.state.isDevelopment), closeProtection: true });
  const ringtone = new Audio('/audio/ring.mp3');

  backoffice.bind("cart-refresh", (data) => {
    var state = getState();
    //if we own this cartid, which means it is our minicart, just force a minicart refresh from localstorage, it is much probably updated
    //console.log("backoffice", "cart-refresh", data);
    if (data.cartid && (data.cartid == state.minicart.cartid || !state.minicart.cartid)) {
      if (data.reloadFromServer && data.cartid) {
        dispatch(doSomething_cart("/bo/minicart", { cartid: data.cartid }));
      } else {

        // TODO

        // se è presente anche il parametro ticks

        if (data.ticks) {

          let ticks = data.ticks;
          let cartid = data.cartid;
          let counter = 10;

          var countdown = function() {
            let storageState = localStorage.getItem('reduxState') ? JSON.parse(localStorage.getItem('reduxState')) : {};

            if (storageState.minicart) {
              //another tab in the same browser could hold a more updated localstorage
              //due to timing of pusher notification (faster than localstorage update across tabs)
              //we could be updating localstorage with stale data
              window.noLocalStorageUpdate = true;
              //I don't know what is worst, use redux or a global variable

              //console.log("backoffice", "cart-refresh", "window.setTimeout", "dispatch ACTN_REFRESH", data);
              dispatch({
                type: ACTN_REFRESH,
                data: {
                  minicart: {
                    ...storageState.minicart
                  }
                }
              });
            }

            let state = getState();

            if ((state.minicart || {}).ticks == ticks) {
              return;
            } 

            counter--

            if (counter > 0) {
              window.setTimeout(countdown, 1000);
            }
            else {
              dispatch(doSomething_cart("/bo/minicart", { cartid: cartid }));
            }
          };

          window.setTimeout(countdown, 1000);

          // verifichiamo che coincida con quello presente sui dati nel local storage

          // riproviamo il controllo per 10 volte poi (forziamo il caricamento dal server ???)

        }

        window.setTimeout(() => {
          let storageState = localStorage.getItem('reduxState') ? JSON.parse(localStorage.getItem('reduxState')) : {};
          if (storageState.minicart) {
            //another tab in the same browser could hold a more updated localstorage
            //due to timing of pusher notification (faster than localstorage update across tabs)
            //we could be updating localstorage with stale data
            window.noLocalStorageUpdate = true;
            //I don't know what is worst, use redux or a global variable

            //console.log("backoffice", "cart-refresh", "window.setTimeout", "dispatch ACTN_REFRESH", data);
            dispatch({
              type: ACTN_REFRESH,
              data: {
                minicart: {
                  ...storageState.minicart
                }
              }
            })
          }
        }, 4 * 250);
      }
      //console.log("pusher", state.minicart);
    }
    //or if have this cartid in our orders.results list, force a refresh of that specific result[n]
    //TO-DO
  });

  backoffice.bind("feeds-update", (data) => {
    console.log("!!!pusher", "feeds-update", data);
    var state = getState();
    var { results } = state.orders;
    var { user } = state.todo;
    var { disabled, running, feedexception } = data;

    dispatch({ type: ACTN_REFRESH, data: { feeds: { disabled: disabled, running: running, feedexception: feedexception } } });
  });

  backoffice.bind("orders-refresh", (data) => {
    //console.log("!!!pusher", "orders-refresh", data);
    var state = getState();
    var { results } = state.orders;
    var { user } = state.todo;
    var { orders, agent } = data;

    if (results && results.length && orders && orders.length) {
      var changed = results.filter(r => orders.some(o => o === r.cartid));
      changed.forEach(c => {
        var url = "/bo/orders/order/" + c.cartid;
        dispatch(doSomething_orders(url, {}, { refreshOne: true }));
      });
      if (changed.length) {
        if (user.guid != agent) {
          var notification = new Notification(`${changed.length} orders updated!`, { body: `You have ${changed.length} orders open in your browser which were somehow changed by someone else: ${changed.map(c => c.cartid).join(",")}.` });
        }
      }
    }
  });

  backoffice.bind("order-approving", (data) => {
    var state = getState();
    var { user } = state.todo;
    var { cartid, agent } = data;

    if (state.orders.approving && state.orders.approving.cartid == cartid && user.guid == agent) {
      var _log = [...(state.orders.approving.log || [])];
      _log.push(data);

      dispatch({ type: ORDERS_UPDATE_APPROVING, data: { ...state.orders.approving, log: _log } });
    }
  });

  backoffice.bind("new-todos", (data) => {
    var state = getState();
    var { user, list } = state.todo;

    if (data.todo && data.todo.list) {
      var gap = data.todo.list;
      var overlap = gap
        .filter(t =>
          //for me
          t.assignedto
          && t.assignedto.toLowerCase() == user.guid.toLowerCase()
          //and same
          && list.some(l => l.id == t.id)
        );
      gap = gap
        .filter(t =>
          //for me
          t.assignedto
          && t.assignedto.toLowerCase() == user.guid.toLowerCase()
          //and new
          && !list.some(l => l.id == t.id)
      );
      list = list.map(l => {
        var ol = overlap.find(o => o.id == l.id);
        return ol ? ol : l;
      });
      list = [].concat(list, gap);

      dispatch({
        type: ACTN_REFRESH,
        data: {
          todo: {
            list: list
          }
        }
      })
      if (gap.length) {
        var unprocessed = list.filter(l => !l.processed).length;
        var notification = new Notification(`${user.displayname} inbox, ${gap.length} new ${gap.length > 1 ? "items" : "item"}!`, { body: `You have ${gap.length} new items and a total ${unprocessed} items waiting in your inbox.` });
      }
    }
  });

  backoffice.bind(PUSHER_ORDER_EDIT_STOP, (data) => {
  });

  backoffice.bind(PUSHER_ORDER_EDIT_START, (data) => {
  });

  backoffice.bind(PUSHER_MISSINGANAG_PING, (data) => {
    dispatch(missinganag(data));
  });

  dispatch({
    type: ACTN_REFRESH,
    data: {
      pusher: {
        pusher: pusher,
        presence: presence,
        backoffice: backoffice
      },
      twilio: {
        device: device,
        ringtone: ringtone
      },
    }
  })
}

var OnlyOnce = {};
const missinganag = (data) => (dispatch, getState) =>{
  const state = getState();
  var { agents } = state.todo;
  //const { distributors } = (state.tires.missinganag && state.tires.missinganag.distributors) ? state.tires.missinganag : state.orders;
  const { distributors } = state.tires.database;
  const { distributorid, userid } = data;
  if (!agents && !OnlyOnce.todo) {
    invalidate("todo");
    OnlyOnce.todo = true;
    return;
  }
  const _u = agents.find(u => u.userid == userid);

  if (distributorid && distributors) {
    const _d = distributors.find(d => d.distributorid == distributorid);
    if (!_d && !OnlyOnce.tires) {
      invalidate("tires");
      OnlyOnce.tires = true;
      return;
    }
    const data = {
      missinganag: {
        distributors: distributors.map(d => {
          var mapped = { ...d };

          if (d.distributorid == _d.distributorid) {
            mapped = { ...mapped, user: _u, nowis: Date.now() };
          } else if (d.user && d.user.userid == _u.userid) {
            mapped = { ...mapped, user: undefined, nowis: undefined };
          }
          return mapped;
        })
      }
    };

    dispatch({
      type: ACTN_REFRESH,
      data: data
    })
  }
}

export const pusherTrigger = (data) => (dispatch, getState) => {
  var state = getState();
  var { user } = state.todo;

  const eventData = { ...data.eventData, userid: user.guid };
  state.pusher.backoffice.trigger(data.eventName, eventData);
  switch (data.eventName) {
    case PUSHER_MISSINGANAG_PING:
      dispatch(missinganag(eventData));
      break;
  }

  dispatch({
    type: PUSHER_CLIENT_EVENT,
    data: data
  })
}