import HttpRequest from '../../utils/HttpRequest';

import { isProcessing } from "./actProcessing";

import { ACTN_REFRESH, ACTN_REFRESH_SINGLE_AFTER_UPDATE, TODO_UPDATE_SEARCHBOX } from "../config/action-types.js";

//function _updateChipsSearchBox(chips) {
//  return dispatch => {
//    dispatch({
//      type: "ACTN_UPDATE_CHIPS",
//      chips: chips
//    });
//  }
//}

//export const updateChipsSearchBox = (data) => (dispatch, getState) => {
//  return dispatch(_updateChipsSearchBox(data))
//}

const _updateSearchBox = (data) => {
  return dispatch => {
    dispatch({
      type: TODO_UPDATE_SEARCHBOX,
      data: data
    });
  }
}

export const updateSearchBox = (data) => (dispatch, getState) => {
  return dispatch(_updateSearchBox(data))
}

//export const updateResults = (data) => (dispatch, getState) => {
//  return dispatch({
//    type: ACTN_REFRESH,
//    data: data
//  })
//}

const _doSomething = (url, payload, getState) => {
  return dispatch => {

    var state = getState();

    //var filters = state.tires.chips.reduce((a, cv) => {
    //  return {
    //    ...a,
    //    //if a[cv.key] already exists makes it an array, for multivalue filters like distributors
    //    [cv.key]: cv.multi ?
    //      a[cv.key] ?
    //        a[cv.key].concat(cv.value || cv.text)
    //        : [cv.value || cv.text]
    //      : cv.value || cv.text
    //  }
    //}, {});

    var filters =
    {
      ...filters,
      ...state.todo.searchBox
    };

    var headers = [];
    if (state.pusher && state.pusher.socket_id) {
      headers.push({ header: "pusher-socket_id", value: state.pusher.socket_id });
    }

    payload = payload || {};
    payload = {
      ...payload,
      filters: { ...payload.filters, ...filters }
    };
    //var url = "/bo/tires/do-something";

    dispatch(isProcessing({
      key: url,
      isProcessing: true
    }));

    var xhr = new HttpRequest("POST", url, "application/json", headers);
    return xhr.send(payload).then((response) => {
      var json = response.json;
      if (json.message || json.exception) {
        alert(json.message || json.exception);
      } else {
      }
      dispatch({
        type: ACTN_REFRESH,
        data: json
      });

      dispatch(isProcessing({
        key: url,
        isProcessing: false
      }));
    }).catch((e) => {
      dispatch(isProcessing({
        key: url,
        isProcessing: false
      }));
    });
  }
}

export const doSomething = (url,payload) => (dispatch, getState) => {
  return dispatch(_doSomething(url, payload, getState))
}
