export const ACTN_FETCH = 'FETCH';
export const ACTN_FETCH_ALL = 'FETCH_ALL';
export const ACTN_UPDATE = 'FETCH_UPDATE';
export const ACTN_RESET = 'RESET';
export const ACTN_ERROR = 'ERROR';

export const ACTN_UPDATE_INTERNAL = 'ACTN_UPDATE_INTERNAL';

export const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

import HttpRequest from '../../gcomponents/utils/HttpRequest'

/**
 * Fetch file. Switch between drafted and unsaved version and remote versions
 */

export function actionFetch(endpoint, domain, fileName, files, forceRemote) {
  let fetchEndpoint = endpoint + fileName + "?domain=" + domain;
  let action = new HttpRequest(
    "GET",
    fetchEndpoint,
    "application/json"
  );

  return dispatch => {

    if(!forceRemote){
      //is there any drafted version for the wanted file?
      let file = files.filter((f) => f.name === domain + "_" + fileName && f.draft);
      if(file.length > 0){
        file = file[0];
        return delay(100).then(() => {
          dispatch({
            type: ACTN_UPDATE_INTERNAL,
            payload: {
              name: domain + "_" + fileName,
              opened: true,
              content: { ...file.content }, //use drafted version
              schema: { ...file.schema }
            }
          });
        })
      }
    }
    
    //if there is no remote forcing or no draft versions are found
    //for the wanted file, then download remote server version
    return action.send({})//fetch(fetchEndpoint)
      //.then(res => res.json())
      .then(data => {
        let resp = data.json;
        let content = resp.json;
        let schema = resp.schema;
        delete schema.$id;

        if (resp.error) {
          dispatch({
            type: ACTN_ERROR,
            payload: {
              message: resp.message
            }
          });
        } else {
          fetchHelper(dispatch, domain, content, schema, fileName);
        }
      });
  };
};

export function actionUpdateFile(endpoint, domain, fileName, fileContent, fileSchema){
  let body = { json: fileContent, schema: fileSchema };
  let endpointUrl = endpoint + fileName + "?domain=" + domain;
  let action = new HttpRequest(
    "PUT",
    endpointUrl,
    "application/json"
  );

  return dispatch => {
    return action.send(body)/*fetch(endpointUrl, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => res.json())*/
      .then(data => {
        let resp = data.json;
        let content = resp.json;
        let schema = resp.schema;
        delete schema.$id;
        
        if (resp.error) {
          dispatch({
            type: ACTN_ERROR,
            payload: {
              message: resp.message
            }
          });
        } else {
          //update action will dispatch fetch type to reucer in order to
          //remove draft flag and update current file version
          //as server has given
          fetchHelper(dispatch, domain, content, schema, fileName);
        }
      })
  }
}

export function actionReset(endpoint, domain, fileName) {
  let fetchEndpoint = endpoint + fileName + "?domain=" + domain;

  let action = new HttpRequest(
    "GET",
    fetchEndpoint,
    "application/json"
  );

  return dispatch => {
    //if there is no remote forcing or no draft versions are found
    //for the wanted file, then download remote server version
    return action.send({})/*fetch(fetchEndpoint)
      .then(res => res.json())*/
      .then(data => {
        let resp = data.json;
        let content = resp.json;
        let schema = resp.schema;
        delete schema.$id;

        if (resp.error) {
          dispatch({
            type: ACTN_ERROR,
            payload: {
              message: resp.message
            }
          });
        } else {
          dispatch({
            type: ACTN_RESET,
            payload: {
              name: domain + "_" + fileName,
              opened: true,
              content: { ...content },
              schema: { ...schema }
            }
          });
        }
      });
  };
};

function fetchHelper(dispatch, domain, content, schema, fileName){
  dispatch({
    type: ACTN_FETCH,
    payload: {
      name: domain + "_" + fileName,
      opened: true,
      content: { ...content },
      schema: { ...schema }
    }
  });
}

export function actionUpdateDraft(file, domain, draft){
  return dispatch => {
    return delay(100).then(() => {
      
      dispatch({
        type: ACTN_UPDATE_INTERNAL,
        payload: {
          name: file.name,
          opened: true,
          content: { ...draft },
          schema: { ...file.schema }
        }
      });
    })
  }
}
