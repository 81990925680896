/**
 * @author Marco Distrutti
 */

import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { theme } from './theme/theme';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

import Container from './subs/Container';

//Internal context state management
import configureStore from './store/configureStore';
const jreStore = configureStore();
jreStore.subscribe(()=>{
  console.log(jreStore.getState());
});

class JsonEditor extends React.Component{

  componentDidMount(){
    /*something*/
  }

  render() {
    return (
      <Provider store={jreStore}>
        <MuiThemeProvider theme={theme}>
          <Paper square style={{ height: '100%', display: this.props.files.length === 0 ? 'none' : '' }}>
            <Container fileNames={this.props.files} endpoint={this.props.endpoint} domain={this.props.domain} />
          </Paper>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

JsonEditor.propTypes = {
  files: PropTypes.array.isRequired,
  endpoint: PropTypes.string.isRequired
};

export default JsonEditor;
