
import {
  ACTN_REFRESH,
  ACTN_REFRESH_DATABASE,
  TODO_UPDATE_SEARCHBOX
} from '../config/action-types.js';


const initialPusherState = {
  searchBox: {
    iDisplayLength: 100,
    sSearch: "",
    active_taskid: ""
  }
};

export default function (state = initialPusherState, action) {
  switch (action.type) {

    case ACTN_REFRESH:
      //if (action.data && action.data.pusher) {
      //  return {
      //    ...state,
      //    ...action.data.pusher
      //  }
      //}
      var _state = { ...state };
      ["agents", "taskids"].forEach(p => {
        if (action.data && action.data[p]) {
          _state = {
            ..._state, [p]: { ...action.data[p] }
          }
        }
      });
      ["user"].forEach(p => {
        if (action.data && action.data[p]) {
          if ((!!action.data[p]) && (action.data[p].constructor === Array)) {
            _state = {
              ..._state, [p]: action.data[p]
            }
          } else {
            _state = {
              ..._state, [p]: { ..._state[p], ...action.data[p] }
            }
          }
        }
      });
      ["todo"].forEach(p => {
        if (action.data && action.data[p]) {
          _state = {
            ..._state, ...action.data[p]
          }
        }
      });
      return _state;

    case ACTN_REFRESH_DATABASE:
      if (action.todo) {
        const taskids = (action.todo && action.todo.taskids) ? action.todo.taskids : [];
        const agents = (action.todo && action.todo.agents) ? action.todo.agents : [];
        return {
          ...state,
          taskids: taskids,
          agents: agents,
          searchBox: { ...state.searchBox, active_taskid: taskids[0].taskid },
          nowis: Date.now()
        }
      } else {
        return state;
      }
      
    case TODO_UPDATE_SEARCHBOX:
      return {
        ...state,
        searchBox: { ...state.searchBox, ...action.data }
      }
      break;

    default:
      return state;
  }
}