import thunk from 'redux-thunk';
import { createStore, combineReducers , applyMiddleware, compose  } from 'redux';

import todoReducer from '../reducers/redTodo';
import drawerReducer from '../reducers/redDrawer';
import { allTiresReducer, removeFromLocalStorage as tiresRemove } from '../reducers/redAllTires';
import allWheelsReducer from '../reducers/redAllWheels';
import sharedReducer from '../reducers/redSharedVolatile';
import { ordersReducer, removeFromLocalStorage as ordersRemove } from '../reducers/redOrders';
import pusherReducer from '../reducers/redPusher';
import processingReducer from '../reducers/redProcessing';
import minicartReducer from '../reducers/redMiniCart';
import { initPusher } from "../actions/actPusher.js";
import { refreshIfStale, cleanUpStaleStuff } from './refreshIfStale'
import { debounce } from '../../utils/Debounce';

import {
  ACTN_REFRESH
} from '../config/action-types.js';

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

import { initialProcessingState } from "../reducers/redProcessing-initial.js";
const _initialProcessingState = JSON.parse(JSON.stringify(initialProcessingState));

var updateLocalStorage = debounce((store) => {
  //this is a one time trigger from actPusher
  if (window.noLocalStorageUpdate) {
    window.noLocalStorageUpdate = false;
    return;
  }

  const state = store.getState();
  //console.log("updateLocalStorage", state.tires);
  //this is not the best way
  var _state = {
    ...state,
    shared: undefined,
    tires: tiresRemove(state.tires),
    orders: ordersRemove(state.orders),
    isProcessing: { ..._initialProcessingState },
    drawer: undefined,
    pusher: undefined
  };

  localStorage.setItem('reduxState', JSON.stringify(_state))
}, 100);

export default () => {
  //fetch initial state from local storage
  let persistedState = localStorage.getItem('reduxState') ? JSON.parse(localStorage.getItem('reduxState')) : {};
  persistedState = cleanUpStaleStuff(persistedState);
  //if (isStale(persistedState)) {
  //  persistedState = {};
  //}

  //init store
  const store = createStore(
    combineReducers({
      shared: sharedReducer,
      tires: allTiresReducer,
      wheels: allWheelsReducer,
      orders: ordersReducer,
      isProcessing: processingReducer,
      minicart: minicartReducer,
      todo: todoReducer,
      drawer: drawerReducer,
      pusher: pusherReducer
    }),
    persistedState,
    applyMiddleware(thunk)
    //composeEnhancers(applyMiddleware(thunk))
  );

  //update localstorage everytime some changes occurs in redux store
  store.subscribe(() => {
    updateLocalStorage(store);
  })

  if (window.state) {
    store
      .dispatch({
        type: ACTN_REFRESH,
        data: { ...window.state }
      });
    if (window.state.user) {
      store
        .dispatch(initPusher());
      store
        .dispatch(refreshIfStale());
    } else if (window.state.distributor) {
      store
        .dispatch(refreshIfStale());
    }
  }

  return store;
};

