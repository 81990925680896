
import { ACTN_FETCH, ACTN_RESET, ACTN_UPDATE_INTERNAL, ACTN_ERROR } from './actions'

const initialState = {
  files: [],
  error: ""
}

export default function (state = initialState, action) {
  let files = JSON.parse(JSON.stringify(state.files));
  switch (action.type) {
    case ACTN_ERROR:
      return {
        ...state,
        error: action.payload.message
      }
    case ACTN_FETCH:
    case ACTN_UPDATE_INTERNAL:
      let file = files.filter((f) => f.name === action.payload.name);
      if(file.length === 0)
        files.push(action.payload); //insert new file in cache

      files = files.map((file) => {
        file.version = file.version ? file.version : 0;
        file.key = file.name + "v" + file.version;
        if (file.name === action.payload.name) {
          file.opened = true; //open fetched file
          file.content = action.payload.content;
          file.draft = false; //by default fetched file are not considered draft
          if(action.type === ACTN_UPDATE_INTERNAL){
            file.draft = true; //internal updates simulate a fetch action and cause draft status change
          }
        }else{
          file.opened = false; //for every file not fetched I force close status
        }
        return file;
      });

      return {
        ...state,
        files: files,
        error: ""
      }
    case ACTN_RESET:
      files.map((file) => {
        if(file.name === action.payload.name){
          file.version++;
          file.draft = false;
          file.key = file.name + "v" + file.version;//force react rerendering
          file.content = action.payload.content//restore 
        }
        return file;
      });

      return {
        ...state,
        files: files,
        error: ""
      }
    default:
      return state;
  }
}
