import React, { useState, useEffect } from 'react';
import {
  ResponsiveContainer, BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

function HistoryRedis(props) {
  // Declare a new state variable, which we'll call "count"

  const crunchData = (data) => {
    if (data) {
      return data
        .sort((a, b) => moment(a.createdon).isBefore(b.createdon) ? -1 : 1)
        .map(d => {
          return {
            ...d,
            name: moment(d.createdon).format("HH:mm")//moment(d.createdon).diff(moment(), "minutes") + "min"
          }
        });
    } else {
      return undefined;
    }
  }

  const [_data, setData] = useState(crunchData(props.data));

  useEffect(() => {
    setData(crunchData(props.data));
  }, [props.data]);

  return (
    <div>
      <div className="row">
        <div className="col">
          <div style={{ fontFamily: "Roboto Condensed", textAlign: "center", fontSize: "1.5rem" }}>Disposable cache (Redis) Memory</div>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart
              data={_data}
              syncId="redis"
              margin={{
                top: 5, right: 0, left: 0, bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name"
                tick={{ fontFamily: "Roboto Condensed", fontSize: ".75rem" }} />
              <YAxis
                tick={{ fontFamily: "Roboto Condensed", fontSize: ".75rem" }}
                tickFormatter={tick => {
                  return ((1 * tick) / (1024 * 1024 * 1024)).toFixed(2) + "GB";
                }} />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="used_memory" stroke="#FF851B" activeDot={{ r: 8 }} />
              <Line type="monotone" dataKey="used_memory_peak" stroke="#FF4136" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div style={{ fontFamily: "Roboto Condensed", textAlign: "center", fontSize: "1.5rem" }}>Disposable cache (Redis) Keys</div>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart
              data={_data}
              syncId="redis"
              margin={{
                top: 5, right: 0, left: 0, bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name"
                tick={{ fontFamily: "Roboto Condensed", fontSize: ".75rem" }} />
              <YAxis
                tick={{ fontFamily: "Roboto Condensed", fontSize: ".75rem" }}
                tickFormatter={tick => {
                  return tick / 1000 + "K";
                }} />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="db0" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}

export default HistoryRedis;

