const styles = theme => ({
  primaryText: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  errorText: {
    color: theme.palette.error.main
  }
});

export default styles;
