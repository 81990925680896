import React from 'react';

import * as marked from 'marked';
import TurndownService from 'turndown'
var turndownService = new TurndownService()

import Divider from '@material-ui/core/Divider';
import LogEntryType from "./drawer-log-filters.jsx";
import MailTemplate, { REPLY_MAIL } from "../mail-templates.js";
import { DrawerMail, prepareMail } from "./drawer-mail.jsx";
import { DrawerMemo } from "./drawer-memo.jsx";
import DrawerItemToolbar from "./drawer-item-toolbar.jsx";

export class DrawerLog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      options: [{ label: "All", value: "all" }, { label: "Mail/Memo/Call", value: "inbox" }, { label: 'Actions', value: "actions" }],
      filter: { type: "inbox" }
    };
  }

  replyMemo = (e, item, channel) => {
    const {
      order, agents, user
      , isProcessing
    } = this.props;

    if (!item) {
      this.setState({ replyingTo: { ...item } });
    } else if (channel == "mail") {
      this.setState({ replyingTo: { ...item, mail: MailTemplate(REPLY_MAIL, order, null, user, item) } });
    } else if (channel == "memo") {
      this.setState({ replyingTo: { ...item, mail: undefined } });
    } else if (channel == "todo") {
      this.setState({ replyingTo: { ...item, mail: undefined } });
    }
  }

  toggleDetails = (e, item) => {
    var { detailsOf } = this.state;
    this.setState({ detailsOf: (!detailsOf || detailsOf.id != item.id) ? item : undefined });
  }

  handleFilter = (e, v) => {
    this.setState({ filter: { type: v.value } });
  }

  prepareContent = (w, agent, user, showDetails) => {
    var icon = null;
    var details = null;

    switch (w.details.type) {
      case "mail":
        if (w.details.processed) {
          icon = <i className="far fa-2x fa-envelope"></i>;
        } else {
          icon = <i className="fas fa-2x fa-envelope"></i>;
        }

        icon = (
          <DrawerItemToolbar
            replyMemo={this.replyMemo}
            toggleDetails={this.toggleDetails}
            item={w}
            user={user}
          />
        );

        //var mailbody = null;
        //if (showDetails) {
        //  var markdown = turndownService.turndown(w.details.m_htmlbody || w.details.m_textbody);
        //  var html = marked(markdown);
        //  mailbody = (
        //    <div className="bologinbound-mail-preview">
        //      <span dangerouslySetInnerHTML={{ __html: markdown }} />
        //    </div>
        //  );
        //}
        var mailbody = null;
        if (showDetails) {
          var body = w.details.m_textbody;
          if (w.details.m_htmlbody) {
            var el = document.createElement('html');
            el.innerHTML = w.details.m_htmlbody;
            el = el.querySelector("body");
            el.querySelectorAll("*").forEach(e => e.className = "");
            el.querySelectorAll("*").forEach(e => { e.removeAttribute("href"); e.removeAttribute("style"); e.setAttribute("style", "font-size: 12px;font-family: Roboto Condensed;"); });
            el.querySelectorAll("script").forEach(e => el.removeChild(e));
            body = el.innerHTML;
          }
          mailbody = (
            <div className="bologinbound-mail-preview">
              <span dangerouslySetInnerHTML={{ __html: body }} />
            </div>
          );
        }

        details = (
          <div
            className="row mb-2">
            <div className="col">
              <div>
                <span className="badge badge-secondary">from</span> <span className="memos-mail-header">{w.details.m_from} {agent && agent.clientid}</span>
              </div>
              <div>
                <span className="badge badge-secondary">to</span> <span className="memos-mail-header">{w.details.m_to}</span>
              </div>
              <div className="memos-mail-header">{w.details.m_subject}</div>
              {mailbody}
            </div>
          </div>
        );
        break;
      case "call":
        if (w.details.processed) {
          icon = <i className="fas fa-2x fa-phone-square-alt" style={{ opacity: .5 }}></i>;
        } else {
          icon = <i className="fas fa-2x fa-phone-square-alt"></i>;
        }

        var content = <div style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{w.details.m_subject}</div>;
        if (w.details.q_time > 0 && w.details.m_subject) {
          //content = <a href={w.details.m_subject}><div style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}><i className="fas fa-play"></i> play {w.details.q_time}s</div></a>;
          content = <div style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>call time {w.details.q_time}s</div>;
          icon = (
            <DrawerItemToolbar
              item={w}
              user={user}
            />
          );
        } else {
          content = <div style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>missed/no-recording</div>;
        }

        details = (
          <div
            className="row">
            <div className="col">
              <div>
                <span className="badge badge-secondary">from</span> <span>{w.details.m_from} {agent && agent.clientid}</span>
              </div>
              <div>
                <span className="badge badge-secondary">to</span> <span>{w.details.m_to}</span>
              </div>
              {content}
            </div>
          </div>
        );
        break;
      case "memo":
        if (w.details.processed) {
          icon = <i className="far fa-2x fa-comment-alt"></i>;
        } else {
          icon = <i className="fas fa-2x fa-comment-alt"></i>;
        }

        icon = (
          <DrawerItemToolbar
            replyMemo={this.replyMemo}
            item={w}
            user={user}
          />
        );

        //<span className="badge badge-secondary">from</span> <span>{w.details.m_from} {agent && agent.clientid}</span>
        details = (
          <div
            className="row">
            <div className="col">
              <div>
                <span className="badge badge-secondary">from</span> <span>{agent && agent.clientid || w.details.m_from}</span>
              </div>
              <div>{w.details.m_textbody}</div>
            </div>
          </div>
        );
        break;
    }

    return { icon: icon, details: details };
  }

  render() {
    var ret = null;
    var { options, filter, replyingTo, detailsOf } = this.state;

    const {
      order, agents, user
      , isProcessing
    } = this.props;

    if (order && order.whathappened) {
      var skipping = [];
      var lines = [];
      order.whathappened
        .filter(w => !filter || !filter.type || filter.type == "all" || (filter.type == "inbox" && w.logTable == "bolog_inbound") || (filter.type != "inbox" && w.logTable != "bolog_inbound"))
        .forEach((w, i) => {
          if (skipping.some(s => s == w.hashkey)) {
            return;
          }

          //show email body
          const showDetails = detailsOf && (detailsOf.hashkey && detailsOf.hashkey == w.hashkey || detailsOf.id && detailsOf.id == w.id);

          var childs = order.whathappened.filter(ww => ww.hashkey && ww.hashkey != w.hashkey && w.threadid && (ww.hashkey == w.threadid || ww.threadid == w.threadid));
          if (childs.length) {
            childs.forEach(c => skipping.push(c.hashkey));
          }

          var agent = agents.find(a => w.userid && a.userid.toLowerCase() == w.userid.toLowerCase());

          var details = null;
          var icon = null;
          switch (w.logTable) {
            case "bolog_inbound":
              var content = this.prepareContent(w, agent, user, showDetails);
              icon = content.icon;
              details = content.details;
              break;

            case "bolog":
              if (agent) {
                icon = <i className="fas fa-2x fa-screwdriver"></i>;
              } else {
                icon = <i className="fas fa-2x fa-robot"></i>;
              }

              var file = null;
              var filename = null;
              switch (w.details.action) {
                case "gomzon-imported-order":
                case "NTS-export-ready":
                case "tnt-response-shipping-creation-" + w.cartid:
                case "tnt-request-shipping-creation-" + w.cartid:
                case "TNT-picking-response":
                case "TNT-send-picking":
                case "supplier-order-confirmation":
                case "supplier-shipping-confirmation":
                case "approve-order":
                case "approve-order-log":
                case "auto-approve-order-log":
                  if (w.details.isbase64 || 1==1) {
                    filename = `${w.logTable}-${w.id}-${w.details.action}.txt`;
                    file = (
                      <div className="actiontext" style={{ padding: "5px 15px" }}>
                        <a href={"/bo/orders/" + order.cartid + "/download/" + filename} target="_blank"><i className="far fa-file"></i> {filename}</a>
                      </div>
                    );
                  }
                  break;
                case "gomzon-imported-order-feeditem":
                case "camodo-import-order":
                case "edock-import-order":
                case "tyre24-import-order":
                case "attempt-to-approve-order":
                  filename = `${w.logTable}-${w.id}-${w.details.action}.json`;
                  file = (
                    <div className="actiontext" style={{ padding: "5px 15px" }}>
                      <a href={"data:text/plain;charset=utf-8," + encodeURIComponent(JSON.stringify(w.details.json))} download={filename} target="_blank"><i className="far fa-file"></i> {filename}</a>
                    </div>
                  );
                  break;
              }

              details = (
                <div
                  className="row bolog">
                  <div className="col">
                    {agent &&
                      <div>
                        <span className="badge badge-light">from</span> <span className="memos-mail-header">{agent && (agent.clientid || agent.displayname)}</span>
                      </div>
                    }
                    {!agent &&
                      <div>
                        <i className="far fa-question-circle"></i><i className="far fa-question-circle"></i><i className="far fa-question-circle"></i>
                      </div>
                    }
                    <div className="actiontext">
                      <span>{w.details.action}</span> {w.details.action != w.details.actiontext && <span>{w.details.actiontext}</span>}
                    </div>
                    {file}
                  </div>
                </div>
              );
              break;

            case "cartOrderTwilio":
              icon = <i className="fas fa-2x fa-robot"></i>;
              details = (
                <div
                  className="row">
                  <div className="col">
                    <div>
                      <span>{w.details.action}</span> <span>{w.details.outcome}</span>
                    </div>
                  </div>
                </div>
              );
              break;
          }

          lines.push(
            <Divider key={"divider-" + i} />
          );

          var composeMemo = null;
          if (replyingTo && replyingTo.id && replyingTo.id == w.id) {
            if (replyingTo.mail) {
              composeMemo = (
                <DrawerMail
                  processActions={this.props.processActions}
                  handleCancel={this.replyMemo}
                  isProcessing={this.props.isProcessing}
                  order={order}
                  mail={replyingTo.mail}
                  agents={agents}
                  replyingTo={replyingTo}
                />
              );
            } else {
              composeMemo = (
                <DrawerMemo
                  processActions={this.props.processActions}
                  isProcessing={this.props.isProcessing}
                  agents={agents}
                  order={this.props.order}
                  options={this.props.options}
                  replyMemo={this.replyMemo}
                  replyingTo={replyingTo}
                />
              );
            }
          }

          lines.push(
            <div
              key={"memos-logs-title-" + (w.hashkey || i)}
              className="row mt-2">
              <div className="col">
                <div className="memo-id">
                  {w.id} {w.logTable}
                </div>
                <div className="memo-date">
                  {moment(w.createdon).format("ddd D MMM YYYY HH:mm:ss")}
                </div>
              </div>
              <div className="col-auto">
                {icon}
              </div>
            </div>
          );

          lines.push(
            <div
              key={"memos-logs-details" + (w.hashkey || i)}
            >
              <div
                className="row">
                <div className="col">
                  <div>
                    {details}
                  </div>
                </div>
              </div>
              {composeMemo}
            </div>
          );

          if (childs.length) {
            childs.forEach(c => {
              var _agent = agents.find(a => c.userid && a.userid.toLowerCase() == c.userid.toLowerCase());
              var _content = this.prepareContent(c, _agent, user, showDetails);
              var _details = _content.details;
              lines.push(
                <div
                  key={"memos-logs-details-childs" + c.hashkey}
                  className="row mt-1 mb-2">
                  <div className="col-1 pr-0">
                    <div className="pr-0" style={{ borderRight: "5px solid #ddd", width: "100%", height: "80%" }}>&#160;</div>
                  </div>
                  <div className="col" style={{ fontSize: "80%" }}>
                    <div className="memo-date">
                      {moment(c.createdon).format("ddd D MMM YYYY HH:mm:ss")}
                    </div>
                    <div>
                      {_details}
                    </div>
                  </div>
                </div>
              );
            });
          }

        });

      var loading = null;
      if (isProcessing) {
        loading = <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', backgroundColor: 'rgba(255,255,255,.5)', width: '100%', padding: '20px', fontSize: '2rem', textAlign: 'center' }}>Loading...</div>;
      }

      ret = (
        <div style={{ position: "relative" }}>
          {!(replyingTo) &&
            <DrawerMemo
              processActions={this.props.processActions}
              isProcessing={this.props.isProcessing}
              agents={this.props.agents}
              order={this.props.order}
              options={this.props.options}
              mail={this.props.mail}
            />
          }
          <div className="container memos-logs">
            <div className="row pt-2 pb-2" style={{ borderTop: "1px solid #ddd" }}>
              <LogEntryType
                options={options}
                filter={filter}
                handleFilter={this.handleFilter}
              />
            </div>
            {lines}
          </div>
          {loading}
        </div>
      );
    }

    return ret;
  }
}

