import React from 'react';
import Slide from '@material-ui/core/Slide';
import Divider from '@material-ui/core/Divider';
import { DrawerMail } from "./drawer-mail.jsx";
import { DrawerMemo } from "./drawer-memo.jsx";
import DrawerItemToolbar from "./drawer-item-toolbar.jsx";
import { Folder } from "./drawer-inbox.jsx";
import { connect } from 'react-redux';
import { updateDrawer } from "../../../gcomponents/store/actions/actDrawer";

class DrawerInboxList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      replyingTo: undefined,
      replyingChannel: undefined
    };

    this.refs = {};
  }

  replyMemo = (e, item) => {
    if (item.details && item.details.type == "mail") {
      this.setState({ replyingTo: item });
    } else {
      this.setState({ replyingTo: item });
    }
  }

  prepareDetails = (item,agent) => {
    return (
      <div
        className="row">
        <div className="col">
          <div>
            <span className="badge badge-secondary">from</span> <span className="memos-mail-header">{agent && agent.clientid || <span><i className="far fa-question-circle"></i><i className="far fa-question-circle"></i><i className="far fa-question-circle"></i></span>}</span>
          </div>
          <div>
            <span className="badge badge-secondary">cart</span> <a className="memos-mail-header" href={"/bo/orders?cartid=" + item.idtosomething}>{item.idtosomething}</a>
          </div>
          <div className="memos-mail-header">{item.longtext || item.taskDescription}</div>
        </div>
      </div>
    );
  }

  applySmartFilter = (t, memos, folders, user) => {
    var ret = true;

    var folder = folders.find(f => f.selected);
    if (t.deleted) {
      ret = false;
    }
    if (folder.value == Folder.Inbox) {
      if (t.threadid && memos.some(ww => ww.hashkey != t.hashkey && ww.hashkey == t.threadid || ww.threadid == t.threadid)) {
        //is a child
        ret = false;
      }
      if (t.processed) {
        ret = false;
      }
    }
    if (folder.value == Folder.Processed && !t.processed) {
      ret = false;
    }
    if (folder.value == Folder.Sent) {
      if (!(t.createdby == user.guid)) {
        ret = false;
      }
    }
    return ret;
  }

  childs = (memos, w, folders) => {
    var ret = [];

    var folder = folders.find(f => f.selected);
    if (folder.value == Folder.Inbox) {
      ret = memos.filter(ww => ww.hashkey != w.hashkey && ww.threadid && (w.hashkey == ww.threadid || w.threadid == ww.threadid));
    }
    if (folder.value == Folder.Processed && !w.processed) {
    }
    if (folder.value == Folder.Sent) {
      ret = memos.filter(ww => ww.hashkey != w.hashkey && ww.hashkey == w.threadid || ww.threadid == w.threadid);
    }
    return ret;
  }

  flagMemoAsRead = (e, hashkey) => {
    var el = this.refs[hashkey];
    const { memos, updateDrawer } = this.props;

    window.setTimeout(() => {
      el.style.animation = "0.75s "
        + "zoomOutRight"
        + " ease-in forwards";
      window.setTimeout(() => {
        updateDrawer({
          memos: memos.map(m => m.hashkey != hashkey ? m : { ...m, processed: true })
        });
        el.style.animation = "none";
        this.props.flagMemoAsRead(e, hashkey);
      }, 1000);
    }, 0);
  }

  trashMemo = (e, hashkey) => {
    var el = this.refs[hashkey];
    const { memos, updateDrawer } = this.props;

    window.setTimeout(() => {
      el.style.animation = "0.75s "
        + "zoomOutRight"
        + " ease-in forwards";
      window.setTimeout(() => {
        updateDrawer({
          memos: memos.map(m => m.hashkey != hashkey ? m : { ...m, deleted: true })
        });
        el.style.animation = "none";
        this.props.trashMemo(e, hashkey);
      }, 1000);
    }, 0);
  }

  render() {
    var ret = null;

    var { replyingTo, replyingChannel } = this.state;
    const { memos, agents, folders, user } = this.props;

    if (memos && memos.length) {
      var lines = [];
      memos
        .filter(t => this.applySmartFilter(t, memos, folders, user))
        .sort((a, b) => {
          var dateA = new Date(a.createdon);
          var dateB = new Date(b.createdon);
          return dateB - dateA;
      }).forEach((w, i) => {
        var agent = agents.find(a => w.createdby && a.userid.toLowerCase() == w.createdby.toLowerCase());

        var details = null;
        var icon = null;

        switch (w.taskid) {
          case "notification-order":
            details = this.prepareDetails(w, agent);
            //details = (
            //  <div
            //    className="row">
            //    <div className="col">
            //      <div>
            //        <span className="badge badge-secondary">from</span> <span className="memos-mail-header">{agent && agent.clientid || <span><i className="far fa-question-circle"></i><i className="far fa-question-circle"></i><i className="far fa-question-circle"></i></span>}</span>
            //      </div>
            //      <div>
            //        <span className="badge badge-secondary">cart</span> <a className="memos-mail-header" href={"/bo/orders?cartid=" + w.idtosomething}>{w.idtosomething}</a>
            //      </div>
            //      <div className="memos-mail-header">{w.longtext || w.taskDescription}</div>
            //    </div>
            //  </div>
            //);
            break;

          default:
            details = (
              <div
                className="row">
                <div className="col">
                  <div>
                    <span className="badge badge-secondary">from</span> <span className="memos-mail-header">{agent && agent.clientid || <span><i className="far fa-question-circle"></i><i className="far fa-question-circle"></i><i className="far fa-question-circle"></i></span>}</span>
                  </div>
                  <div className="memos-mail-header">{w.longtext || w.taskDescription}</div>
                </div>
              </div>
            );
            break;
        }

        lines.push(
          <Divider key={"divider-" + i} />
        );

        var composeMemo = null;
        if (replyingTo && replyingTo.id && replyingTo.id == w.id) {
          if (replyingTo.details && replyingTo.details.type == "mail") {
            composeMemo = (
              <DrawerMemo
                processActions={this.props.processActions}
                isProcessing={this.props.isProcessing}
                agents={agents}
                replyMemo={this.replyMemo}
                replyingTo={replyingTo}
              />
            );
          } else {
            composeMemo = (
              <DrawerMemo
                processActions={this.props.processActions}
                isProcessing={this.props.isProcessing}
                agents={agents}
                replyMemo={this.replyMemo}
                replyingTo={replyingTo}
              />
            );
          }
        }

        lines.push(
          <div
            key={"memos-logs-title-and-details" + w.hashkey}
            className="mt-2 mb-2"
            ref={element => this.refs = { ...this.refs, [w.hashkey]: element }}
          >
            <div
              className="row">
              <div className="col">
                <div className="memo-id">
                  {w.id} {w.taskid} {w.domain}
                </div>
                <div className="memo-date">
                  {moment(w.createdon).format("ddd D MMM YYYY HH:mm:ss")}
                </div>
              </div>
              <div className="col-auto memo-toolbar">
                <DrawerItemToolbar
                  trashMemo={this.trashMemo}
                  replyMemo={this.replyMemo}
                  flagMemoAsRead={this.flagMemoAsRead}
                  item={w}
                  user={user}
                />
              </div>
            </div>
            <div
              className="row">
              <div className="col">
                <div>
                  {details}
                </div>
              </div>
            </div>
            {composeMemo}
          </div>
        );

        var childs = this.childs(memos, w, folders); 
        if (childs.length) {
          childs.forEach(c => {
            var _agent = agents.find(a => c.createdby && a.userid.toLowerCase() == c.createdby.toLowerCase());
            var _details = this.prepareDetails(c, _agent);
            lines.push(
              <div
                key={"memos-logs-details-childs" + w.hashkey}
                className="row mt-1 mb-2">
                <div className="col-1 pr-0">
                  <div className="pr-0" style={{ borderRight: "5px solid #ddd", width: "100%", height: "80%" }}>&#160;</div>
                </div>
                <div className="col" style={{ fontSize: "80%" }}>
                  <div className="memo-date">
                    {moment(c.createdon).format("ddd D MMM YYYY HH:mm:ss")}
                  </div>
                  <div>
                    {_details}
                  </div>
                </div>
              </div>
            );
          });
        }
      });


      ret = (
        <div>
          {!(replyingTo) &&
            <DrawerMemo
              processActions={this.props.processActions}
              isProcessing={this.props.isProcessing}
              agents={this.props.agents}
            />
          }
          <div className="container memos-logs">
            {lines}
          </div>
        </div>
      );
    }

    return ret;
  }
}

const mapStateToProps = state => {
  return {
    isProcessing: state.isProcessing.mostRecentStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDrawer: (data) => dispatch(updateDrawer(data)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(DrawerInboxList);

