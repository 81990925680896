
import {
  ACTN_REFRESH,
  ACTN_UPDATE_SOMETHING,
  ACTN_REFRESH_SINGLE_AFTER_UPDATE,
  ACTN_REFRESH_DATABASE,
  ACTN_UPDATE_CHIPS, ACTN_UPDATE_SEARCHBOX

} from '../config/action-types.js';


const initialState = function () {
  let state = {
    results: [],
    ktypes: [],

    iAm: "wheels"
  };

  return state;
}()



export default function (state = initialState, action) {
  switch (action.type) {

    case ACTN_REFRESH_DATABASE:
      var _state = { ...state };
      ["wheels"].forEach(q => {
        if (action[q]) {
          ["cars_database", "wheels_database"].forEach(p => {
            if (action[q][p]) {
              _state = {
                ..._state,
                [p]: action[q][p],
              };
            }
          });
        }
      });
      return {
        ..._state,
        nowis: Date.now()
      }


    case ACTN_REFRESH:
      var _state = { ...state };
      ["wheels"].forEach(p => {
        if (action.data && action.data[p]) {
          ["ktypes", "results","brands"].forEach(r => {
            if (action.data[p][r] && Array.isArray(action.data[p][r])) {
              _state = {
                ..._state, [r]: action.data[p][r]
              }
            }
          });
        }
      });
      return _state;

    case ACTN_UPDATE_SOMETHING:
      var _state = { ...state };
      if (action.data.whoIsThisDataFor == "wheels") {
        ["filters"].forEach(r => {
          if (action.data[r]) {
            _state = {
              ..._state, [r]: action.data[r]
            }
          }
        });
      }
      return _state;

    case ACTN_UPDATE_SEARCHBOX:
      return {
        ...state,
        searchBox: { ...state.searchBox, ...action.data}
      }

    case ACTN_UPDATE_CHIPS:
      return {
        ...state,
        chips: action.chips
      }

    default:
      return state;
  }
}