import HttpRequest from '../../utils/HttpRequest';

import { isProcessing } from "../actions/actProcessing";

import {
  ACTN_REFRESH,
  ORDERS_REFRESH_DATABASE,
  ORDERS_UPDATE_CHIPS,
  ORDERS_UPDATE_SEARCHBOX,
  ORDERS_UPDATE_MODAL,
  ORDERS_REFRESH_RESULTS,
  ORDERS_REFRESH_SPECIFIC,
  ORDERS_REFRESH_ONE,
  ORDER_SHIPPING_CLOSEWORKINGDAY,
} from '../config/action-types.js';

function _updateChipsSearchBox(chips) {
  return dispatch => {
    dispatch({
      type: "ORDERS_UPDATE_CHIPS",
      chips: chips
    });
  }
}

export const updateChipsSearchBox = (data) => (dispatch, getState) => {
  return dispatch(_updateChipsSearchBox(data))
}

const _updateSearchBox = (data) => {
  return dispatch => {
    dispatch({
      type: ORDERS_UPDATE_SEARCHBOX,
      data: data
    });
  }
}

export const updateSearchBox = (data) => (dispatch, getState) => {
  return dispatch(_updateSearchBox(data))
}

const _updateModal = (data) => {
  return dispatch => {
    dispatch({
      type: ORDERS_UPDATE_MODAL,
      data: data
    });
  }
}

export const updateModal = (data) => (dispatch, getState) => {
  return dispatch(_updateModal(data))
}

const _updateSearchCloseWorkingDay = (data) => {
  return dispatch => {
    dispatch({
      type: ORDER_SHIPPING_CLOSEWORKINGDAY,
      data: data
    });
  }
}

export const updateSearchCloseWorkingDay = (data) => (dispatch, getState) => {
  return dispatch(_updateSearchBox(data))
}

const _doSomething = (url, payload, options, getState) => {
  return dispatch => {

    var state = getState();

    var filters = state.orders.chips.reduce((a, cv) => {
      return {
        ...a,
        //if a[cv.key] already exists makes it an array, for multivalue filters like distributors
        [cv.key]: cv.multi ?
          a[cv.key] ?
            a[cv.key].concat(cv.value || cv.text)
            : [cv.value || cv.text]
          : cv.value || cv.text
      }
    }, {});

    var filters =
    {
      ...filters,
      ...state.orders.searchBox
    };

    var headers = [];
    if (state.pusher && state.pusher.socket_id) {
      headers.push({ header: "pusher-socket_id", value: state.pusher.socket_id });
    }

    payload = {
      ...payload,
      filters: { ...payload.filters, ...filters }
    };

    //var url = "/bo/tires/do-something";

    if (options && typeof options.type_class !== "undefined") {
      delete payload.filters;
    }

    dispatch(isProcessing({
      key: url,
      isProcessing: true
    }));

    var xhr = new HttpRequest("POST", url, "application/json", headers);
    return xhr.send(payload).then((response) => {
      var json = response.json;
      if (json.message || json.exception) {
        alert(json.message || json.exception);
      } else {
      }

      if (options && options.refreshOne) {
        dispatch({
          type: ORDERS_REFRESH_ONE,
          data: json,
          options: options
        });
      }else if (options && typeof options.type_class !== "undefined") {
        dispatch({
          type: options.type_class,
          data: json
        });
      } else {
        dispatch({
          type: ACTN_REFRESH,
          data: json
        });
      }
      if (options && options.refreshModal) {
        dispatch({
          type: ORDERS_UPDATE_MODAL,
          data: {
            data: { ...json.data },
            action: json.action
          }
        });
      }
      //dispatch({
      //  type: ORDERS_REFRESH_RESULTS,
      //  results: json.results
      //});
      dispatch(isProcessing({
        key: url,
        isProcessing: false
      }));

      return json;
    }).catch((e) => {
      dispatch(isProcessing({
        key: url,
        isProcessing: false
      }));
    });
  }
}

export const doSomething = (url,payload, options) => (dispatch, getState) => {
  return dispatch(_doSomething(url.toLowerCase(), payload, options, getState))
}
