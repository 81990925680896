import HttpRequest from '../../utils/HttpRequest';

import { isProcessing } from "../actions/actProcessing";

import { ACTN_REFRESH, ACTN_REFRESH_ONE, ACTN_UPDATE_SOMETHING } from "../config/action-types.js";

const _doSomething = (url, payload, method, options, getState) => {
  return dispatch => {

    var state = getState();

    var headers = [];
    if (state.pusher && state.pusher.socket_id) {
      headers.push({ header: "pusher-socket_id", value: state.pusher.socket_id });
    }

    dispatch(isProcessing({
      key: url,
      isProcessing: true
    }));

    var contentType = "application/json";
    if (payload instanceof FormData) {
      contentType = undefined;
      //contentType = "multipart/form-data";
    }

    method = method == "GET" ? method : method == "PUT" ? method : "POST";

    var xhr = new HttpRequest(method, url.toLowerCase(), contentType, headers);
    return xhr.send(payload).then((response) => {
      var json = response.json;
      if (json.message || json.exception) {
        alert(json.message || json.exception);
      } else {
      }
      if (options && options.refreshOne) {
        dispatch({
          type: ACTN_REFRESH_ONE,
          data: json
        });
      } else {
        dispatch({
          type: ACTN_REFRESH,
          data: json
        });
      }
      //dispatch({
      //  type: ACTN_REFRESH,
      //  data: json
      //});

      dispatch(isProcessing({
        key: url,
        isProcessing: false
      }));

      return json;
    }, (e) => {
      dispatch(isProcessing({
        key: url,
        isProcessing: false
      }));
      return { error: e };
    });
  }
}

const _updateRouting = (data, getState) => {
  var state = getState();

  return dispatch => {
    dispatch({
      type: ACTN_REFRESH,
      data: {
        routing: data
      }
    });
  }
}

export const updateRouting = (data) => (dispatch, getState) => {
  return dispatch(_updateRouting(data, getState))
}

const _updateSearchBox = (data, getState) => {
  var state = getState();

  if (state.searchBox && data.searchBox && state.searchBox.brandid != data.searchBox.brandid) {
    data.searchBox.text = "";
  }

  return dispatch => {
    dispatch({
      type: ACTN_REFRESH,
      data: {
        whoIsThisDataFor: data.whoIsThisDataFor,
        searchBox: data.searchBox
      }
    });
  }
}

const _updateSomething = (data, getState) => {
  var state = getState();

  return dispatch => {
    dispatch({
      type: ACTN_UPDATE_SOMETHING,
      data: {
        whoIsThisDataFor: data.whoIsThisDataFor, // just to make it explicit
        ...data
      }
    });
  }
}

export const updateSomething = (data) => (dispatch, getState) => {
  return dispatch(_updateSomething(data, getState))
}

export const updateSearchBox = (data) => (dispatch, getState) => {
  return dispatch(_updateSearchBox(data, getState))
}

export const doSomething = (url, payload, method, options) => (dispatch, getState) => {
  return dispatch(_doSomething(url.toLowerCase(), payload, method, options, getState))
}
