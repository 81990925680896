import React from 'react';

import Reauth from '../../reauth/src/index'; //'reauth-private';
import NavBarUserMenu from './navbar-user-menu.jsx';
import NavBarProductsMenu from './navbar-products-menu.jsx';
import NavBarMiniCart from "./navbar-mini-cart.jsx";
import NavBarMemos from "./navbar-memos.jsx";
import NavBarTwilio from "./navbar-twilio.jsx";
import NavBarMessage from "./navbar-message.jsx";

class NavBar extends React.Component {
  constructor(props) {
    super(props)

    this.loginFields = [
      { name: 'email', type: 'email', label: 'Email', autoComplete: 'email', pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ },
      { name: 'password', type: 'password', label: 'Password', autoComplete: 'current-password' },
      { name: 'remember', type: 'checkbox', label: 'Ricordami' }
    ];

    this.registrationFields = [
      { name: 'company', type: 'hidden', label: 'Company' },
      { name: 'name', type: 'hidden', label: 'Name' },
      { name: 'email', type: 'email', label: 'Email', autoComplete: 'email', pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ },
      { name: 'password', type: 'password', label: 'Password' },
      { name: 'terms', type: 'checkbox', label: 'Acconsento al trattamento dei miei dati personali ai sensi dell\'art. 13 del D.Lgs. 30/06/2003 n°196.' },
      { name: 'phone', type: 'text', label: 'Numero di telefono' }
    ];

    this.state = {
      ui: {
        configuration: { show: false, ref: React.createRef() },
        minicart: { show: false, ref: React.createRef() },
        presencemenu: { show: false, ref: React.createRef() },
        usermenu: { show: false, ref: React.createRef() },
        products: { show: false, ref: React.createRef() }
      }
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickRaw, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickRaw, false);
  }

  handleClickRaw = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const { ui } = this.state;

    var _ui = { ...ui };
    var updateState = false;
    Object.keys(ui).forEach(key => {
      let value = ui[key];

      if (value.ref && value.ref.current && value.show && !value.ref.current.contains(target)) {
        _ui[key] = { ..._ui[key], show: false };
        updateState = true;
      }

      //if (value.ref && value.ref.current) {
      //  if (value.ref.current.contains(target)) {
      //    console.log("handleClickRaw", "inside", key, event);
      //  } else {
      //    console.log("handleClickRaw", "outside", key, event);
      //  }
      //}
    });

    if (updateState) {
      this.setState({ ui: _ui });
    }
  };

  handleClick = (event, name) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    name = name || target.name || (target.parentElement ? target.parentElement.name : undefined);

    //console.log("handleClick", event);

    const { ui } = this.state;

    if (!target) {
      return;
    }

    var _ui = { ...ui };
    var updateState = false;
    //Object.keys(ui).forEach(key => {
    //  let value = ui[key];

    //  if (value.ref && value.ref.current) {
    //    if (value.ref.current.contains(target)) {
    //      console.log("handleClick", "inside", key, event);
    //    } else {
    //      console.log("handleClick", "outside", key, event);
    //    }
    //  }
    //});

    if (Object.keys(ui).some(key => key == name)) {
      switch (name) {
        default:
          _ui[name] = { ..._ui[name], show: !_ui[name].show };
          updateState = true;
          break;
      }
    }

    if (updateState) {
      this.setState({ ui: _ui });
    }
  };

  render = () => {
    const { ui } = this.state;
    const { IsAuthenticated, Name } = this.props;

    //<div className={"dropdown-menu" + (ui.configuration ? " show" : "")} aria-labelledby="navbardropdown">
    //  <a className="dropdown-item" href="#">action</a>
    //  <a className="dropdown-item" href="#">another action</a>
    //  <div className="dropdown-divider" />
    //  <a className="dropdown-item" href="#">something else here</a>
    //</div>
    var UserMenu = null;
    if (IsAuthenticated) {
      UserMenu = <NavBarUserMenu
        name={Name}
        ui={ui}
        handleClick={this.handleClick}
      />;
    } else {
      UserMenu = <Reauth
        //onAuth={(response, provider) => { console.log([response, provider]); }}
        oSignup={(response, provider) => { console.log("ok"); }}
        endpoint={"/"}
        component="div"
        componentClass="btn btn-outline nav-login"
        componentText="Log In"

        loginFields={this.loginFields}
        registrationFields={this.registrationFields}
      />;
    }

          //<form className="form-inline my-2 my-lg-0 d-none d-sm-block">
          //  <input className="form-control mr-sm-2" type="search" placeholder="search" aria-label="search" />
          //  {
          //    //<button className="btn btn-outline-light my-2 my-sm-0" type="submit">search</button>
          //  }
          //</form>
    var menu = null;
    if (IsAuthenticated) {
      menu = <div><nav className="navbar navbar-expand navbar-dark bg-orange" style={{ fontFamily: "pt sans narrow", paddingBottom: 0, paddingTop: 4 }}>
        <a className="navbar-brand" href="/"><i className="fas fa-broom"></i> <span className="dnone-when-small" style={{ fontFamily: "pt sans narrow", fontWeight: 700, fontSize: '22px' }}>backoffice</span></a>
        <div className="collapse navbar-collapse" id="navbarsupportedcontent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <a className="nav-link" href="/bo/orders">Orders <span className="sr-only">(current)</span></a>
            </li>
            <NavBarProductsMenu ui={ui} handleClick={this.handleClick} />
            <li className={"nav-item dropdown" + (ui.configuration.show ? " show" : "")} ref={ui.configuration.ref}>
              <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                onClick={(e) => this.handleClick(e)}
                name="configuration">
                Configuration
                </a>
              <div className={"dropdown-menu" + (ui.configuration.show ? " show" : "")} aria-labelledby="navbardropdown">
                <a className="dropdown-item" href="/configuration/editor"><i className="fas fa-code"></i> Editor</a>
              </div>
            </li>
          </ul>
          {
            //search here
          }
          <NavBarTwilio
            ui={ui}
            handleClick={this.handleClick}
          />
          <NavBarMemos
          />
          <NavBarMiniCart
            ui={ui}
            handleClick={this.handleClick}
          />
          {UserMenu}
        </div>
      </nav>
        <nav className="navbar navbar-expand navbar-dark bg-orange" style={{ fontFamily: "pt sans narrow", paddingBottom: 0, paddingTop: 0, color: "rgba(255, 255, 255, .90)" }}>
          <NavBarMessage/>
        </nav>
      </div>;
    } else {
      menu = <nav className="navbar navbar-expand navbar-dark bg-orange" style={{ fontFamily: "pt sans narrow" }}>
        <a className="navbar-brand" href="/"><i className="fas fa-broom"></i> <span className="dnone-when-small" style={{ fontFamily: "pt sans narrow", fontWeight: 700, fontSize: '22px' }}>backoffice</span></a>
        <div className="collapse navbar-collapse" id="navbarsupportedcontent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <a className="nav-link" href="#">You are not authenticated!</a>
            </li>
          </ul>
          {UserMenu}
        </div>
      </nav>;
    }

    return (
      <div className="container pr-0 pl-0">
        {menu}
      </div>
    );
  }
}


export default NavBar;