import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import chroma from 'chroma-js';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color || "#AAAAAA");
    return {
      ...styles,
      fontFamily: "Roboto Condensed",
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? data.color
          : isFocused
            ? color.alpha(0.1).css()
            : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          //: data.color,
          : isFocused
            ? data.color
            : 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color || "#AAAAAA");
    return {
      ...styles,
      fontFamily: "Roboto Condensed",
      backgroundColor: data.color,
      //backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => {
    const color = chroma(data.color || "#AAAAAA");
    return {
      ...styles,
      color: chroma.contrast(color, 'white') > 2
        ? 'white'
        : 'black',
      //color: data.color,
    };
  },
  multiValueRemove: (styles, { data }) => {
    const color = chroma(data.color || "#AAAAAA");
    return {
      ...styles,
      color: chroma.contrast(color, 'white') > 2
        ? 'white'
        : 'black',
      ':hover': {
        backgroundColor: color.brighten().hex(),
        //color: color.alpha(0.1),
      },
    };
  },
};

function OrderTagger({ order, processActions }) {
  //console.log("order.json.ordertags", order.json.ordertags);
  const [selectedOption, setSelectedOption] = useState(order.json.ordertags);

  const orderTags = useSelector(state => state.orders.ordersConfiguration.tags);

  var handleChange = (selectedOption) => {
    //console.log("selectedOption", selectedOption);
    setSelectedOption(selectedOption);

    processActions(
      "tagger",
      order, null, null,
      { tags: selectedOption }
    ).then((d) => {
        //console.log("tagger-outcome",d);
    });
  }

  return (
    <Select
      onChange={handleChange}
      closeMenuOnSelect={true}
      components={animatedComponents}
      defaultValue={selectedOption}
      isMulti
      options={orderTags}
      styles={colourStyles}
    />
  );
}


export { OrderTagger };

