import React from "react";
import ReactDOM from "react-dom";
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';

import { Localize } from "../../../scripts/shared/localize.js";

class GoogleProvider extends React.Component {
                     
  static clientId = "311228913182.apps.googleusercontent.com"
  
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    this.logIn();
  }

  logIn = () => {
    gapi.load('auth2', () => {
      console.log("TEST");
      gapi.auth2.init({
        client_id: GoogleProvider.clientId,
        cookiepolicy: 'single_host_origin'
      }).then((d) => {
        console.log("google", "init-then()", d);
        this.checkAutoLogin();

        this.attachSignin();
        let gprov = document.getElementById("googleProvider");
        if (gprov)
          this.attachSignin(gprov);
      }, (e) => {
          this.props.onError({ error: Localize.L("Impossibile usare l'autenticazione social. Sei in incognito?")});
          console.log("google", "init-onError", e);
      });
      console.log("google", "componentDidMount-end");
    });
  }

  checkAutoLogin = () => {
    let googleUser;

    console.log("google", "checkAutoLogin");
    
    if (gapi.auth2.getAuthInstance() === null) return;
    googleUser = gapi.auth2.getAuthInstance().currentUser.get();
    if (typeof googleUser !== "undefined" && googleUser.isSignedIn()) {
      //fast login
      console.log("google", "isSignedIn", true);
      this.props.onAuth(googleUser, "google");
    }
    console.log("google", "isSignedIn", false);
  }

  attachSignin = (element) => {
    console.log("google", "attachSignin");
    gapi.auth2.getAuthInstance().attachClickHandler(element, {},
      (googleUser) => {
        this.props.onAuth(googleUser, "google");
      }, (error) => {
        //nothing happened
      }
    );
    //this.auth2.attachClickHandler(element, {},
    //  function (googleUser) {
    //    this.props.onAuth(googleUser, "google");
    //  }.bind(this), function (error) {
    //    //nothing happened
    //  }.bind(this)
    //);
  }

  render() {
    //this.checkAutoLogin();

    return (
      <IconButton id="googleProvider" aria-label={Localize.L("Accedi con Google")} color="primary">
        <img src="/images/google.png" alt={Localize.L("Accedi con Google")} style={{ height: 48 }} />
      </IconButton>
    );
  }
}

GoogleProvider.propTypes = {
  onAuth: PropTypes.func.isRequired //handle authentication process
};

export default GoogleProvider;