
import {
  ACTN_REFRESH,
  PUSHER_CLIENT_EVENT
} from '../config/action-types.js';
import { el } from 'date-fns/locale';

const initialPusherState = {
  appId: "",
  appKey: "",
  cluster: "",
  members: []
};

export default function (state = initialPusherState, action) {
  switch (action.type) {

    case ACTN_REFRESH:
      //if (action.data && action.data.pusher) {
      //  return {
      //    ...state,
      //    ...action.data.pusher
      //  }
      //}
      var _state = { ...state };
      ["pusher"].forEach(p => {
        if (action.data && action.data[p]) {
          _state = { ..._state, ...action.data[p] }
        }
      });
      ["twilio", "members", "user"].forEach(p => {
        if (action.data && action.data[p]) {
          if ((!!action.data[p]) && (action.data[p].constructor === Array)) {
            _state = {
              ..._state, [p]: action.data[p]
            }
          } else {
            _state = {
              ..._state, [p]: { ..._state[p], ...action.data[p] }
            }
          }
        }
      });
      ["twilio_queue"].forEach(p => {
        if (action.data && action.data[p]) {
          _state = {
            ..._state, twilio: { ..._state.twilio, queue: action.data[p] }
          }
        }
      });
      return _state;

    case PUSHER_CLIENT_EVENT:
      var { triggered } = state;
      triggered = (triggered || []);
      triggered.push(action.data);
      return { ...state, triggered: triggered };

    default:
      return state;
  }
}