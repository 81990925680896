import HttpRequest from '../../utils/HttpRequest';

import { isProcessing } from "../actions/actProcessing";

export const ACTN_REFRESH_DATABASE = "ACTN_REFRESH_DATABASE";

const default_staleBefore = 1588335596881;
const default_staleAfter = 1000 * 60 * 60 * 24;
const nowis = Date.now();

const staleWhen = [
  { name: "wheels", staleBefore: 1603490908265, staleAfter: 1000 * 60 * 60 * 48 },
  { name: "tires", staleBefore: 1606952040033, staleAfter: 1000 * 60 * 60 * 48 },
  { name: "orders", staleBefore: 1607768730035, staleAfter: 1000 * 60 * 60 * 48 },
  { name: "isProcessing", neverStale: true },
  { name: "minicart", neverStale: true },
  { name: "todo", staleBefore: 1588335596881, staleAfter: 1000 * 60 * 60 * 24 },
  { name: "drawer", neverStale: true },
  { name: "shared", neverStale: true },
  { name: "pusher", neverStale: true }
];

const _refreshIfStale = (data) => {
  return dispatch => {
    return initFromServer(data, dispatch).then((data) => {
      dispatch({
        type: ACTN_REFRESH_DATABASE,
        ...data
      })
    });
  }
}

export const refreshIfStale = () => (dispatch, getState) => {
  var state = getState();

  var entriesToRequest = [];

  Object
    .entries(state)
    .forEach(([key, valueObject]) => {
      if (isStale(key, valueObject)) {
        entriesToRequest.push(key);
      }
    });

  if (entriesToRequest.length) {
    return dispatch(_refreshIfStale(entriesToRequest))
  }
}

export const invalidate = (entry) => (dispatch, getState) => {

  var entriesToRequest = [];
  if (entry && entry.constructor === Array) {
    entriesToRequest = entry;
  }else if (entry) {
    entriesToRequest.push(entry);
  }

  if (entriesToRequest.length) {
    return dispatch(_refreshIfStale(entriesToRequest))
  }
}

var initFromServer = (data, dispatch) => {
  //var url = (location.href.includes("localhost") || 1==1 ?
  //  "/bo/state/initialize" :
  //  ("https://d2wwsiiaf1due0.cloudfront.net") + "/bo/state/initialize");
  var url = "/bo/state/initialize";

  dispatch(isProcessing({
    key: url,
    isProcessing: true
  }));

  var xhr = new HttpRequest("POST", url, "application/json");
  var payload = {
    requesting: data
  };

  return xhr.send(payload).then((response) => {
    dispatch(isProcessing({
      key: url,
      isProcessing: false
    }));
    return {
      ...response.json
    }
  }).catch((e) => {
    dispatch(isProcessing({
      key: url,
      isProcessing: false
    }));
  });
}

export const cleanUpStaleStuff = (state) => {
  var _state = { ...state };
  Object
    .entries(state)
    .forEach(([key, valueObject]) => {
      if (isStale(key, valueObject)) {
        _state[key] = undefined;
      }
      //temporary fix
      if (key == "orders" && (!valueObject.domains || !valueObject.domains.length)) {
        _state[key] = undefined;
      }
      if (key == "tires" && (!valueObject.database || !Object.keys(valueObject.database).length)) {
        _state[key] = undefined;
      }
      //should become something configurable
    });

  return _state;
}

const isStale = (key, valueObject) => {
  var ret = false;

  var _staleWhen = staleWhen.find(s => s.name == key);
  if (!_staleWhen) {
    alert("missing stale configuration for redux store: " + key);
  }
  if (!_staleWhen.neverStale) {
    var _staleBefore = _staleWhen.staleBefore || default_staleBefore || nowis;
    var _staleAfter = _staleWhen.staleAfter || default_staleAfter;
    if (!(valueObject && valueObject.nowis
      && ((nowis - valueObject.nowis) < _staleAfter)
      && valueObject.nowis > _staleBefore)) {
      ret = true;
    }
  }

  return ret;
}

//export const isStale = (state) => {
//  var ret = false;
//  Object
//    .entries(state)
//    .forEach(([key, valueObject]) => {
//      var _staleWhen = staleWhen.find(s => s.name == key);
//      if (!_staleWhen) {
//        alert("missing stale configuration for redux store: " + key);
//      }
//      if (!_staleWhen.neverStale) {
//        var _staleBefore = _staleWhen.staleBefore || default_staleBefore || nowis;
//        var _staleAfter = _staleWhen.staleAfter || default_staleAfter;
//        if (!(valueObject && valueObject.nowis
//          && ((nowis - valueObject.nowis) < _staleAfter)
//          && valueObject.nowis > _staleBefore)) {
//          ret = true;
//        }
//      }
//    });
//  return ret;
//}
