import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import JsonEditor from '../../reactjs/json-editor/JsonEditor'
import { doSomething } from '../gcomponents/store/actions/actShared.js';
import { isProcessing } from "../gcomponents/store/actions/actProcessing";
import ProgressLoading from '../gcomponents/utils/ProgressLoading'

import File from '../json-editor/subs/File';

//import deburr from 'lodash/deburr'; accent characters transformation --> è => e
import Autocomplete from 'react-autocomplete';

const endpoint = "/api/jsoneditor"

function JsonSmartEditor() {
  const isProcessing = useSelector(state => state.isProcessing.mostRecentStatus)

  const [domain, setDomain] = useState("backoffice");
  const [beingConfigured, setBeingConfigured] = useState({});
  const [filename, setFilename] = useState({});
  const [content, setContent] = useState({});
  const [revision, setRevision] = useState(null);
  const [draft, setDraft] = useState(null);
  const [schema, setSchema] = useState({});
  const [title, setTitle] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    // Update the document title using the browser API
    var folder = (new URLSearchParams(location.search)).get("folder");
    var distributorid = (new URLSearchParams(location.search)).get("distributorid");
    setBeingConfigured({ folder: folder, distributorid: distributorid })
    if (folder == "supplier" && distributorid > 0) {
      var _filename = "supplier-" + distributorid + ".json"
      setFilename(_filename)
      var url = endpoint + "/" + _filename + "?domain=" + domain
      dispatch(doSomething(url, {}, "GET")).then(d => {
        if (d && d.json) {
          setContent(d.json)
          setRevision(new Date().getTime())
          setTitle(`Editing supplier config for ${d.json.distributorid} ${d.json.twoletterscode} ${d.json.companyname}`)
          if (d && d.schema) {
            setSchema(d.schema)
          }
        }
        return d;
      });
    }
  }, []);

  var handleFileChange = (e) => {
    setDraft(new Date().getTime())
    setContent(e)
  }

  var handleSave = (e) => {
    var distributorid = beingConfigured.distributorid
    var folder = beingConfigured.folder
    console.log("handleSave", domain, filename, content);
    var _filename = "supplier-" + distributorid + ".json"
    var url = endpoint + "/" + _filename + "?domain=" + domain
    dispatch(doSomething(url, { json: content, schema: {} }, "PUT")).then(d => {
      if (d && d.json) {
        setContent(d.json)
        setRevision(new Date().getTime())
        setTitle(`Editing supplier config for ${d.json.distributorid} ${d.json.twoletterscode} ${d.json.companyname}`)
        if (d && d.schema) {
          setSchema(d.schema)
        }
      }
      return d;
    });
  }

  var ret = null;
  var _file = null;
  if (revision) {
    _file = (
      <File
        key={filename + revision}
        value={content}
        schema={schema}
        onChange={handleFileChange}
      />
    )
  }
  ret = (
    <div className="container">
      <ProgressLoading processing={isProcessing} />
      <div className="row mt-2">
        <div className="col-12" style={{ overflow: "hidden", padding: 0 }}>
          <div style={{ fontWeight: 700 }}>{title}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-6 p-1" style={{ overflow: "hidden", padding: 0 }}>
          <Changes revision={revision} draft={draft} />
        </div>
        <div className="col-6 p-1" style={{ overflow: "hidden", padding: 0, textAlign: "right" }}>
          <button className="btn btn-primary btn-sm" style={{ fontFamily: "Roboto Condensed" }} onClick={e => handleSave(e)}>Save</button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 json-smart-editor-container" style={{ minHeight: 600, overflow: "hidden", padding: 0 }}>
          {_file}
        </div>
      </div>
    </div>
  );

  return ret;
}

function Changes({ revision, draft }) {
  const [id, setId] = useState(null);
  const [useless, setUseless] = useState(null);

  useEffect(() => {
    if (draft) {

      if (id) {
        //console.log("draft changed", draft, id)
        window.clearInterval(id)
      }

      var distance = new Date().getTime() - draft

      var _id = window.setInterval((originalDistance, uselessBoolean) => {
        var newdistance = new Date().getTime() - draft
        setUseless(newdistance)
        //console.log("!!!window.setInterval", "originalDistance", originalDistance, "uselessBoolean", uselessBoolean, "draft", draft, "newdistance", newdistance)
      }, 1000 * 30, distance, true);

      setId(_id)

      return function cleanup() {
        if (id) {
          window.clearInterval(id)
        }
      }
    }
  },[draft])

  var ret = <span className="badge badge-secondary">No changes</span>;
  if (draft && draft > revision) {
    var when = formatDistance(new Date(draft), new Date(), { addSuffix: true })
    ret = <span><span className="badge badge-danger mr-1">Unsaved</span><span className="badge bg-warning text-dark">Changed {when}</span></span>;
  }

  return ret;
}

export default JsonSmartEditor;