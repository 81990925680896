import TurndownService from 'turndown'
var turndownService = new TurndownService()

export const REPLY_MAIL = "reply-mail";

export const MAIL_ASK_ID = "mail-ask-id";
export const MAIL_ASK_RETURN = "mail-ask-return-to-supplier";
export const MAIL_ASK_TRACKING = "mail-ask-tracking";
export const MAIL_ASK_PHONE = "mail-ask-phone";
export const MAIL_SEND_TRACKING = "mail-send-tracking";

const ourDomains = ["gomme-auto.it", "cerchigomme.it", "gomzon.com", "reifenkumpel.de"];

var AddressFromOrder=(r) =>{
  var ret = "";
  if (r.companyname && r.businessorpersonal == 'business') {
    if (r.firstname || r.lastname) {
      ret += r.firstname + " " + r.lastname + "<br/>";
    }
    ret += r.companyname + "<br/>";
  } else {
    ret += r.firstname + " " + r.lastname + "<br/>";
  }

  if (r.careof) {
    ret += "C/O " + r.careof + "<br/>";
  }

  ret += r.route + " " + r.streetnumber + "<br/>";
  ret += r.locality + " " + r.province + " " + r.postal_code + " " + r.country + "<br/>";

  if (r.shopid) {
    ret += "phone " + r.shop.phone + "<br/>";
    ret += "phone " + r.phone + "<br/>";
  } else {
    ret += "phone " + r.phone + "<br/>";
  }

  if (r.pleasenote && r.pleasenote.length > 0) {
    ret += "PLEASE-NOTE: " + r.pleasenote + "<br/>";
  }

  return ret;
}

const MailTemplate = (template, order, item, user, replyingTo) => {
  var sender = "";
  var receiver = "";
  var subject = "";
  var body = "";
  var uglyPre = "";

  switch (template) {
    default:
      break;

    case REPLY_MAIL:
      sender = user.email;
      //not the best solution possible
      if (order) {
        sender = "ordini@" + order.domain;
        if (!(ourDomains.some(d => (order && d == order.domain)))) {
          sender = "ordini@" + "gomzon.com";
        }
      }
      if (!order && replyingTo.details && !(ourDomains.some(d => (replyingTo.details.m_from.includes(d))))) {
        sender = "ordini@" + "gomzon.com";
      }
      var ret = ReplyMail(replyingTo, user, order);
      subject = ret.subject;
      uglyPre = ret.uglyPre;
      if (ourDomains.some(d => replyingTo.details.m_from.includes("@" + d))) {
        receiver = replyingTo.details.m_to;
      } else {
        receiver = replyingTo.details.m_from
      }
      ret = { ...ret, sender: sender, receiver: receiver }
      break;

    case MAIL_ASK_PHONE:
      sender = user.email;
      //not the best solution possible
      sender = "ordini@" + order.domain;
      //not the best solution possible
      receiver = order.email;

      subject = order.domain + " - richiesta contatto telefonico - ordine nr. " + order.cartid + "-" + order.oid;
      var orderdate = moment(order.forwardedon).format("ddd D MMM YYYY HH:mm:ss");
      var uglyPre = `<p>${order.domain} - Ordine ${order.cartid} del ${orderdate}</p>`;
      uglyPre += "<p><strong>Abbiamo urgente bisogno di contattarti</strong> telefonicamente in merito al tuo ordine, ma non ci riusciamo, il numero risulta inesistente, oppure nessuno risponde oppure risponde sempre la segreteria.</p>";
      uglyPre += `<p>Il numero di telefono da te fornitoci e': ${order.phone}</p>`;
      uglyPre += "<p>Ti chiediamo di <strong>rispondere a questa email</strong> fornendoci un recapito telefonico al quale sia possibile raggiungerti.</p>";
      uglyPre += "<p>L'evasione del tuo ordine e' sospesa finche' non riusciremo a contattarti telefonicamente.</p>";
      uglyPre += "<p>Controlla il tuo ordine online</p>";
      uglyPre += `<p>Ricorda che puoi controllare lo stato del tuo ordine in qualsiasi momento accedendo alla pagina dei <a href="https://${order.domain}/user/orders?key=${order.rndkey}" >tuoi ordini</a>.</p>`;

      uglyPre += "<br/>";
      switch (order.domain) {
        case "gomme-auto.it":
          uglyPre += "telefono: 02-94754019";
          break;
        case "cerchigomme.it":
          uglyPre += "telefono: 02-94759421";
          break;
      }
      break;

    case MAIL_SEND_TRACKING:
      sender = user.email;
      //not the best solution possible
      sender = "ordini@" + order.domain;
      //not the best solution possible
      receiver = order.email;

      subject = order.domain + " - invio link per tracking - ordine nr. " + order.cartid + "-" + order.oid;
      var orderdate = moment(order.forwardedon).format("ddd D MMM YYYY HH:mm:ss");
      var uglyPre = `<p>${order.domain} - Ordine ${order.cartid} del ${orderdate}</p>`;
      uglyPre += "<p>Tracking:</p>";

      order.shippingLabels.forEach(function (v, i, a) {
        if (((v.status || "").toUpperCase() != "CANCELLED") && ((v.courier_tracking_link || "") != ""))
          uglyPre += "<a href=\"" + v.courier_tracking_link + "\">" + v.courier_tracking_link + "</a>";
      });

      uglyPre += "<p>Controlla il tuo ordine online</p>";
      uglyPre += `<p>Ricorda che puoi controllare lo stato del tuo ordine in qualsiasi momento accedendo alla pagina dei <a href="https://${order.domain}/user/orders?key=${order.rndkey}" >tuoi ordini</a>.</p>`;

      uglyPre += "<br/>";
      switch (order.domain) {
        case "gomme-auto.it":
          uglyPre += "telefono: 02-94754019";
          break;
        case "cerchigomme.it":
          uglyPre += "telefono: 02-94759421";
          break;
      }
      break;

    case MAIL_ASK_RETURN:
      var bestellung = null;
      if (item.twoletterscode == "T24" && order.json && order.json.tyretwfr) {
        var tmp = order.json.tyretwfr.filter((w, i, a) => { return w.itemid == item.itemid; });
        if (tmp) {
          tmp = tmp[0];
          if (order.json.T24OrderInfo) {
            bestellung = tmp.ordernr;
            tmp = order.json.T24OrderInfo.filter((v, i, a) => { return v.bestellung == tmp.ordernr });
            if (tmp) {
              tmp = tmp[0];
              receiver = tmp.supplier_email;
            }
          }
        }
      }

      var address = AddressFromOrder(order);

      subject =order.domain + " - richiesta di reso - return request - ordine nr. " +order.cartid + "-" +order.oid;
      var uglyPre = "";
      if (bestellung) {
        uglyPre += "TYRE24 order number: " + bestellung;
        uglyPre += "<br/><br/>";
      }
      uglyPre += "We request this order to be canceled and tires to be returned to you.";
      uglyPre += "<br/><br/>";
      uglyPre += "Please organize RETURN of these tires to your warehouse.";
      uglyPre += "<br/><br/>";
      uglyPre += "Credit for the goods returned is hereby claimed and demanded. Standard agreed policy apply.";
      uglyPre += "<br/><br/>";
      uglyPre += "Pickup address follow:";
      uglyPre += "<br/><br/>";
      uglyPre += address;
      uglyPre += "<br/><br/>";
      uglyPre += "<hr/>";
      uglyPre += "<br/><br/>";
      uglyPre += "Questo cliente si accorge ora di aver sbagliato misura.";
      uglyPre += "<br/><br/>";
      uglyPre += "Ti prego di organizzare il rientro dei pneumatici che vanno ritirati da:";
      uglyPre += "<br/><br/>";
      uglyPre += address;
      uglyPre += "<br/><br/>";
      uglyPre += "lo staff di <a href=\"https://" +order.domain + "\">" +order.domain + "</a>";
      uglyPre += "<br/>";
      uglyPre += "mail: <a href=\"mailto:ordini@" +order.domain + "\">ordini@" +order.domain + "</a>";
      uglyPre += "<br/>";
      switch (order.domain) {
        case "gomme-auto.it":
          uglyPre += "telefono: 02-94754019";
          break;
        case "cerchigomme.it":
          uglyPre += "telefono: 02-94759421";
          break;
      }
      break;

    case MAIL_ASK_TRACKING:
      sender = user.email;
      //not the best solution possible
      sender = "ordini@" + order.domain;
      //not the best solution possible
      receiver = order.email;

      var bestellung = null;
      if (item.twoletterscode == "T24" && order.json && order.json.tyretwfr) {
        var tmp = order.json.tyretwfr.filter((w, i, a) => { return w.itemid == item.itemid; });
        if (tmp) {
          tmp = tmp[0];
          if (tmp.ordernr) {
            bestellung = tmp.ordernr;
            if (order.json.T24OrderInfo) {
              tmp = order.json.T24OrderInfo.filter((v, i, a) => { return v.bestellung == bestellung });
              if (tmp) {
                tmp = tmp[0];
                receiver = tmp.supplier_email;
              }
            }
          }
        }
      }

      if (!bestellung) {
        bestellung = order.cartid;
      }

      subject = order.domain + " - richiesta tracking - tracking request - order number " + bestellung + (bestellung != order.cartid ? " (our ref. " + order.cartid + "-" + order.oid + ")" : "");
      var uglyPre = "Please provide tracking id, shipping information for this order number: " + bestellung;
      uglyPre += "<br/><br/>";
      uglyPre += "Tires have not been delivered yet, tracking id/shipping information are still missing.";
      uglyPre += "<br/><br/>";
      uglyPre += "<a href=\"https://" +order.domain + "\">" +order.domain + "</a>";
      uglyPre += "<br/>";
      uglyPre += "mail: <a href=\"mailto:ordini@" +order.domain + "\">ordini@" +order.domain + "</a>";
      uglyPre += "<br/>";
      switch (order.domain) {
        case "gomme-auto.it":
          uglyPre += "telefono: 02-94754019";
          break;
        case "cerchigomme.it":
          uglyPre += "telefono: 02-94759421";
          break;
      }
      break;

    case MAIL_ASK_ID:
      sender = user.email;
      //not the best solution possible
      sender = "ordini@" + order.domain;
      //not the best solution possible
      receiver = order.email;

      subject = order.domain + " - richiesta documento d'identità - ordine nr. " + order.cartid + "-" + order.oid;
      //var uglyPre = document.getElementById("ask-for-id-html").innerHTML;
      var uglyPre = "I pagamenti con carta di credito su " + order.domain + " vengono gestiti da società esterne specializzate, <b>Nexi, Stripe e Paypal</b>.";
      uglyPre += "<br/><br/>";
      uglyPre += "Per alcune transazioni, queste società ci richiedono di verificare l'autenticità del titolare della carta utilizzata per il pagamento, al fine di tutelare i titolari stessi e proteggerli dal rischio di eventuali frodi perpetrate con carte rubate.";
      uglyPre += "<br/><br/>";
      uglyPre += "Per questo <b>siamo a richiederle copia di un documento d'identità del titolare della carta di credito</b> con cui è stato fatto il pagamento dell'ordine in oggetto (nr. " + order.cartid + "-" + order.oid + ") e copia del <b>frontespizio della carta stessa</b> in modo da poter verificare la corrispondenza tra i due documenti, la preghiamo pertanto di volerli cortesemente inviare per e- mail.";
      uglyPre += "<br/><br/>";
      uglyPre += "È sufficiente scattare una foto con il proprio smartphone ed inviarla in risposta a questa email.";
      uglyPre += "<br/><br/>";
      uglyPre += "<b>Provvederemo ad evadere l'ordine immediatamente non appena ricevuti i documenti richiesti.</b>";
      uglyPre += "<br/><br/>";
      uglyPre += "lo staff di <a href=\"https://" + order.domain + "\">" + order.domain + "</a>";
      uglyPre += "<br/>";
      uglyPre += "mail: <a href=\"mailto:ordini@" + order.domain + "\">ordini@" + order.domain + "</a>";
      uglyPre += "<br/>";
      switch (order.domain) {
        case "gomme-auto.it":
          uglyPre += "telefono: 02-94754019";
          break;
        case "cerchigomme.it":
          uglyPre += "telefono: 02-94759421";
          break;
      }
      break;
  }

  var markdown = turndownService.turndown(uglyPre)
  //console.log("markeddown", markdown);
  //console.log("html", html);

  return {
    sender: sender,
    receiver: receiver,
    subject: subject,
    body: markdown
  };
}

export default MailTemplate;

const ReplyMail = (replyingTo, user, order) => {
  const { details } = replyingTo;

  var m_subject = details.m_subject;
  if (m_subject && m_subject.length > 4 && m_subject.substring(0, 4) == "RE: ") {
    m_subject = m_subject.substring(4);
  }
  var domain = order ? order.domain ? order.domain : "" : "";
  if (!(["gomme-auto.it", "cerchigomme.it", "reifenkumpel.de"].some(d => d == domain))) {
    domain = "gomzon.com";
  }
  var uglyPre = "<br/><br/>";
  uglyPre += user.displayname;
  uglyPre += "<br/>";
  uglyPre += "<a href=\"https://" + domain + "\">" + domain + "</a>";
  uglyPre += "<br/>";
  uglyPre += "mail: <a href=\"mailto:ordini@" + domain + "\">ordini@" + domain + "</a>";
  uglyPre += "<br/>";
  switch (order.domain) {
    case "gomme-auto.it":
      uglyPre += "telefono: 02-94754019";
      break;
    case "cerchigomme.it":
      uglyPre += "telefono: 02-94759421";
      break;
  }

  const ret = {
    subject: "RE: " + m_subject,
    uglyPre: uglyPre
  };

  return ret;
} 