import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {
  ACTN_REFRESH
} from '../../gcomponents/store/config/action-types.js';

function NavBarMessage(props) {
  // Declare a new state variable, which we'll call "count"
  const feeds = useSelector(state => state.shared.feeds)

  const dispatch = useDispatch();

  var ret = (
    <div style={{ paddingBottom: 4, lineHeight: 1, fontSize: "75%" }}>
      <i className="fas fa-terminal"></i>
    </div>
  )

  if (feeds) {
    if (feeds.disabled) {
      ret = (
        <div style={{ paddingBottom: 4, lineHeight: 1, fontSize: "75%" }}>
          <a href="https://feeds.gomme-auto.it" target="_blank" style={{ color:"rgba(255, 255, 255, 0.9)"}}>
            <i className="far fa-stop-circle" style={{ color: "rgba(207, 0, 15, 0.65)" }}></i> Feeds is disabled.
          </a>
        </div>
      )
    } else {
      var running = [];
      if (feeds.running && feeds.running.length) {
        feeds.running.forEach((r,i) => {
          running.push(<span key={"running-" + i} style={{ backgroundColor: "rgba(255,255,255,.2)", padding: "0px 4px", margin: "0px 4px"}}>{ r.path || r.destination || "???"}</span>)
        })
      }
      if (feeds.feedexception) {
        const r = feeds.feedexception;
        running.push(<span key={"running-exception"} style={{ backgroundColor: "rgba(207, 0, 15, 0.65)", padding: "0px 4px", margin: "0px 4px", fontWeight: 700 }}><i className="fas fa-exclamation-triangle"></i> {r.path || r.destination || "???"}</span>)
      }
      ret = (
        <div style={{ paddingBottom: 4, lineHeight: 1, fontSize: "75%" }}>
          <a href="https://feeds.gomme-auto.it" target="_blank" style={{ color: "rgba(255, 255, 255, 0.9)" }}>
            <i className="fas fa-running"></i> feeds running
          </a>
           {running}
        </div>
      )
    }
  }

  return ret;
}

export default NavBarMessage;
