import React from 'react';

const LogEntryType = ({ handleFilter, options, filter }) => {

  const handleClick = (e, v) => {
    handleFilter(e,v)
  };

  var radios = options.map(o => {
    return (
      <button key={o.value} type="button" className={"btn " + (filter.type == o.value ? "btn-secondary" :"btn-light")} onClick={e => handleClick(e, o)}>{o.label}</button>
    );
  });

  return (
    <div className="col" style={{textAlign: "center"}}>
      <div className="btn-group btn-group-sm" role="group" aria-label="Basic example">
        {radios}
      </div>
    </div>
  );
}

export default LogEntryType;