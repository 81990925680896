import React from 'react'
import JsonEditor from '../../reactjs/json-editor/JsonEditor'
import LinearProgress from '@material-ui/core/LinearProgress'

//import deburr from 'lodash/deburr'; accent characters transformation --> è => e
import Autocomplete from 'react-autocomplete';

class JsonEditorContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { domains: [], files: [], editedDomain: "", currentDomain: "", flat: [] };
  }

  static searched = false;

  remoteDomains = []; //fetched from REST

  componentDidMount() {
    this.setState({ loading: true });
    fetch('/api/jsoneditor/config')
      .then(response => response.json())
      .then(data => {
        let domains = []
        for (let d in data.domains) {
          domains.push({ label: data.domains[d]});
        }
        this.setState({ domains: domains, flat: domains, loading: false });
      });
  }
  
  fetchDomainFiles = (domain) => () => {
    this.setState({ editedDomain: domain, files: [], loading: true });
    fetch('/api/jsoneditor/list?domain=' + domain)
      .then(response => response.json())
      .then(data => {
        let stateObject = {};
        JsonEditorContainer.searched = true;
        if (data.files.length > 0) {
          stateObject = { currentDomain: domain, files: data.files };
          console.log(this.state);
        }
        stateObject.loading = false;
        this.setState(stateObject);
      });
  }

  isSelectable = (item) => {
    return this.state.editedDomain === "" || item.label.indexOf(this.state.editedDomain) !== -1;
  }

  render() {
    let { classes } = this.props;
    let { state } = this;

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 autocomplete-container" style={{padding:5, paddingLeft:0, opacity: state.loading ? 0.3 : 1}}>
            <Autocomplete
                inputProps={{ readOnly: this.state.loading, placeholder: "Inserisci il dominio di cui modificare i file.", className: 'search-domain' }}
                getItemValue={(item) => item.label}
                items={this.state.domains}
                renderItem={(item, isHighlighted) =>
                  <div key={item.label} className={this.isSelectable(item) ? "" : "no-selectable"} style={{ width: 300, background: isHighlighted ? 'lightgray' : 'white', fontSize: 15, cursor: 'pointer' }}>
                    {item.label}
                  </div>
                }
                isItemSelectable={(item) => this.isSelectable(item)}
                value={this.state.editedDomain}
                onChange={(e) => { this.setState({ editedDomain: e.target.value }); }}
                onSelect={(val) => { this.fetchDomainFiles(val)() } }
            />
          </div>

          <div className="col-md-12" style={{ minHeight: 600, overflow: "hidden", padding: 0 }}>
            {this.state.currentDomain.length > 0 && (<JsonEditor
              files={this.state.files}
              endpoint="/api/jsoneditor/"
              domain={this.state.currentDomain}
            />)}
            {state.loading && (<LinearProgress />)}
            {JsonEditorContainer.searched && state.files.length === 0 && this.state.editedDomain.length > 0 && (
              <img style={{ height: 200 }} src="/images/empty.png" />  
            )}
          </div>
        </div>
      </div>  
    );
  }
}

export default JsonEditorContainer;