import React from 'react';
import { connect } from 'react-redux';

import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { DrawerMemo } from "./drawer-memo.jsx";
import DrawerInboxList from "./drawer-inbox-list.jsx";
import FolderSwitcher from "./drawer-inbox-folder-switcher.jsx";

import { doSomething as todoDoSomething } from "../../../gcomponents/store/actions/actTodo";

export const Folder = { Inbox: "Inbox", Sent: "Sent", Processed: "Done" };

class DrawerInbox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      folders: [{ value: Folder.Inbox, icon: "fas fa-inbox fa-fw", selected: true }, { value: Folder.Sent, icon: "far fa-paper-plane fa-fw" }, { value: Folder.Processed, icon: "fas fa-clipboard-check fa-fw" }].map(f => {
        return { label: f.value, value: f.value, selected: f.selected, icon: f.icon }
      })
    };
  }

  flagMemoAsRead = (e, hashkey) => {
    var { drawerIsOpen, memos } = this.props;

    var url = "/bo/todo/" + hashkey + "/processed";
    var payload = {};
    this.props.todoDoSomething(url, payload)
      .then(() => {
    });
  }

  trashMemo = (e, hashkey) => {
    var url = "/bo/todo/" + hashkey + "/delete";
    var payload = {};
    this.props.todoDoSomething(url, payload)
      .then(() => {
      });
  }

  handleFolderSwitch = (e, v) => {
    var { folders } = this.state;
    this.setState({
      folders: folders.map(f => { return { ...f, selected: f.value == v } })
    });
  }

  render() {
    var ret = null;
    var { drawerIsOpen, memos } = this.props;
    var { folders } = this.state;

    //<div style={{ justifyContent: "flex-end" }}>
    //  <div className="row">
    //    <div className="col">
    //      <IconButton onClick={(e) => this.props.toggleDrawer(e)}>
    //        <ChevronRightIcon />
    //      </IconButton>
    //    </div>
    //    <div className="col">
    //      <FolderSwitcher
    //        folders={folders}
    //        handleFolderSwitch={this.handleFolderSwitch}
    //      />
    //    </div>
    //  </div>
    //</div>

    if (drawerIsOpen) {
      ret = (
        <Drawer
          className="bo-orders-drawer"
          anchor="right"
          ref={element => this.drawerRef = element}
          open={drawerIsOpen}
          onClose={(e) => this.props.toggleDrawer(e)}
          classes={{
            paper: "bo-orders-drawer-paper"
          }}
        >
          <div style={{ justifyContent: "flex-end" }}>
            <div className="float-left">
              <IconButton onClick={(e) => this.props.toggleDrawer(e)}>
                <ChevronRightIcon />
              </IconButton>
            </div>
            <FolderSwitcher
              folders={folders}
              handleFolderSwitch={this.handleFolderSwitch}
            />
          </div>
          <Divider />
          <DrawerInboxList
            memos={memos}
            processActions={this.props.processActions}
            isProcessing={this.props.isProcessing}
            user={this.props.user}
            agents={this.props.agents}
            folders={folders}
            trashMemo={this.trashMemo}
            flagMemoAsRead={this.flagMemoAsRead}
          />
        </Drawer>
      );
    }

    return ret;
  }
}


const mapStateToProps = state => {
  return {
    agents: state.todo.agents,
    //distributors: state.orders.distributors,
    //paywith: state.orders.paywith,
    //warranty_options: state.orders.warranty_options,
    //searchBox: state.orders.searchBox,
    //modal: state.orders.modal,
    //chips: state.orders.chips,
    //orders: state.orders.results,
    //user: state.orders.user,
    //minicart: state.minicart,

    //wheelFittingFees: state.orders.wheelFittingFees,
    //sensorUnitStandardPrice: state.orders.sensorUnitStandardPrice,

    isProcessing: state.isProcessing.mostRecentStatus
  };
};

const mapDispatchToProps = dispatch => {
  return {
    todoDoSomething: (url, payload) => dispatch(todoDoSomething(url, payload)),
    //updateChipsSearchBox: (chips) => dispatch(updateChipsSearchBox(chips)),
    //updateSearchBox: (data) => dispatch(updateSearchBox(data)),
    //updateModal: (data) => dispatch(updateModal(data)),

    //animateCart: (data) => dispatch(animateCart(data)),

    //pusherTrigger: (data) => dispatch(pusherTrigger(data)),

    //doSomething: (url, payload, options) => dispatch(doSomething(url, payload, options)),
    //doSomethingSpecific: (url, payload) => dispatch(doSomethingSpecific(url, payload))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(DrawerInbox);

