import React, { useState, useEffect } from 'react';
import {
  ResponsiveContainer, BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

function HistorySalesDetails(props) {
  // Declare a new state variable, which we'll call "count"

  const crunchData = (data) => {
    if (data && data[0]) {
      data = data[0];

      //var years = groupBy(data.Closed, "yyyy").sort((a, b) => b - a);
      //console.log("years", years);

      data.Closed = data.Closed.map(v => {
        Object.keys(v).forEach(y => {
          if (v[y] == 0) {
            delete v[y];
          }
        });
        return v;
      });

      if (data.LostWithContactInformation) {
        data.LostWithContactInformation = data.LostWithContactInformation.map(v => {
          Object.keys(v).forEach(y => {
            if (v[y] == 0) {
              delete v[y];
            }
          });
          return v;
        });
      }

      return data;
    } else {
      return undefined;
    }
  }

  const [_data, setData] = useState(crunchData(props.data));

  useEffect(() => {
    //setData(crunchData(props.data));
    setData(props.data);
  }, [props.data]);

  if (!_data) {
    return null;
  }


  //<Line type="monotone" dataKey="2020" stroke="#0074D9" strokeWidth="2" activeDot={{ r: 8 }} />
  //<Line type="monotone" dataKey="2019" stroke="#FF851B" strokeDasharray="3 4 5 2" activeDot={{ r: 8 }} />
  //<Line type="monotone" dataKey="2018" stroke="#AAAAAA" strokeDasharray="3 4 5 2" activeDot={{ r: 8 }} />

  var charts = [];
  charts = ["tires", "wheels", "spare"].map(p => {
    var years = Object.keys(_data[p][0]).filter(k => k != "wk").sort();
    var lines = years.sort((a, b) => { return b - a;}).map((y, i) => {
      switch (i) {
        case 0:
          return <Line key={"chart-" + p + "-line" + i} type="monotone" dataKey={y} stroke="#0074D9" strokeWidth="2" activeDot={{ r: 8 }} />;
          break;
        case 1:
          return <Line key={"chart-" + p + "-line" + i} type="monotone" dataKey={y} stroke="#FF851B" strokeDasharray="3 4 5 2" activeDot={{ r: 8 }} />;
          break;
        case 2:
          return <Line key={"chart-" + p + "-line" + i} type="monotone" dataKey={y} stroke="#AAAAAA" strokeDasharray="3 4 5 2" activeDot={{ r: 8 }} />;
          break;
      }
    });
    return (
      <div key={"chart-" + p} className="row">
        <div className="col">
          <div style={{ fontFamily: "Roboto Condensed", textAlign: "center", fontSize: "1.5rem" }}>{ p } - {props.domain}</div>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart
              data={_data[p].sort((a, b) => { return a.wk - b.wk; })}
              syncId={"sales" + p + "-" + props.domain}
              margin={{
                top: 5, right: 0, left: 0, bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="wk"
                tick={{ fontFamily: "Roboto Condensed", fontSize: ".75rem" }} />
              <YAxis
                tick={{ fontFamily: "Roboto Condensed", fontSize: ".75rem" }}
                tickFormatter={tick => {
                  return (1 * tick).toFixed(0);
                }} />
              <Tooltip />
              <Legend />
              {lines}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  });


  return (
    <div>
      {charts}
    </div>
  );
}

export default HistorySalesDetails;
