

const styles = theme => ({
  paper: {
    height: '100%',
    backgroundColor: theme.palette.background.paper
  },
  badge: {
    top: '50%',
    right: -10,
    height: 8,
    minWidth: 8
  },
  white: {
    color: 'white !important'
  },
  menuItem: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& $primary, & $icon': {
        color: theme.palette.common.white,
      },
    },
  },
  primary: {},
  icon: {},
  errorMessage: {
    backgroundColor: theme.palette.error.dark,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  iconMessage: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(0),
  },
  icon: {
    fontSize: 20
  }
});

export default styles;
