import React from 'react';

class NavBarProductsMenu extends React.Component {
  constructor(props) {
    super(props)
  }


  render = () => {
    const { ui } = this.props;

    //<div className={"dropdown-menu" + (ui.configuration ? " show" : "")} aria-labelledby="navbardropdown">
    //  <a className="dropdown-item" href="#">action</a>
    //  <a className="dropdown-item" href="#">another action</a>
    //  <div className="dropdown-divider" />
    //  <a className="dropdown-item" href="#">something else here</a>
    //</div>
    return (
      <ul className="navbar-nav mr-10" style={{ paddingLeft: 10 }}>
        <li className={"nav-item dropdown" + (ui.products.show ? " show" : "")}
          ref={ui.products.ref}>
          <a className="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            style={{ position: "relative" }}
            onClick={(e) => this.props.handleClick(e)}
            name="products">
            Products
          </a>
          <div className={"dropdown-menu" + (ui.products.show ? " show" : "")}>
            <a className="dropdown-item" href="/bo/products"><i className="fas fa-dolly"></i> All tires</a>
            <a className="dropdown-item" href="/bo/wheels"><i className="fas fa-dolly"></i> All wheels</a>
            <a className="dropdown-item" href="/bo/eans"><i className="fas fa-barcode"></i> Master Data</a>
            <a className="dropdown-item" href="/bo/missinganag"><i className="fas fa-cogs"></i> Suppliers Import queue</a>
            <a className="dropdown-item" href="/bo/erpprices"><i className="fas fa-cogs"></i> Suppliers Import Erp Prices</a>
            <a className="dropdown-item" href="/bo/gomzon/customers"><i className="fas fa-cash-register"></i> Gomzon customers</a>
          </div>
        </li>
      </ul>
    );
  }
}


export default NavBarProductsMenu;