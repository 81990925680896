export const ACTN_AUTH = "AUTHENTICATE";
export const ACTN_SIGNUP = "SIGNUP";
export const ACTN_ERROR = "ERROR";
export const ACTN_FORGOT = "FORGOT";

export function actionAuth(endpoint, provider, authBody){
  let body = authBody;
  if (provider === "google") {
    body = convertGoogleUser(authBody);
  } else if (provider === "facebook") {
    body = convertFacebookResponse(authBody);
  }

  return dispatch => {
    return fetch(endpoint + "api/signin/" + provider, {
        method: 'POST',
      body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: ACTN_AUTH,
          provider: provider,
          payload: data
        });
      });
  }
}

export function actionSignup(endpoint, signupBody){
  return dispatch => {
    return fetch(endpoint + "api/signup", {
        method: 'POST',
        body: JSON.stringify(signupBody),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: ACTN_SIGNUP,
          payload: data
        });
      });
  }
}

export function actionForgot(endpoint, body) {
  return dispatch => {
    return fetch(endpoint + "api/signin/forgot", {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: ACTN_FORGOT,
          payload: data
        });
      });
  }
}

export function actionError(error) {
  return dispatch => {
    dispatch({
      type: ACTN_ERROR,
      error: error
    });
  }
}

function convertGoogleUser(googleUser) {
  var profile = googleUser.getBasicProfile();

  var data = {
    name: profile.getName(),
    email: profile.getEmail(),
    picture: profile.getImageUrl(),
    id_token: googleUser.getAuthResponse().id_token
  };

  return data;
}

function convertFacebookResponse(response) {
  var data = {
    name: response.name,
    email: response.email,
    picture: response.picture.data.url,
    signedRequest: response.authResponse.signedRequest,
    id_token: 'none'
  };

  return data;
}